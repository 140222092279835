import { PolylineEdge, PolylineEdgeModel } from "@logicflow/core";
import {
  getShapeStyleFuction,
  getTextStyleFunction,
} from "../getShapeStyleUtil";

// 折线
class Model extends PolylineEdgeModel {
  constructor(data, graphModel) {
    super(data, graphModel);
    this.strokeWidth = 2;
    this.text.editable = false; // 禁止节点文本编辑
  }
  getTextStyle() {
    const style = super.getTextStyle();
    return getTextStyleFunction(style, this.properties);
  }

  getEdgeStyle() {
    const attributes = super.getEdgeStyle();
    const properties = this.properties;
    const style = getShapeStyleFuction(attributes, properties);
    style.strokeWidth = 2;
    return { ...style, fill: "none", stroke: "#00FF00" };
  }
}
export default {
  type: "pro-green-polyline",
  view: PolylineEdge,
  model: Model,
};
