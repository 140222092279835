import { createApp } from "vue";
import {
  ElPopover,
  ElSelect,
  ElSteps,
  ElStep,
  ElOption,
  ElInput,
  ElButton,
  ElButtonGroup,
  ElInputNumber,
  ElRadioGroup,
  ElRadioButton,
  ElTabs,
  ElTabPane,
  ElUpload,
  ElForm,
  ElFormItem,
  ElRadio,
  ElSlider,
  ElDialog,
  ElMessageBox,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElAvatar,
  ElProgress,
  ElCard,
  ElMessage,
  ElCheckbox,
  ElSwitch,
  ElTag,
  ElLoading,
  ElImage,
  ElCollapse,
  ElCollapseItem,
  ElBadge,
  ElCheckboxGroup,
  ElTooltip,
  ElCheckboxButton,
  ElIcon,
  ElDivider,
  ElColorPicker,
} from "element-plus";
import "element-plus/dist/index.css";
import ElementTiptapPlugin from "element-tiptap-vue3-fixed";
import "element-tiptap-vue3-fixed/lib/style.css";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import VueKonva from 'vue-konva';

import App from "./App.vue";

import axios from "axios";

import { enLanguage } from "./lang/en.js";
import { zhHansLanguage } from "./lang/zh-Hans.js";
import { zhHantLanguage } from "./lang/zh-Hant.js";
import { dispatchEventStroage } from "./tools/utils";
import store from "./store";
import mitt from "mitt";
import router from "./router/router.js";

const app = createApp(App);

app.config.globalProperties.enLanguage = enLanguage;
app.config.globalProperties.zhHansLanguage = zhHansLanguage;
app.config.globalProperties.zhHantLanguage = zhHantLanguage;

if (window.location.href.indexOf("cocorobo.cn") != -1) {
  app.config.globalProperties.language = zhHansLanguage;
} else if (window.location.href.indexOf("cocorobo.hk") != -1) {
  app.config.globalProperties.language = zhHantLanguage;
} else if (window.location.href.indexOf("cocorobo.com") != -1) {
  app.config.globalProperties.language = enLanguage;
} else {
  app.config.globalProperties.language = zhHansLanguage;
}
//在main.js引用drag.js的内容
import registerDirectives from "./tools/drag";
registerDirectives(app);
import { drag } from "./tools/moveDrag";
app.directive("draggable", drag);

app.component(ElPopover.name, ElPopover);
app.component(ElSelect.name, ElSelect);
app.component(ElOption.name, ElOption);
app.component(ElSteps.name, ElSteps);
app.component(ElStep.name, ElStep);
app.component(ElInput.name, ElInput);
app.component(ElButton.name, ElButton);
app.component(ElButtonGroup.name, ElButtonGroup);
app.component(ElInputNumber.name, ElInputNumber);
app.component(ElRadioGroup.name, ElRadioGroup);
app.component(ElRadioButton.name, ElRadioButton);
app.component(ElTabs.name, ElTabs);
app.component(ElTabPane.name, ElTabPane);
app.component(ElUpload.name, ElUpload);
app.component(ElForm.name, ElForm);
app.component(ElFormItem.name, ElFormItem);
app.component(ElRadio.name, ElRadio);
app.component(ElSlider.name, ElSlider);
app.component(ElDialog.name, ElDialog);
app.component(ElDropdown.name, ElDropdown);
app.component(ElDropdownMenu.name, ElDropdownMenu);
app.component(ElDropdownItem.name, ElDropdownItem);
app.component(ElAvatar.name, ElAvatar);
app.component(ElProgress.name, ElProgress);
app.component(ElCard.name, ElCard);
app.component(ElCheckbox.name, ElCheckbox);
app.component(ElSwitch.name, ElSwitch);
app.component(ElTag.name, ElTag);
app.component(ElImage.name, ElImage);
app.component(ElCollapse.name, ElCollapse);
app.component(ElCollapseItem.name, ElCollapseItem);
app.component(ElBadge.name, ElBadge);
app.component(ElCheckboxGroup.name, ElCheckboxGroup);
app.component(ElCheckboxButton.name, ElCheckboxButton);
app.component(ElTooltip.name, ElTooltip);
app.component(ElIcon.name, ElIcon);
app.component(ElDivider.name, ElDivider);
app.use(ElColorPicker)
app.use(ElLoading);
app.use(ElementTiptapPlugin);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(dispatchEventStroage);

//codemirror
import VueCodemirror from "vue-codemirror";
app.use(VueCodemirror);

app.use(VueKonva)

app.config.productionTip = false;

app.config.globalProperties.$confirm = ElMessageBox.confirm;
app.config.globalProperties.$message = ElMessage;

app.config.globalProperties.$ajax = axios;
app.config.globalProperties.$bus = mitt();

// import('vconsole').then(VConsole => {
//   new VConsole.default();
// });

app.use(store).use(router).mount("#app");
