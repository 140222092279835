<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    id="diagram-panel"
    ref="diagramPanel"
    class="diagram-panel"
    :style="
      style.type == '' ||
      style.type == 'icon-start' ||
      style.type == 'icon-thinking' ||
      style.type == 'icon-whiteboard' ||
      style.type == 'icon-map' ||
      style.type == 'icon-camera' ||
      style.type == 'icon-ai-npc'
        ? 'display:none;'
        : 'display:flex;'
    "
  >
    <div id="moveDiv" class="moveDiv"></div>
    <el-tabs style="width: 100%">
      <el-tab-pane style="width: 100%" :label="languageData.Editing">
        <template v-if="style.type == 'icon-theme'">
          <div class="setting-item">
            <b style="margin-left: 5px; min-width: 50px"
              >{{ languageData.topic }}：</b
            >
            <el-input
              :model-value="style.topic"
              :placeholder="languageData.themeDesc"
              @input="getThemeInputValue"
              @change="$_changeThemeTitle"
            />
          </div>

          <div class="setting-item">
            <b style="margin-right: 10px"
              >{{ languageData.tip109 }}：</b
            >
            <el-popover
              placement="top-start"
              :title="languageData.tip110"
              width="250"
              trigger="click"
            >
              <sketch-picker v-model="style.fontColor" />
              <template #reference>
                <div
                  class="border-color"
                  :style="{ backgroundColor: style.fontColor }"
                  style="cursor: pointer; flex:1;"
                ></div>
              </template>
            </el-popover>
          </div>

          <div class="setting-item">
            <b style="margin-right: 10px"
              >{{ languageData.tip107 }}：</b
            >
            <el-popover
              placement="top-start"
              :title="languageData.tip110"
              width="250"
              trigger="click"
            >
              <sketch-picker v-model="style.backgroundColor" />
              <template #reference>
                <div
                  class="border-color"
                  :style="{ backgroundColor: style.backgroundColor }"
                  style="cursor: pointer; flex:1;"
                ></div>
              </template>
            </el-popover>
          </div>

          <div class="setting-item">
            <el-checkbox
              v-model="style.isGradientColor"
              @change="setGradientColorSelect"
            >
              <b style="font-size: 12px; color: black"
                >{{ languageData.tip108 }}：</b
              >
            </el-checkbox>
            <!-- <b style="min-width: 50px;margin-right:10px;">{{  languageData.tip108 }}：</b> -->
            <el-popover
              placement="top-start"
              :title="languageData.tip110"
              width="250"
              trigger="click"
            >
              <sketch-picker v-model="style.gradientColor" />
              <template #reference>
                <div
                  class="border-color"
                  :style="{ backgroundColor: style.gradientColor }"
                  style="cursor: pointer; flex:1;"
                ></div>
              </template>
            </el-popover>
          </div>
        </template>
        <template v-if="style.type == 'icon-stage'">
          <div class="setting-item">
            <b
              :style="
                lang == 'en'
                  ? 'margin-left: 5px;min-width: 90px;'
                  : 'margin-left: 5px;min-width: 78px;'
              "
              >{{ languageData.stageTitle }}：</b
            >
            <el-input
              :model-value="style.stageName"
              :placeholder="languageData.stageDesc"
              style="width: calc(100% + 10px)"
              @input="getStageInputValue"
              @change="$_changeStageTitle"
            />
            <b
              :style="
                lang == 'en'
                  ? 'margin-left: 10px;min-width: 80px;'
                  : 'margin-left: 10px;min-width: 60px;'
              "
              >{{ languageData.titleColor }}：</b
            >
            <color-select
              :color-list="colorList"
              :color="style.stageTitleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="false"
              @update="updateStage"
              @selfAdaption="selfAdaption"
            ></color-select>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              line-height: 12px;
              vertical-align: middle;
              margin-bottom: 10px;
            "
          >
            <div
              :style="lang == 'en' ? 'min-width: 125px;' : 'min-width: 78px;'"
            >
              <b style="margin-left: 5px; margin-bottom: 15px; font-size: 12px"
                >{{ languageData.stageContent }}：</b
              >
            </div>
            <!-- <el-input type="textarea" autosize :value="style.taskContent" style="width: 270px; padding: 10px 0px;"
              @input="getTaskContentInputValue" @change="$_changeTaskContent" :placeholder="languageData.taskContentDesc" /> -->
            <div id="stageContent" style="width: 98%">
              <el-tiptap
                v-if="lang == 'en'"
                id="tiptap1"
                :content="style.stageContent"
                placeholder=""
                :extensions="extensions"
                style="height: 400px"
                lang="en"
                @onUpdate="onUpdateStageContent"
                @onBlur="onBlurStageContent"
              />
              <el-tiptap
                v-else-if="lang == 'zh'"
                id="tiptap1"
                :content="style.stageContent"
                placeholder=""
                :extensions="extensions"
                style="height: 400px"
                lang="zh"
                @onUpdate="onUpdateStageContent"
                @onBlur="onBlurStageContent"
              />
              <el-tiptap
                v-else-if="lang == 'zh_tw'"
                id="tiptap1"
                :content="style.stageContent"
                placeholder=""
                :extensions="extensions"
                style="height: 400px"
                lang="zh_tw"
                @onUpdate="onUpdateStageContent"
                @onBlur="onBlurStageContent"
              />
            </div>
          </div>
        </template>
        <template v-if="style.type == 'icon-task'">
          <div class="setting-item">
            <b style="margin-left: 5px; min-width: 78px"
              >{{ languageData.taskTitle }}：</b
            >
            <el-input
              :model-value="style.taskTitle"
              :placeholder="languageData.taskDesc"
              @input="getTaskInputValue"
              @change="$_changeTaskTitle"
            />
            <b
              :style="
                lang == 'en'
                  ? 'margin-left: 10px;min-width: 80px;'
                  : 'margin-left: 10px;min-width: 60px;'
              "
              >{{ languageData.titleColor }}：</b
            >
            <color-select
              :color-list="colorList"
              :color="style.taskTitleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="false"
              @update="updateTask"
              @selfAdaption="selfAdaption"
            ></color-select>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              line-height: 12px;
              vertical-align: middle;
              margin-bottom: 10px;
            "
          >
            <div
              :style="lang == 'en' ? 'min-width: 120px;' : 'min-width: 78px;'"
            >
              <b style="margin-left: 5px; margin-bottom: 15px; font-size: 12px"
                >{{ languageData.taskContent }}：</b
              >
            </div>
            <!-- <el-input type="textarea" autosize :value="style.taskContent" style="width: 270px; padding: 10px 0px;"
              @input="getTaskContentInputValue" @change="$_changeTaskContent" :placeholder="languageData.taskContentDesc" /> -->
            <div id="taskContent" style="width: 98%">
              <el-tiptap
                v-if="lang == 'en'"
                id="tiptap2"
                :content="style.taskContent"
                :extensions="extensions"
                style="height: 400px"
                lang="en"
                @onUpdate="onUpdateTaskContent"
                @onBlur="onBlurTaskContent"
              />
              <el-tiptap
                v-else-if="lang == 'zh'"
                id="tiptap2"
                :content="style.taskContent"
                :extensions="extensions"
                style="height: 400px"
                lang="zh"
                @onUpdate="onUpdateTaskContent"
                @onBlur="onBlurTaskContent"
              />
              <el-tiptap
                v-else-if="lang == 'zh_tw'"
                id="tiptap2"
                :content="style.taskContent"
                :extensions="extensions"
                style="height: 400px"
                lang="zh_tw"
                @onUpdate="onUpdateTaskContent"
                @onBlur="onBlurTaskContent"
              />
            </div>
          </div>
        </template>
        <template v-if="style.type == 'icon-homework'">
          <div class="setting-item">
            <b style="margin-left: 5px; min-width: 78px"
              >{{ languageData.homeworkTitle }}：</b
            >
            <el-input
              :model-value="style.homeworkName"
              :placeholder="languageData.homeworkDesc"
              @input="getHomeworkInputValue"
              @change="$_changeHomeworkTitle"
            />
            <b style="margin-left: 10px; min-width: 60px"
              >{{ languageData.titleColor }}：</b
            >
            <color-select
              :color-list="colorList"
              :color="style.homeworkTitleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="false"
              @update="updateHomework"
              @selfAdaption="selfAdaption"
            ></color-select>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              line-height: 12px;
              vertical-align: middle;
              margin-bottom: 10px;
            "
          >
            <div style="min-width: 78px">
              <b style="margin-left: 5px; margin-bottom: 15px; font-size: 12px"
                >{{ languageData.homeworkContent }}：</b
              >
            </div>
            <!-- <el-input type="textarea" autosize :value="style.taskContent" style="width: 270px; padding: 10px 0px;"
              @input="getTaskContentInputValue" @change="$_changeTaskContent" :placeholder="languageData.taskContentDesc" /> -->
            <div id="stageContent" style="width: 98%">
              <el-tiptap
                v-if="lang == 'en'"
                id="tiptap3"
                :content="style.homeworkContent"
                placeholder=""
                :extensions="extensions"
                style="height: 400px"
                lang="en"
                @onUpdate="onUpdateHomeworkContent"
                @onBlur="onBlurHomeworkContent"
              />
              <el-tiptap
                v-else-if="lang == 'zh'"
                id="tiptap3"
                :content="style.homeworkContent"
                placeholder=""
                :extensions="extensions"
                style="height: 400px"
                lang="zh"
                @onUpdate="onUpdateHomeworkContent"
                @onBlur="onBlurHomeworkContent"
              />
              <el-tiptap
                v-else-if="lang == 'zh_tw'"
                id="tiptap3"
                :content="style.homeworkContent"
                placeholder=""
                :extensions="extensions"
                style="height: 400px"
                lang="zh_tw"
                @onUpdate="onUpdateHomeworkContent"
                @onBlur="onBlurHomeworkContent"
              />
            </div>
          </div>
        </template>
        <template v-if="style.type == 'icon-card'">
          <div class="setting-item">
            <b style="margin-left: 24px; min-width: 37px"
              >{{ languageData.title }}：</b
            >
            <el-input
              :model-value="style.title"
              :placeholder="languageData.titleDesc"
              @input="getInputValue"
              @change="$_changeTitle"
            />
            <b
              :style="
                lang == 'en'
                  ? 'margin-left: 10px;min-width: 80px;'
                  : 'margin-left: 18px;min-width: 60px;'
              "
              >{{ languageData.titleColor }}：</b
            >
            <color-select
              :color-list="colorList"
              :color="style.titleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="true"
              @update="update"
              @selfAdaption="selfAdaption"
            ></color-select>
            <el-checkbox
              v-model="style.isShowTitle"
              @change="setIsShowTitleSelect"
            >
              <b
                style="
                  margin-bottom: 39px;
                  font-size: 12px;
                  color: black;
                  min-width: 24px;
                "
                >{{ languageData.hide }}</b
              >
            </el-checkbox>
          </div>
          <!-- <div class="setting-item">
            <b style="margin-left: 24px; margin-bottom: 15px;">{{ languageData.notes }}：</b>
            <el-input type="textarea" autosize :value="style.describe" style="width: 299px; padding: 10px 0px"
              @input="getDescribeInputValue" @change="$_changeDescribe" :placeholder="languageData.notesDesc" />
          </div> -->
          <div
            class="setting-item"
            style="display: flex; align-items: flex-start; flex-direction: row"
          >
            <!-- <b style="margin-left: 24px; margin-top: 8px">{{ languageData.icon }}：</b> -->
            <div style="margin-top: 7px; min-width: 60px">
              <el-checkbox v-model="style.iconRadio" @change="setIconSelect">
                <b style="margin-bottom: 39px; font-size: 12px; color: black"
                  >{{ languageData.icon }}：</b
                >
              </el-checkbox>
            </div>
            <div
              id="iconList"
              style="margin-bottom: 39px; width: 100%; margin-top: 7px"
            >
              <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleIconListRemove"
                :file-list="iconList"
                :auto-upload="true"
                accept="image/*"
                :http-request="uploadIcon"
                list-type="picture"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <el-button size="small" type="primary">
                    {{ languageData.upload }}
                  </el-button>
                </div>
                <template #tip>
                  <div class="el-upload__tip" style="margin-left: 5px">
                    {{ languageData.uploadDesc }}
                  </div>
                </template>
              </el-upload>
              <el-progress
                v-if="isIconProgress"
                :stroke-width="16"
                :percentage="IconProgressPercent"
                style="margin-top: 10px"
              ></el-progress>
            </div>
            <div style="margin-top: 9px; margin-right: 15px; min-width: 75px">
              <el-button type="primary" size="small" link @click="addIcon">
                {{ languageData.addIcon }}
              </el-button>
            </div>
            <div style="margin-top: 7px;">
              <el-checkbox
                v-model="style.checked"
                :title="languageData.transparentIcon"
                @change="setChecked"
              ></el-checkbox>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-top: -30px;
              line-height: 12px;
              vertical-align: middle;
              margin-bottom: 10px;
            "
          >
            <el-radio
              v-model="style.radio"
              label="content"
              value="content"
              @change="setSelect"
            >
              <b
                style="
                  margin-bottom: 39px;
                  font-size: 12px;
                  color: black;
                  font-size: 12px;
                  min-width: 60px;
                "
                >{{ languageData.text }}：</b
              >
            </el-radio>
            <!-- <input type="text" :value="style.content" style="width:150px" @change="$_changeTitle"/> -->
            <!-- <el-input type="textarea" :value="style.content" style="width: 299px;padding: 10px 0px;position: relative;"
                @input="getTextareaValue" @change="$_changeContent" placeholder="请输入正文" /> -->
            <div id="icon-card" style="width: 98%">
              <el-tiptap
                v-if="lang == 'en'"
                id="tiptap4"
                :content="style.content"
                placeholder=""
                :extensions="extensions"
                lang="en"
                style="height: 300px"
                @onUpdate="onUpdate"
                @onBlur="onBlur"
              />
              <el-tiptap
                v-else-if="lang == 'zh'"
                id="tiptap4"
                :content="style.content"
                placeholder=""
                :extensions="extensions"
                lang="zh"
                style="height: 300px"
                @onUpdate="onUpdate"
                @onBlur="onBlur"
              />
              <el-tiptap
                v-else-if="lang == 'zh_tw'"
                id="tiptap4"
                :content="style.content"
                placeholder=""
                :extensions="extensions"
                lang="zh_tw"
                style="height: 300px"
                @onUpdate="onUpdate"
                @onBlur="onBlur"
              />
            </div>
          </div>

          <div
            class="setting-item"
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-top: 20px;
            "
          >
            <el-radio
              v-model="style.radio"
              label="iframeUrl"
              value="iframeUrl"
              @change="setSelect"
            >
              <b style="margin-bottom: 39px; font-size: 12px; color: black"
                >{{ languageData.iframe }}：</b
              >
            </el-radio>
            <el-input
              :model-value="style.iframeUrl"
              style="margin-bottom: 39px"
              :placeholder="languageData.iframeDesc"
              @input="getIframeValue"
              @change="$_changeIframeUrl"
            />
          </div>

          <div
            class="setting-item"
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-top: -30px;
            "
          >
            <el-radio
              v-model="style.radio"
              label="embedCode"
              value="embedCode"
              @change="setSelect"
            >
              <b style="margin-bottom: 39px; font-size: 12px; color: black"
                >{{ languageData.embedCode }}：</b
              >
            </el-radio>
            <el-input
              :model-value="style.embedCode"
              style="margin-bottom: 39px"
              :placeholder="languageData.embedCodeDesc"
              @input="getEmbedCodeValue"
              @change="$_changeEmbedCode"
            />
          </div>

          <div
            class="setting-item"
            style="
              display: flex;
              align-items: flex-start;
              flex-direction: row;
              margin-top: -30px;
            "
          >
            <div style="margin-top: 7px; min-width: 75px">
              <el-radio
                v-model="style.radio"
                label="imageList"
                value="imageList"
                @change="setSelect"
              >
                <b style="margin-bottom: 39px; font-size: 12px; color: black"
                  >{{ languageData.picture }}：</b
                >
              </el-radio>
            </div>
            <div
              id="imageList"
              style="margin-bottom: 39px; margin-top: 7px; width: 100%"
            >
              <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleImageListRemove"
                :file-list="imageList"
                :auto-upload="true"
                accept="image/*"
                :http-request="uploadImage"
                list-type="picture"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <el-button size="small" type="primary">
                    {{ languageData.upload }}
                  </el-button>
                </div>
                <template #tip>
                  <div
                    class="el-upload__tip"
                    style="margin-left: 5px; margin-top: 0"
                  >
                    {{ languageData.uploadDesc }}
                  </div>
                </template>
              </el-upload>
              <el-progress
                v-if="isImageProgress"
                :stroke-width="16"
                :percentage="ImageProgressPercent"
                style="margin-top: 10px"
              ></el-progress>
            </div>
            <div style="margin-top: 9px; min-width: 85px; text-align: center">
              <el-button type="primary" size="small" link @click="addImage">
                {{ languageData.searchImages }}
              </el-button>
            </div>
          </div>

          <div
            class="setting-item"
            style="
              display: flex;
              align-items: flex-start;
              flex-direction: row;
              margin-top: -30px;
            "
          >
            <div style="margin-top: 7px; min-width: 75px">
              <el-radio
                v-model="style.radio"
                label="videoList"
                value="videoList"
                @change="setSelect"
              >
                <b style="margin-bottom: 39px; font-size: 12px; color: black"
                  >{{ languageData.video }}：</b
                >
              </el-radio>
            </div>
            <div
              :style="
                style.videoList.length != 0
                  ? 'margin-right:2px;width: 100%;margin-top: 7px;'
                  : 'margin-bottom: 39px;margin-right:2px;width: 100%;margin-top: 7px;'
              "
            >
              <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleVideoListRemove"
                :file-list="videoList"
                :auto-upload="true"
                accept="video/*"
                :http-request="uploadVideo"
              >
                <el-button size="small" type="primary">
                  {{ languageData.upload }}
                </el-button>
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
              </el-upload>
              <el-progress
                v-if="isVideoProgress"
                :stroke-width="16"
                :percentage="videoProgressPercent"
              ></el-progress>
            </div>
            <div style="margin-top: 6px; min-width: 150px; display: none">
              <el-radio
                v-model="style.styleRadio"
                label="1"
                value="1"
                style="margin-right: 3px"
                @change="setStyleSelect"
              >
                {{ languageData.CenterUpAndDown }}
              </el-radio>
              <el-radio
                v-model="style.styleRadio"
                label="2"
                value="2"
                @change="setStyleSelect"
              >
                {{ languageData.selfAdaption }}
              </el-radio>
            </div>
          </div>

          <div
            class="setting-item"
            :style="
              style.videoList.length != 0
                ? 'display:flex;align-items: center;flex-direction: row;'
                : 'display:flex;align-items: center;flex-direction: row;margin-top: -30px;'
            "
          >
            <div style="min-width: 60px">
              <el-radio
                v-model="style.radio"
                label="audioList"
                value="audioList"
                @change="setSelect"
              >
                <b style="margin-bottom: 39px; font-size: 12px; color: black"
                  >{{ languageData.voice }}：</b
                >
              </el-radio>
            </div>
            <div style="margin-bottom: 39px">
              <el-button
                size="small"
                type="primary"
                :disabled="isStartRecordAudio"
                @click="startRecordAudio"
              >
                {{ languageData.startRecording }}
              </el-button>
              <el-button
                size="small"
                type="danger"
                :disabled="isStopRecordAudio"
                @click="stopRecordAudio"
              >
                {{ languageData.endRecording }}
              </el-button>
            </div>
          </div>
          <div class="setting-item" style="margin-top: -45px; width: 100%">
            <h3 style="margin-left: 10%">
              {{ languageData.RecordingTime }}：{{
                recorder.duration.toFixed(4)
              }}
            </h3>
          </div>
          <div
            class="setting-item"
            style="margin-top: -30px; margin-left: 10%; width: 90%"
          >
            <el-upload
              class="upload-demo"
              action="#"
              :on-preview="handlePreview"
              :on-remove="handleAudioListRemove"
              :file-list="audioList"
              :auto-upload="true"
              accept="audio/*"
              :http-request="uploadAudio"
              style="width: 100%"
            >
              <!-- <el-button size="small" type="primary">{{ languageData.upload }}</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
          </div>
          <el-progress
            v-if="isAudioProgress"
            :stroke-width="16"
            :percentage="AudioProgressPercent"
          ></el-progress>

          <div
            class="setting-item"
            style="display: flex; align-items: flex-start; flex-direction: row"
          >
            <div style="margin-top: 7px; min-width: 60px">
              <el-radio
                v-model="style.radio"
                label="fileList"
                value="fileList"
                @change="setSelect"
              >
                <b style="margin-bottom: 39px; font-size: 12px; color: black"
                  >{{ languageData.file }}：</b
                >
              </el-radio>
            </div>
            <div
              id="fileList"
              style="margin-bottom: 39px; width: 100%; margin-top: 7px"
            >
              <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreviewFile"
                :on-remove="handleFileListRemove"
                :file-list="fileList"
                :auto-upload="true"
                accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx"
                :http-request="uploadFile"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <el-button size="small" type="primary">
                    {{ languageData.upload }}
                  </el-button>
                </div>
                <template #tip>
                  <div
                    class="el-upload__tip"
                    style="margin-left: 5px; margin-top: 0"
                  >
                    {{ languageData.uploadTip }}
                  </div>
                </template>
              </el-upload>
              <el-progress
                v-if="isFileProgress"
                :stroke-width="16"
                :percentage="fileProgressPercent"
                style="margin-top: 10px"
              ></el-progress>
            </div>
          </div>
        </template>
        <template v-if="style.type == 'icon-select'">
          <div class="setting-item">
            <b style="min-width: 37px">{{ languageData.title }}：</b>
            <el-input
              :model-value="style.selectTitle"
              placeholder="请输入内容"
              @input="getSelectInputValue"
              @change="$_changeSelectTitle"
            />
            <b style="margin-left: 18px; min-width: 37px"
              >{{ languageData.titleColor }}：</b
            >
            <color-select
              :color-list="colorList"
              :color="style.selectTitleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="false"
              @update="updateSelect"
              @selfAdaption="selfAdaption"
            ></color-select>
          </div>
          <div
            class="setting-item"
            style="display: flex; align-items: center; flex-direction: row"
          >
            <b
              style="
                margin-bottom: 39px;
                font-size: 12px;
                color: black;
                min-width: 37px;
              "
              >{{ languageData.question }}：</b
            >
            <el-input
              type="textarea"
              :model-value="style.selectContent"
              style="padding: 10px 0px"
              @input="getSelectTextareaValue"
              @change="$_changeSelectContent"
            />
          </div>

          <div
            class="setting-item"
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-top: 20px;
            "
          >
            <b style="min-width: 37px">{{ languageData.option }}：</b>
            <!-- <template v-for="(item, index) in style.selectOption" :key="index">
                <el-input type="text" :value="style.currentInput" style="width:320px" 
                  @input="getSelectOptionTextareaValue" @change="$_changeSelectOption" placeholder="请输入内容"
                  @focus="setInput" :data-index="index"/>
              </template> -->
          </div>
          <div
            v-for="(item, index) in style.selectOption"
            :key="index"
            class="setting-item"
            :style="
              index == 0
                ? 'display:flex;align-items: center;flex-direction: row;margin-top: -30px;'
                : 'display:flex;align-items: center;flex-direction: row;'
            "
          >
            <input
              type="radio"
              name="radio"
              :value="index"
              style="margin-left: 60px; margin-bottom: 4px"
              :checked="style.rightAnswer == style.selectOption[index]"
              @change="changeRadio"
            />
            <input
              type="text"
              :placeholder="languageData.optionDesc"
              :value="style.selectOption[index]"
              class="el-input__inner"
              style="margin-left: 5px; width: 100%"
              :data-index="index"
              @input="getSelectOptionValue"
              @change="$_changeSelectOption"
            />
            <img
              :style="
                index == style.selectOption.length - 1
                  ? 'display:block;cursor: pointer;'
                  : 'display:none;cursor: pointer;'
              "
              src="../../assets/删除.png"
              width="32"
              height="32"
              @click="deleteOption"
            />
          </div>

          <div
            class="setting-item"
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: center;
              margin-left: 35px;
              cursor: pointer;
            "
          >
            <img src="../../assets/icon_add.png" @click="addOption" />
          </div>

          <!-- <div class="setting-item">
              答案：
              <el-input type="text" :value="style.selectAnswer" style="width:180px" @input="getSelectAnswerInputValue"
                @change="$_changeSelectAnswer" placeholder="请输入内容" />
            </div> -->
        </template>
        <!-- <template v-if="style.type == 'icon-thinking'">
          <div class="setting-item">
            <b style="margin-left: 24px">{{ languageData.title }}：</b>
            <el-input type="text" :value="style.thinkingTitle" style="width: 160px" @input="getThinkingInputValue"
              @change="$_changeThinkingTitle" :placeholder="languageData.titleDesc" />
            <b style="margin-left: 18px">{{ languageData.titleColor }}：</b>
            <color-select :color-list="colorList" v-model="style.titleColor" @update="update"></color-select>
          </div>
          <div class="setting-item">
            <b style="margin-left: 24px; margin-bottom: 39px">{{ languageData.describe }}：</b>
            <el-input type="textarea" :value="style.describe" style="width: 299px; padding: 10px 0px"
              @input="getDescribeInputValue" @change="$_changeDescribe" :placeholder="languageData.describeDesc" />
          </div>
          <div class="setting-item" style="
              display: flex;
              align-items: flex-start;
              flex-direction: column;
            ">
            <div>
              <b style="margin-left: 24px; margin-top: 8px">{{ languageData.material }}：</b>
              <el-button size="small" type="primary" @click="confirmSource">{{
                languageData.selectTemplate
              }}</el-button>
            </div>

            
          </div>

          <div class="setting-item" style="
              display: flex;
              align-items: flex-start;
              flex-direction: column;
            ">
            <div>
              <b style="margin-left: 24px; margin-top: 8px">{{ languageData.preset }}：</b>
              <el-button size="small" type="primary">{{
                languageData.upload
              }}</el-button>
              <el-button size="small" type="primary">{{
                languageData.edit
              }}</el-button>
            </div>

            <div v-if="style.sourceUrlList.length != 0" :style="'background-image: url(' +
              style.sourceUrlList[0].url +
              ');background-repeat: no-repeat;background-position: center center;background-size: 100% 100%;width:310px;height:245px;margin-left:24px;margin-top:10px;'
              "></div>
          </div>
        </template> -->
        <template v-if="style.type == 'my-group'">
          <div class="setting-item" style="display:none;">
            <b style="margin-left: 24px">{{ languageData.title }}：</b>
            <el-input
              :model-value="style.groupTitle"
              style="width: 280px"
              :placeholder="languageData.titleDesc"
              @input="getGroupInputValue"
              @change="$_changeGroupTitle"
            />
          </div>

          <div
            class="setting-item"
            style="
              display: flex;
              align-items: flex-start;
              flex-direction: row;
            "
          >
            <div :style="lang != 'en' ? 'margin-top: 7px;width: 40px;' : 'margin-top: 7px;width: 88px;'">
              <b style="margin-bottom: 39px; font-size: 12px; color: black"
                >{{ languageData.background }}：</b
              >
            </div>
            <div
              id="imageList"
              :style="lang != 'en' ? 'width: calc(100% - 40px);' : 'width: calc(100% - 88px);'"
            >
              <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleImageListRemove"
                :file-list="imageList"
                :auto-upload="true"
                accept="image/*"
                :http-request="uploadImage"
                list-type="picture"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <el-button size="small" type="primary">
                    {{ languageData.upload }}
                  </el-button>
                </div>
                <template #tip>
                  <div
                    class="el-upload__tip"
                    style="margin-left: 5px; margin-top: 0"
                  >
                    {{ languageData.uploadDesc }}
                  </div>
                </template>
              </el-upload>
              <el-progress
                v-if="isImageProgress"
                :stroke-width="16"
                :percentage="ImageProgressPercent"
                style="margin-top: 10px"
              ></el-progress>
            </div>
          </div>
        </template>
        <template
          v-if="
            !style.isIconSelect &&
            (style.type.indexOf('bezier') != -1 ||
              style.type.indexOf('line') != -1 ||
              style.type.indexOf('polyline') != -1 ||
              style.type.indexOf('curved') != -1)
          "
        >
          <div
            class="setting-item"
            style="
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
              "
            >
              <b style="font-size: 12px; color: black"
                >{{ languageData.tip121 }}：</b
              >
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: -9px;
              "
            >
              <el-radio
                v-model="style.isHidden"
                label="2"
                value="2"
                style="margin-right: 20px; margin-left: 20px"
                @change="setIsHidden"
              >
                {{ languageData.tip122 }}
              </el-radio>
              <el-radio
                v-model="style.isHidden"
                label="1"
                value="1"
                @change="setIsHidden"
              >
                {{ languageData.tip123 }}
              </el-radio>
            </div>
          </div>
        </template>
        <template
          v-if="
            style.isIconSelect == true &&
            (style.type.indexOf('bezier') != -1 ||
              style.type.indexOf('line') != -1 ||
              style.type.indexOf('polyline') != -1 ||
              style.type.indexOf('curved') != -1)
          "
        >
          <div
            class="setting-item"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            "
          >
            <div style="height: 100%">
              <b style="font-size: 12px; color: black"
                >{{ languageData.option }}：</b
              >
            </div>
            <!-- <el-radio v-model="style.route" label="0" value="0" @change="setRoute"
              style="margin-right:30px;margin-left: 10px;">正确</el-radio>
            <el-radio v-model="style.route" label="1" value="1" @change="setRoute">错误</el-radio> -->

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 300px;
                justify-content: center;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  flex-wrap: wrap;
                "
              >
                <template
                  v-for="(option, index) in allSelectOption"
                  :key="'letterList_' + index"
                >
                  <el-radio
                    v-model="style.route"
                    :label="option"
                    :value="option"
                    :disabled="
                      alSelectOption.length != 0
                        ? alSelectOption.includes(option)
                        : false
                    "
                    style="padding: 10px; margin-bottom: 0; margin-right: 0"
                    @change="setRoute"
                  >
                    {{ letterList[index] }}.
                    {{ option }}
                  </el-radio>
                </template>
              </div>
            </div>
          </div>
        </template>

        <template v-if="style.type == 'icon-AI'">
          <div class="setting-item" style="display: none">
            <b>{{ languageData.titleColor }}：</b>
          </div>
          <div class="setting-item">
            <b style="margin-left: 11px; margin-right: 14px; min-width: 37px"
              >{{ languageData.topic }}：</b
            >
            <el-input
              :model-value="style.topic"
              style="width: 100%"
              :placeholder="
                style.audioPa != '' ? style.audioPa : languageData.enterTopic
              "
              @input="getAIInputValue"
              @change="$_changeAITopic"
            />
            <i
              v-if="!isStop"
              class="micro_stop"
              :title="languageData.tip77"
              style="font-size: 15px; cursor: pointer"
              @click="start"
            >
              <svg
                style="
                  width: 1.5em;
                  height: 1.5em;
                  fill: currentColor;
                  fill-rule: evenodd;
                  color: #5d6565;
                "
                viewBox="0 0 16 27"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M4.235 1.803C4.848.823 6.013 0 8 0c1.986 0 3.152.824 3.765 1.803a3.826 3.826 0 0 1 .568 1.836V3.664s0 .003-1 .003h1v10.666h-1 1v.028a1.823 1.823 0 0 1-.01.176 3.827 3.827 0 0 1-.558 1.66C11.152 17.177 9.986 18 8 18c-1.987 0-3.152-.824-3.765-1.803a3.828 3.828 0 0 1-.568-1.836V14.336s0-.003 1-.003h-1V3.667h1-1v-.028a2.004 2.004 0 0 1 .01-.176 3.827 3.827 0 0 1 .558-1.66Zm1.432 1.874.002-.036a1.828 1.828 0 0 1 .262-.778C6.152 2.51 6.653 2 8 2s1.848.51 2.069.863a1.83 1.83 0 0 1 .264.814v10.646l-.002.036a1.83 1.83 0 0 1-.262.778c-.221.353-.722.863-2.07.863-1.346 0-1.847-.51-2.068-.863a1.828 1.828 0 0 1-.264-.814V3.677Zm-4.334 4.99a1 1 0 0 1 1 1v4.666l.001.03a5.657 5.657 0 0 0 .087.725c.09.49.27 1.13.62 1.76a4.464 4.464 0 0 0 1.62 1.69c.76.457 1.827.795 3.339.795s2.579-.338 3.34-.795a4.464 4.464 0 0 0 1.62-1.69c.349-.63.528-1.27.62-1.76a5.642 5.642 0 0 0 .086-.724V9.667a1 1 0 1 1 2 0v4.666h-1 1v.035a3.958 3.958 0 0 1-.016.302 7.653 7.653 0 0 1-.104.783 7.85 7.85 0 0 1-.839 2.366 6.464 6.464 0 0 1-2.338 2.434c-.902.541-2.013.914-3.37 1.037v3.377h4.334a1 1 0 0 1 0 2H2.667a1 1 0 1 1 0-2H7v-3.378c-1.356-.122-2.467-.495-3.369-1.036a6.463 6.463 0 0 1-2.339-2.434 7.852 7.852 0 0 1-.838-2.366 7.66 7.66 0 0 1-.118-1.015 3.637 3.637 0 0 1-.002-.07v-.032s0-.003 1-.003h-1V9.667a1 1 0 0 1 1-1Z"
                ></path>
              </svg>
            </i>
            <i
              v-else
              class="micro"
              :title="languageData.tip78"
              style="font-size: 15px; cursor: pointer; color: blue"
              @click="stop"
            >
              <svg
                style="
                  width: 1.5em;
                  height: 1.5em;
                  fill: currentColor;
                  color: #5d5cde;
                "
                viewBox="0 0 16 27"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M4.235 1.803C4.848.823 6.013 0 8 0c1.986 0 3.152.824 3.765 1.803a3.826 3.826 0 0 1 .568 1.836V3.664s0 .003-1 .003h1v10.666h-1 1v.028a1.823 1.823 0 0 1-.01.176 3.827 3.827 0 0 1-.558 1.66C11.152 17.177 9.986 18 8 18c-1.987 0-3.152-.824-3.765-1.803a3.828 3.828 0 0 1-.568-1.836V14.336s0-.003 1-.003h-1V3.667h1-1v-.028a2.004 2.004 0 0 1 .01-.176 3.827 3.827 0 0 1 .558-1.66Zm1.432 1.874.002-.036a1.828 1.828 0 0 1 .262-.778C6.152 2.51 6.653 2 8 2s1.848.51 2.069.863a1.83 1.83 0 0 1 .264.814v10.646l-.002.036a1.83 1.83 0 0 1-.262.778c-.221.353-.722.863-2.07.863-1.346 0-1.847-.51-2.068-.863a1.828 1.828 0 0 1-.264-.814V3.677Zm-4.334 4.99a1 1 0 0 1 1 1v4.666l.001.03a5.657 5.657 0 0 0 .087.725c.09.49.27 1.13.62 1.76a4.464 4.464 0 0 0 1.62 1.69c.76.457 1.827.795 3.339.795s2.579-.338 3.34-.795a4.464 4.464 0 0 0 1.62-1.69c.349-.63.528-1.27.62-1.76a5.642 5.642 0 0 0 .086-.724V9.667a1 1 0 1 1 2 0v4.666h-1 1v.035a3.958 3.958 0 0 1-.016.302 7.653 7.653 0 0 1-.104.783 7.85 7.85 0 0 1-.839 2.366 6.464 6.464 0 0 1-2.338 2.434c-.902.541-2.013.914-3.37 1.037v3.377h4.334a1 1 0 0 1 0 2H2.667a1 1 0 1 1 0-2H7v-3.378c-1.356-.122-2.467-.495-3.369-1.036a6.463 6.463 0 0 1-2.339-2.434 7.852 7.852 0 0 1-.838-2.366 7.66 7.66 0 0 1-.118-1.015 3.637 3.637 0 0 1-.002-.07v-.032s0-.003 1-.003h-1V9.667a1 1 0 0 1 1-1Z"
                ></path>
              </svg>
            </i>
            <el-button
              v-if="!isSending"
              size="small"
              type="primary"
              style="margin-left: 10px"
              @click="askForTopic"
            >
              {{ languageData.gain }}
            </el-button>
            <el-button
              v-else
              size="small"
              type="primary"
              style="margin-left: 10px"
              @click="stopAsk"
            >
              {{ languageData.tip88 }}
            </el-button>
            <color-select
              :color-list="colorList"
              :color="style.AITitleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="false"
              @update="updateAI"
              @selfAdaption="selfAdaption"
            ></color-select>
          </div>
          <div
            class="setting-item"
            style="display: flex; align-items: flex-start; flex-direction: row"
          >
            <b
              :style="
                lang == 'en'
                  ? 'color: black;margin-top: 10px;'
                  : 'color: black;margin-top: 10px;'
              "
            >
              <el-radio
                v-model="style.radio"
                label="description"
                value="description"
                @change="setSelect"
                ><b style="font-size: 12px; color: black; margin-top: 5px"
                  >{{ languageData.description }}：</b
                ></el-radio
              >
            </b>
            <el-input
              type="textarea"
              autosize
              :model-value="style.aiContent"
              :placeholder="languageData.describeDesc"
              style="padding: 10px 0px;flex:1;"
              @input="icon_ai_text"
            />
          </div>
          <!-- <div style="text-align: center">
            <img src="../../assets/loading.gif" v-if="style.isLoading" />
          </div> -->
          <div
            v-if="is_description"
            class="setting-item"
            style="
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-bottom: 20px;
            "
          >
            <!-- <b style="margin-right: 10px;font-size: 12px;color: black;">{{ style.keyWordList.length != 0 ?
              languageData.tip14 : languageData.tip12 }}</b> -->
            <el-button
              size="small"
              type="primary"
              style="margin-left: 11px; font-size: 12px; cursor: pointer"
              @click="getMoreKeyword"
            >
              {{ languageData.tip14 }}
            </el-button>
            <el-button
              size="small"
              type="primary"
              style="font-size: 12px; cursor: pointer"
              @click="getMoreQuestion"
            >
              {{ languageData.tip16 }}
            </el-button>
          </div>
          <div
            v-if="style.keyWordList.length != 0"
            class="setting-item"
            style="align-items: flex-start"
          >
            <b style="margin-left: 11px; min-width: 60px; margin-right: 5px"
              >{{ languageData.keyword }}：</b
            >
            <div
              style="
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
              "
            >
              <template v-for="(item, index) in style.keyWordList" :key="index">
                <el-tag
                  :color="style.tagColor[index]"
                  style="
                    margin-right: 10px;
                    margin-bottom: 10px;
                    color: #fff;
                    cursor: pointer;
                  "
                  @click="askForKeyword"
                >
                  {{ item }}
                </el-tag>
                <!-- <el-tag  effect="plain"
                  :style="'margin-right:10px;margin-bottom: 10px;color:' + style.tagColor[index] + ';border-color:' + style.tagColor[index] + ';cursor: pointer;'">{{ item }}</el-tag> -->
              </template>
            </div>
          </div>
          <div style="text-align: center">
            <img v-if="isKeywordLoading" src="../../assets/loading.gif" />
          </div>
          <!-- <div class="setting-item" style="display:flex;align-items: center;flex-direction: row;"
            v-if="style.aiContent != ''">
            <b style="margin-right: 10px;font-size: 12px;color: black;">{{ style.questionList.length != 0 ?
              languageData.tip16 : languageData.tip15 }}</b>
            <el-button size="small" type="primary" style="font-size: 12px;cursor: pointer;" @click="getMoreQuestion">{{
              languageData.tip16 }}</el-button>
          </div> -->
          <div
            v-if="style.questionList.length != 0"
            class="setting-item"
            style="align-items: flex-start"
          >
            <b style="margin-left: 11px; min-width: 37px; margin-right: 5px"
              >{{ languageData.question_new }}：</b
            >
            <div
              style="
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                margin-left: 10px;
              "
            >
              <template
                v-for="(item, index) in style.questionList"
                :key="index"
              >
                <el-tag
                  :color="style.tagColor[index]"
                  :title="item.replace(index + 1 + '.', '')"
                  style="
                    line-height: 25px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    color: #fff;
                    cursor: pointer;
                    word-wrap: break-word;
                    height: auto;
                    width: 280px;
                    white-space: normal;
                  "
                  @click="askForQuestion"
                >
                  {{ item.replace(index + 1 + ".", "") }}
                </el-tag>
              </template>
            </div>
          </div>
          <div style="text-align: center">
            <img v-if="isQuestionLoading" src="../../assets/loading.gif" />
          </div>
          <div
            v-if="style.questionList.length != 0"
            class="setting-item"
            style="align-items: flex-start; justify-content: space-between"
          >
            <b style="min-width: 60px">
              <el-radio
                v-model="style.radio"
                label="image"
                value="image"
                style="margin-bottom: 10px"
                @change="setSelect"
              >
                <b style="font-size: 12px; color: black"
                  >{{ languageData.picture }}：</b
                >
              </el-radio>
            </b>
            <el-button
              size="small"
              style="
                background: none;
                border: none;
                padding: 0;
                font-size: 12px;
                margin-top: 2px;
              "
              @click="switchPage"
            >
              {{ languageData.change }}
            </el-button>
          </div>
          <div style="text-align: center">
            <img v-if="isImageLoading" src="../../assets/loading.gif" />
          </div>
          <div
            v-if="style.imgResultList.length != 0"
            style="
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-left: 10px;
              justify-content: flex-start;
            "
          >
            <template v-for="(item, index) in style.imgResultList" :key="index">
              <el-image
                v-if="style.imgResult == item.thumbnail"
                style="
                  width: 80px;
                  height: 80px;
                  margin-left: 10px;
                  margin-right: 10px;
                  margin-bottom: 20px;
                  padding: 5px;
                  background: #2995ff;
                "
                :src="item.thumbnail"
                fit="cover"
                @click="selectImage(item.thumbnail)"
              ></el-image>
              <el-image
                v-else
                style="
                  width: 80px;
                  height: 80px;
                  margin-left: 10px;
                  margin-right: 10px;
                  margin-bottom: 20px;
                "
                :src="item.thumbnail"
                fit="cover"
                @click="selectImage(item.thumbnail)"
              ></el-image>
            </template>
          </div>
        </template>

        <template v-if="style.type == 'icon-markdown'">
          <div class="setting-item">
            <b style="margin-left: 24px; min-width: 37px"
              >{{ languageData.title }}：</b
            >
            <el-input
              :model-value="style.topic"
              :placeholder="languageData.titleDesc"
              @input="getAIInputValue"
              @change="$_changeAITopic"
            />
            <b
              :style="
                lang == 'en'
                  ? 'margin-left: 10px;min-width: 80px;'
                  : 'margin-left: 10px;min-width: 60px;'
              "
              >{{ languageData.titleColor }}：</b
            >
            <color-select
              :color-list="colorList"
              :color="style.MarkdownTitleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="false"
              @update="updateMarkdown"
              @selfAdaption="selfAdaption"
            ></color-select>
          </div>
          <div
            class="setting-item"
            style="display: flex; align-items: flex-start; flex-direction: row"
          >
            <el-tiptap
              v-if="lang == 'en'"
              id="tiptap1"
              :content="style.aiContent"
              placeholder=""
              :extensions="extensions"
              style="height: 400px"
              lang="en"
              @onUpdate="onUpdateAIContent"
              @onBlur="onBlurAIContent"
            />
            <el-tiptap
              v-else-if="lang == 'zh'"
              id="tiptap1"
              :content="style.aiContent"
              placeholder=""
              :extensions="extensions"
              style="height: 400px"
              lang="zh"
              @onUpdate="onUpdateAIContent"
              @onBlur="onBlurAIContent"
            />
            <el-tiptap
              v-else-if="lang == 'zh_tw'"
              id="tiptap1"
              :content="style.aiContent"
              placeholder=""
              :extensions="extensions"
              style="height: 400px"
              lang="zh_tw"
              @onUpdate="onUpdateAIContent"
              @onBlur="onBlurAIContent"
            />
          </div>
        </template>

        <template v-if="style.type == 'icon-new-AI'">
          <div
            v-if="style.AINewFileList.length != 0"
            class="setting-item"
            style="justify-content: center; position: relative"
          >
            <img
              v-if="style.fileType.indexOf('image/') != -1"
              ref="newAIImg"
              :src="style.AINewFileList[0].url"
              style="width: 60%; height: auto"
            />
            <audio
              v-else-if="style.fileType.indexOf('audio/') != -1"
              controls
              :src="style.AINewFileList[0].url"
            ></audio>
            <div v-else>
              {{ style.AINewFileList[0].name }}
            </div>
            <i
              id="icon-new-AI"
              class="el-icon el-icon-close"
              style="
                font-size: 18px;
                right: 0;
                top: 0;
                position: absolute;
                cursor: pointer;
              "
              @click="deleteAINewFileList"
            ></i>
          </div>

          <div
            v-if="
              style.AINewFileList.length != 0 &&
              style.fileType.indexOf('image/') != -1
            "
            class="setting-item"
            style="position: relative"
          >
            <el-badge
              :is-dot="style.isImageToText"
              class="item"
              style="
                position: relative;
                bottom: 0;
                right: 0;
                margin-bottom: 0;
                margin-right: 20px;
                margin-left: 0;
              "
            >
              <el-button
                size="small"
                class="share-button"
                :type="style.isImageToText ? 'primary' : ''"
                :title="languageData.tip81"
                @click="changeImageToText"
              >
                {{ languageData.tip81 }}
              </el-button>
            </el-badge>

            <el-badge
              :is-dot="style.isImageToImage"
              class="item"
              style="
                position: relative;
                bottom: 0;
                right: 0;
                margin-bottom: 0;
                margin-right: 20px;
                margin-left: 0;
              "
            >
              <el-button
                size="small"
                class="share-button"
                :type="style.isImageToImage ? 'primary' : ''"
                :title="languageData.tip82"
                @click="changeImageToImage"
              >
                {{ languageData.tip82 }}
              </el-button>
            </el-badge>
          </div>

          <div class="setting-item" style="justify-content: center">
            <el-progress
              v-if="isFileProgress"
              :stroke-width="16"
              :percentage="fileProgressPercent"
              style="margin-top: 10px; width: 100%"
            ></el-progress>
          </div>
          <div class="setting-item" style="position: relative">
            <input
              id="new-AI"
              type="file"
              style="display: none"
              accept=".png,.pdf,.doc,.docx,.xls,.xlsx,.wav"
              @change="uploadFileNew"
            />
            <svg
              :style="
                lang == 'en'
                  ? 'z-index: 1030;position: absolute;right: 165px;bottom: 19px;cursor: pointer;'
                  : 'z-index: 1030;position: absolute;right: 151px;bottom: 19px;cursor: pointer;'
              "
              t="1700710107829"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4363"
              width="18"
              height="18"
              @click="selectFileNew"
            >
              <title>{{ languageData.tip80 }}</title>
              <path
                d="M757.334756 0a180.167111 180.167111 0 0 1 179.882666 169.927111l0.284445 10.24v360.049778a28.444444 28.444444 0 0 1-56.433778 5.12l-0.455111-5.12v-360.106667a123.278222 123.278222 0 0 0-114.460445-122.88L757.334756 56.888889H208.129422a123.278222 123.278222 0 0 0-122.993778 114.460444L84.8512 180.167111v662.584889c0 65.137778 50.517333 118.442667 114.460444 122.993778l8.817778 0.284444h361.244445a28.444444 28.444444 0 0 1 5.12 56.433778l-5.12 0.455111h-361.244445a180.167111 180.167111 0 0 1-179.882666-169.927111L27.962311 842.752V180.167111A180.167111 180.167111 0 0 1 197.889422 0.284444L208.129422 0h549.205334z m11.093333 566.328889a28.444444 28.444444 0 0 1 27.989333 23.324444l0.455111 5.12v170.666667h170.666667a28.444444 28.444444 0 0 1 5.12 56.433778l-5.12 0.455111h-170.666667v170.666667a28.444444 28.444444 0 0 1-56.433777 5.12l-0.455112-5.12v-170.666667h-170.666666a28.444444 28.444444 0 0 1-5.12-56.376889l5.12-0.512h170.666666v-170.666667a28.444444 28.444444 0 0 1 28.444445-28.444444z m-408.746667 116.394667a28.444444 28.444444 0 0 1 5.12 56.433777L359.624533 739.555556H198.060089a28.444444 28.444444 0 0 1-5.12-56.433778l5.12-0.455111h161.564444z m113.777778-227.555556a28.444444 28.444444 0 0 1 5.12 56.433778L473.402311 512H198.060089a28.444444 28.444444 0 0 1-5.12-56.433778l5.12-0.455111h275.342222z m293.432889-227.555556a28.444444 28.444444 0 0 1 5.12 56.433778l-5.12 0.455111h-568.888889a28.444444 28.444444 0 0 1-5.12-56.433777l5.12-0.455112h568.888889z"
                fill="#2c2c2c"
                p-id="4364"
              ></path>
            </svg>
            <!-- <el-icon style="z-index: 1030;font-size: 25px;position: absolute;right: 170px;bottom: 12px;cursor: pointer;" @click="selectFileNew"><Upload /></el-icon> -->
            <b :style="lang == 'en' ? 'min-width:100px;' : 'min-width:37px;'"
              >{{ languageData.ask_question }}：</b
            >
            <el-input
              id="new_AI_input"
              type="textarea"
              :autosize="{ minRows: 2 }"
              :value="style.topic"
              :disabled="style.isImageToImage"
              style="width: 100%; padding: 10px 0"
              :placeholder="
                style.audioPa != '' ? style.audioPa : languageData.tip79
              "
              @input="getAIInputValueNew"
              @change="$_changeAITopic"
            >
            </el-input>
            <i
              v-if="!isStop"
              class="micro_stop"
              :title="languageData.tip77"
              style="font-size: 15px; cursor: pointer; margin-left: 0"
              @click="start"
            >
              <svg
                style="
                  width: 1.5em;
                  height: 1.5em;
                  fill: currentColor;
                  fill-rule: evenodd;
                  color: #5d6565;
                "
                viewBox="0 0 16 27"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M4.235 1.803C4.848.823 6.013 0 8 0c1.986 0 3.152.824 3.765 1.803a3.826 3.826 0 0 1 .568 1.836V3.664s0 .003-1 .003h1v10.666h-1 1v.028a1.823 1.823 0 0 1-.01.176 3.827 3.827 0 0 1-.558 1.66C11.152 17.177 9.986 18 8 18c-1.987 0-3.152-.824-3.765-1.803a3.828 3.828 0 0 1-.568-1.836V14.336s0-.003 1-.003h-1V3.667h1-1v-.028a2.004 2.004 0 0 1 .01-.176 3.827 3.827 0 0 1 .558-1.66Zm1.432 1.874.002-.036a1.828 1.828 0 0 1 .262-.778C6.152 2.51 6.653 2 8 2s1.848.51 2.069.863a1.83 1.83 0 0 1 .264.814v10.646l-.002.036a1.83 1.83 0 0 1-.262.778c-.221.353-.722.863-2.07.863-1.346 0-1.847-.51-2.068-.863a1.828 1.828 0 0 1-.264-.814V3.677Zm-4.334 4.99a1 1 0 0 1 1 1v4.666l.001.03a5.657 5.657 0 0 0 .087.725c.09.49.27 1.13.62 1.76a4.464 4.464 0 0 0 1.62 1.69c.76.457 1.827.795 3.339.795s2.579-.338 3.34-.795a4.464 4.464 0 0 0 1.62-1.69c.349-.63.528-1.27.62-1.76a5.642 5.642 0 0 0 .086-.724V9.667a1 1 0 1 1 2 0v4.666h-1 1v.035a3.958 3.958 0 0 1-.016.302 7.653 7.653 0 0 1-.104.783 7.85 7.85 0 0 1-.839 2.366 6.464 6.464 0 0 1-2.338 2.434c-.902.541-2.013.914-3.37 1.037v3.377h4.334a1 1 0 0 1 0 2H2.667a1 1 0 1 1 0-2H7v-3.378c-1.356-.122-2.467-.495-3.369-1.036a6.463 6.463 0 0 1-2.339-2.434 7.852 7.852 0 0 1-.838-2.366 7.66 7.66 0 0 1-.118-1.015 3.637 3.637 0 0 1-.002-.07v-.032s0-.003 1-.003h-1V9.667a1 1 0 0 1 1-1Z"
                ></path>
              </svg>
            </i>
            <i
              v-else
              class="micro"
              :title="languageData.tip78"
              style="
                font-size: 15px;
                cursor: pointer;
                color: blue;
                margin-left: 0;
              "
              @click="stop"
            >
              <svg
                style="
                  width: 1.5em;
                  height: 1.5em;
                  fill: currentColor;
                  color: #5d5cde;
                "
                viewBox="0 0 16 27"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M4.235 1.803C4.848.823 6.013 0 8 0c1.986 0 3.152.824 3.765 1.803a3.826 3.826 0 0 1 .568 1.836V3.664s0 .003-1 .003h1v10.666h-1 1v.028a1.823 1.823 0 0 1-.01.176 3.827 3.827 0 0 1-.558 1.66C11.152 17.177 9.986 18 8 18c-1.987 0-3.152-.824-3.765-1.803a3.828 3.828 0 0 1-.568-1.836V14.336s0-.003 1-.003h-1V3.667h1-1v-.028a2.004 2.004 0 0 1 .01-.176 3.827 3.827 0 0 1 .558-1.66Zm1.432 1.874.002-.036a1.828 1.828 0 0 1 .262-.778C6.152 2.51 6.653 2 8 2s1.848.51 2.069.863a1.83 1.83 0 0 1 .264.814v10.646l-.002.036a1.83 1.83 0 0 1-.262.778c-.221.353-.722.863-2.07.863-1.346 0-1.847-.51-2.068-.863a1.828 1.828 0 0 1-.264-.814V3.677Zm-4.334 4.99a1 1 0 0 1 1 1v4.666l.001.03a5.657 5.657 0 0 0 .087.725c.09.49.27 1.13.62 1.76a4.464 4.464 0 0 0 1.62 1.69c.76.457 1.827.795 3.339.795s2.579-.338 3.34-.795a4.464 4.464 0 0 0 1.62-1.69c.349-.63.528-1.27.62-1.76a5.642 5.642 0 0 0 .086-.724V9.667a1 1 0 1 1 2 0v4.666h-1 1v.035a3.958 3.958 0 0 1-.016.302 7.653 7.653 0 0 1-.104.783 7.85 7.85 0 0 1-.839 2.366 6.464 6.464 0 0 1-2.338 2.434c-.902.541-2.013.914-3.37 1.037v3.377h4.334a1 1 0 0 1 0 2H2.667a1 1 0 1 1 0-2H7v-3.378c-1.356-.122-2.467-.495-3.369-1.036a6.463 6.463 0 0 1-2.339-2.434 7.852 7.852 0 0 1-.838-2.366 7.66 7.66 0 0 1-.118-1.015 3.637 3.637 0 0 1-.002-.07v-.032s0-.003 1-.003h-1V9.667a1 1 0 0 1 1-1Z"
                ></path>
              </svg>
            </i>
            <el-button
              size="small"
              type="primary"
              style="margin-left: 0"
              :title="languageData.sumbit_question"
              @click="sumbit_question"
            >
              {{ languageData.sumbit_question }}
            </el-button>
            <color-select
              :color-list="colorList"
              :color="style.AITitleColor"
              :is-self-adaption="style.isExistSelfAdaption"
              :is-exist-self-adaption="false"
              @update="updateAI"
              @selfAdaption="selfAdaption"
            ></color-select>
          </div>

          <div
            v-if="
              style.AINewFileList.length == 0 ||
              (style.AINewFileList.length != 0 &&
                style.fileType.indexOf('audio/') != -1 &&
                style.topic != '')
            "
            class="setting-item"
            style="position: relative"
          >
            <el-badge
              :is-dot="style.isTextToText"
              class="item"
              style="
                position: relative;
                bottom: 0;
                right: 0;
                margin-bottom: 0;
                margin-right: 10px;
                margin-left: 0;
              "
            >
              <el-button
                size="small"
                class="share-button"
                :type="style.isTextToText ? 'primary' : ''"
                :title="languageData.tip81"
                @click="changeTextToText"
              >
                {{ languageData.tip81 }}
              </el-button>
            </el-badge>

            <el-badge
              :is-dot="style.isTextToImage"
              class="item"
              style="
                position: relative;
                bottom: 0;
                right: 0;
                margin-bottom: 0;
                margin-right: 10px;
                margin-left: 0;
              "
            >
              <el-button
                size="small"
                class="share-button"
                :type="style.isTextToImage ? 'primary' : ''"
                :title="languageData.tip82"
                @click="changeTextToImage"
              >
                {{ languageData.tip82 }}
              </el-button>
            </el-badge>

            <el-badge
              :is-dot="style.isTextToAudio"
              class="item"
              style="
                position: relative;
                bottom: 0;
                right: 0;
                margin-bottom: 0;
                margin-right: 0;
                margin-left: 0;
              "
            >
              <el-button
                size="small"
                class="share-button"
                :type="style.isTextToAudio ? 'primary' : ''"
                :title="languageData.tip83"
                @click="changeTextToAudio"
              >
                {{ languageData.tip83 }}
              </el-button>
            </el-badge>
          </div>

          <div class="setting-item" style="position: relative">
            <!-- <b :style="lang == 'en' ? 'min-width:60px;' : 'min-width:37px;'">{{ languageData.answer_question }}：</b> -->
            <b
              :style="
                lang == 'en'
                  ? 'color: black;margin-top: 10px;min-width:105px;'
                  : 'color: black;margin-top: 10px;min-width:65px;'
              "
            >
              <el-radio
                v-model="style.radio"
                label="description"
                value="description"
                @change="setSelect"
                ><b style="font-size: 12px; color: black; margin-top: 5px"
                  >{{ languageData.answer_question }}：</b
                ></el-radio
              >
            </b>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              :model-value="style.aiContent"
              :placeholder="style.answer"
              style="width: 100%; padding: 10px 0"
              @input="icon_ai_text"
            />
          </div>

          <div style="text-align: center">
            <img v-if="isImageLoading" src="../../assets/loading.gif" />
          </div>

          <div
            v-if="style.imgResult != ''"
            class="setting-item"
            style="position: relative"
          >
            <b
              :style="
                lang == 'en'
                  ? 'color: black;margin-top: 10px;min-width:105px;'
                  : 'color: black;margin-top: 10px;min-width:65px;'
              "
            >
              <el-radio
                v-model="style.radio"
                label="image"
                value="image"
                @change="setSelect"
                ><b style="font-size: 12px; color: black; margin-top: 5px"
                  >{{ languageData.picture }}：</b
                ></el-radio
              >
            </b>
            <img :src="style.imgResult" style="width: 340px" />
          </div>

          <div style="text-align: center">
            <img v-if="isAudioLoading" src="../../assets/loading.gif" />
          </div>

          <div
            v-if="style.audioList.length != 0"
            class="setting-item"
            style="position: relative"
          >
            <b
              :style="
                lang == 'en'
                  ? 'color: black;margin-top: 10px;min-width:105px;'
                  : 'color: black;margin-top: 10px;min-width:65px;'
              "
            >
              <el-radio
                v-model="style.radio"
                label="audio"
                value="audio"
                @change="setSelect"
                ><b style="font-size: 12px; color: black; margin-top: 5px"
                  >{{ languageData.voice }}：</b
                ></el-radio
              >
            </b>
            <audio :src="style.audioList[0].url" controls></audio>
          </div>

          <div
            class="setting-item"
            style="position: relative; justify-content: flex-end"
          >
            <el-button
              size="small"
              type="primary"
              style="margin-left: 0"
              :title="languageData.sumbit_question_again"
              @click="sumbit_question_again"
            >
              {{ languageData.sumbit_question_again }}
            </el-button>
          </div>
        </template>

        <template
          v-if="
            style.type.indexOf('icon-pm') != -1 ||
            (style.type.indexOf('icon-st') != -1 &&
              style.type.indexOf('icon-stage') == -1)
          "
        >
          <div class="setting-item">
            <b style="margin-left: 11px; margin-right: 14px; min-width: 37px"
              >{{ languageData.move_key }}：</b
            >
          </div>

          <div class="setting-item">
            <span>{{ languageData.move_key_1 }}</span>
            <el-input
              :model-value="style.up"
              style="width: 100%"
              @input="getUpInputValue"
              @change="$_changeUp"
              @keydown="getUpInputValueNew"
            />

            <span style="margin-left: 15px">{{ languageData.move_key_2 }}</span>
            <el-input
              :model-value="style.down"
              style="width: 100%"
              @input="getDownInputValue"
              @change="$_changeDown"
              @keydown="getDownInputValueNew"
            />
          </div>

          <div class="setting-item">
            <span>{{ languageData.move_key_3 }}</span>
            <el-input
              :model-value="style.left"
              style="width: 100%"
              @input="getLeftInputValue"
              @change="$_changeLeft"
              @keydown="getLeftInputValueNew"
            />

            <span style="margin-left: 15px">{{ languageData.move_key_4 }}</span>
            <el-input
              :model-value="style.right"
              style="width: 100%"
              @input="getRightInputValue"
              @change="$_changeRight"
              @keydown="getRightInputValueNew"
            />
          </div>

          <div class="setting-item">
            <b style="margin-left: 11px; margin-right: 14px; min-width: 37px">
              {{ languageData.move_distance }}：
            </b>
          </div>

          <div class="setting-item">
            <span>{{ languageData.move_distance_x }}</span>
            <el-input
              :model-value="style.distance_x"
              style="width: 100%"
              @input="getXInputValue"
              @change="$_changeX"
            />

            <span style="margin-left: 15px">{{
              languageData.move_distance_y
            }}</span>
            <el-input
              :model-value="style.distance_y"
              style="width: 100%"
              @input="getYInputValue"
              @change="$_changeY"
            />
          </div>
        </template>
      </el-tab-pane>
      <template
        v-if="
          style.type.indexOf('bezier') == -1 &&
          style.type.indexOf('line') == -1 &&
          style.type.indexOf('polyline') == -1 &&
          style.type.indexOf('curved') == -1 &&
          style.type.indexOf('icon-theme') == -1 &&
          style.type.indexOf('icon-pm') == -1 &&
          style.type.indexOf('icon-st-') == -1
        "
      >
        <el-tab-pane style="width: 100%" :label="languageData.previewMode">
          <template v-if="style.type == 'icon-stage'">
            <el-card
              class="box-card"
              :style="style.radio == 'iframeUrl' ? 'padding-bottom: 40px;' : ''"
            >
              <template #header>
                <div class="clearfix">
                  <span
                    ><b
                      >{{ languageData.stageTip }} {{ style.stageName }}</b
                    ></span
                  >
                </div>
              </template>
              <div class="item">
                <span v-html="style.stageContent"></span>
              </div>
            </el-card>
          </template>
          <template v-if="style.type == 'icon-task'">
            <el-card
              class="box-card"
              :style="style.radio == 'iframeUrl' ? 'padding-bottom: 40px;' : ''"
            >
              <template #header>
                <div class="clearfix">
                  <span
                    ><b
                      >{{ languageData.taskTip }} {{ style.taskTitle }}</b
                    ></span
                  >
                </div>
              </template>
              <div class="item">
                <span v-html="style.taskContent"></span>
              </div>
            </el-card>
          </template>
          <template v-if="style.type == 'icon-card'">
            <el-card
              class="box-card"
              :style="
                style.radio == 'iframeUrl'
                  ? 'padding-bottom: 40px;'
                  : 'overflow-y:auto;overflow-x:hidden;'
              "
            >
              <template #header>
                <div class="clearfix">
                  <span
                    ><b>{{ style.title }}</b></span
                  ><br />
                  <span style="font-size: 14px">{{ style.describe }}</span>
                </div>
              </template>
              <div class="item">
                <div
                  v-if="style.radio == 'content'"
                  style="word-wrap: break-word"
                >
                  <span v-html="style.content"></span>
                </div>
                <div
                  v-else-if="style.radio == 'iframeUrl'"
                  style="margin-top: 10px; width: 225px; height: 155px"
                >
                  <iframe
                    style="
                      position: absolute;
                      transform: scale(0.5, 0.5) translate(-47%, -50%);
                      width: 187%;
                      height: 160%;
                      top: 50px;
                      left: 0;
                    "
                    :src="
                      style.iframeUrl.indexOf('https://') != -1 ||
                      style.iframeUrl.indexOf('http://') != -1
                        ? style.iframeUrl
                        : '//' + style.iframeUrl
                    "
                    frameborder="no"
                    border="0"
                    sandbox="allow-forms allow-scripts allow-same-origin"
                  ></iframe>
                </div>
                <div
                  v-else-if="style.radio == 'embedCode'"
                  style="margin-top: 10px; width: 225px; height: 155px"
                  v-html="style.embedCode"
                ></div>
                <div
                  v-else-if="
                    style.radio == 'imageList' && style.imageList.length != 0
                  "
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <img
                    style="
                      width: auto;
                      height: auto;
                      max-width: 100%;
                      max-height: 100%;
                    "
                    :src="style.imageList[0].url"
                  />
                </div>
                <div
                  v-else-if="
                    style.radio == 'videoList' && style.videoList.length != 0
                  "
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <video
                    style="
                      width: auto;
                      height: auto;
                      max-width: 100%;
                      max-height: 100%;
                    "
                    controls
                    :src="style.videoList[0].url"
                  ></video>
                </div>
                <div
                  v-else-if="
                    style.radio == 'audioList' && style.audioList.length != 0
                  "
                >
                  <audio controls :src="style.audioList[0].url"></audio>
                </div>
                <div
                  v-else-if="
                    style.radio == 'fileList' && style.fileList.length != 0
                  "
                  style="height: 530px"
                >
                  <iframe
                    v-if="
                      style.fileList[0].url.indexOf('.pdf') != -1 ||
                      style.fileList[0].url.indexOf('.PDF') != -1
                    "
                    style="
                      position: absolute;
                      transform: scale(0.5, 0.5) translate(-47%, -50%);
                      width: 187%;
                      height: 160%;
                      top: 50px;
                      left: 0;
                    "
                    frameborder="0"
                    :src="
                      'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' +
                      style.fileList[0].url
                    "
                  ></iframe>
                  <iframe
                    v-else
                    style="
                      position: absolute;
                      transform: scale(0.5, 0.5) translate(-47%, -50%);
                      width: 187%;
                      height: 160%;
                      top: 50px;
                      left: 0;
                    "
                    frameborder="0"
                    :src="
                      'https://view.officeapps.live.com/op/view.aspx?src=' +
                      style.fileList[0].url
                    "
                  ></iframe>
                </div>
              </div>
            </el-card>
          </template>
          <template v-if="style.type == 'icon-select'">
            <el-card
              class="box-card"
              :style="style.radio == 'iframeUrl' ? 'padding-bottom: 40px;' : ''"
            >
              <template #header>
                <div class="clearfix">
                  <span
                    ><b>{{ style.selectTitle }}</b></span
                  >
                </div>
              </template>
              <div class="item">
                <div>{{ style.selectContent }}</div>
                <div
                  v-if="style.selectOption.length != 1"
                  style="margin-top: 10px"
                >
                  <template
                    v-for="(option, index) in style.selectOption"
                    :key="'letterList' + index"
                  >
                    <el-radio
                      v-model="Radio"
                      :label="option"
                      :value="option"
                      style="margin-right: 30px; margin-bottom: 20px"
                    >
                      {{ letterList[index] }}.
                      {{ option }}
                    </el-radio>
                  </template>
                </div>
              </div>
            </el-card>
          </template>
          <template v-if="style.type == 'icon-thinking'"> </template>
          <template v-if="style.type == 'icon-whiteboard'"> </template>
          <template v-if="style.type == 'icon-AI'">
            <el-card class="box-card">
              <template #header>
                <div class="clearfix">
                  <span
                    ><b>{{ style.topic }}</b></span
                  ><br />
                </div>
              </template>
              <div class="item">
                <div
                  v-if="style.radio == 'description'"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div>
                    <span class="text">{{ style.aiContent }}</span>
                  </div>
                </div>
                <div
                  v-else-if="style.radio == 'image' && style.imgResult != ''"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <img
                    style="
                      width: auto;
                      height: auto;
                      max-width: 100%;
                      max-height: 100%;
                    "
                    :src="style.imgResult"
                  />
                </div>
              </div>
            </el-card>
          </template>
          <template v-if="style.type == 'icon-markdown'">
            <el-card class="box-card">
              <template #header>
                <div class="clearfix">
                  <span
                    ><b>{{ style.topic }}</b></span
                  ><br />
                </div>
              </template>
              <div class="item">
                <div
                  v-if="style.radio == 'description'"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div>
                    <span class="text" v-html="style.aiContent"></span>
                  </div>
                </div>
              </div>
            </el-card>
          </template>

          <template v-if="style.type == 'icon-new-AI'">
            <el-card class="box-card">
              <template #header>
                <div class="clearfix">
                  <span
                    ><b>{{ style.topic }}</b></span
                  ><br />
                </div>
              </template>
              <div class="item">
                <div
                  v-if="style.radio == 'description'"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div>
                    <span class="text">{{ style.aiContent }}</span>
                  </div>
                </div>
                <div
                  v-else-if="style.radio == 'image' && style.imgResult != ''"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <img
                    style="
                      width: auto;
                      height: auto;
                      max-width: 100%;
                      max-height: 100%;
                    "
                    :src="style.imgResult"
                  />
                </div>
                <div
                  v-else-if="
                    style.radio == 'audio' && style.audioList.length != 0
                  "
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <audio
                    style="max-width: 100%; max-height: 100%"
                    :src="style.audioList[0].url"
                    controls
                  ></audio>
                </div>
              </div>
            </el-card>
          </template>
        </el-tab-pane>
      </template>
      <!-- <el-tab-pane>
          <span slot="label">样式设置</span>
          <div class="setting-block">
            <div>快捷样式</div>
            <div class="short-styles">
              <div v-for="(item, index) in shortStyles" :key="index"
                :style="{ 'backgroundColor': item.backgroundColor, 'borderColor': item.borderColor, 'borderWidth': item.borderWidth }"
                @click="setStyle(item)">
              </div>
            </div>
          </div>
          <div class="setting-block">
            <div class="setting-item">
              <span>背景色</span>
              <el-popover placement="top-start" title="填充样式" width="220" trigger="click">
                <sketch-picker :value="style.backgroundColor"
                  @input="(c) => $_changeColorProperty(c, 'backgroundColor')" />
                <div class="border-color" :style='{ "backgroundColor": style.backgroundColor }' slot="reference"></div>
              </el-popover>
              <span>背景渐变色</span>
              <el-popover placement="top-start" title="填充样式" width="220" trigger="click">
                <sketch-picker :value="style.gradientColor" @input="(c) => $_changeColorProperty(c, 'gradientColor')" />
                <div class="border-color" :style='{ "backgroundColor": style.gradientColor }' slot="reference"></div>
              </el-popover>
            </div>
            <div class="setting-item">
              <span>线条样式</span>
              <el-select v-model="style.borderStyle" size="small" @change="$_selectBorder">
                <el-option value="hidden" label="不显示"></el-option>
                <el-option v-for="(border, index) in borderStyles" :value="border.value" :key="index">
                  <div class="border-style" :style="{ 'borderBottomStyle': border.value }"></div>
                </el-option>
              </el-select>
            </div>
            <div class="setting-item">
              <span>线条颜色</span>
              <el-popover placement="top-start" title="填充样式" width="220" trigger="click">
                <sketch-picker :value="style.borderColor" @input="(c) => $_changeColorProperty(c, 'borderColor')" />
                <div class="border-color" :style='{ "backgroundColor": style.borderColor }' slot="reference"></div>
              </el-popover>
            </div>
            <div class="setting-item">
              <span>线条宽度</span>
              <el-select v-model="style.borderWidth" @change="$_changeBorderWidth">
                <el-option v-for="item in borderWidthOptions" :key="item" :label="`${item}px`" :value="item"></el-option>
              </el-select>
            </div>
            <div class="setting-item">
              <span>文本颜色</span>
              <el-popover placement="top-start" title="填充样式" width="220" trigger="click">
                <sketch-picker :value="style.fontColor" @input="(c) => $_changeColorProperty(c, 'fontColor')" />
                <div class="border-color" :style='{ "backgroundColor": style.fontColor }' slot="reference"></div>
              </el-popover>
            </div>
            <div class="setting-item">
              <span>文本大小</span>
              <el-input-number v-model="style.fontSize" controls-position="right" size="small" @change="$_changeFontSize"
                :min="12" :max="30">
              </el-input-number>
              <span>px</span>
            </div>
            <div class="setting-item">
              <span>文本字体</span>
              <el-select v-model="style.fontFamily" size="small" @change="$_changeFontFamily">
                <el-option v-for="(fontFamily, index) in fontFamilies" :value="fontFamily.value" :key="index"></el-option>
              </el-select>
            </div>
            <div class="setting-item">
              <span>行高</span>
              <el-select v-model="style.lineHeight" size="small" @change="$_changeLineHeight">
                <el-option v-for="(item, index) in lineHeightOptions" :key="index" :label="`${item}`" :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="setting-item">
              <span>对齐</span>
              <el-radio-group v-model="style.textAlign" size="small" @change="$_changeTextAlign">
                <el-radio-button label="left">左对齐</el-radio-button>
                <el-radio-button label="center">居中</el-radio-button>
                <el-radio-button label="right">右对齐</el-radio-button>
              </el-radio-group>
            </div>
            <div class="setting-item">
              <span>文本样式</span>
              <el-button size="small" @click="$_changeFontWeight">B</el-button>
              <el-button size="small" @click="$_changeTextDecoration">U</el-button>
              <el-button size="small" @click="$_changeFontStyle">I</el-button>
            </div>
            <div class="setting-item">
              <el-button @click="$emit('setZIndex', 'top')">置为顶部</el-button>
              <el-button @click="$emit('setZIndex', 'bottom')">置为底部</el-button>
            </div>
          </div>
        </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import { Sketch } from "vue-color-next";
import "../../vue-color-next.css";
import { shortStyles, borderStyles, fontFamilies } from "../../constant";
import Recorder from "js-audio-recorder";
import ColorSelect from "./../ColorPicker.vue";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { sendUpdate, add_Operation } from "../../tools/send.js";

import {
  // 罗列出需要的功能按钮
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Image,
  Italic,
  Strike,
  // ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TaskList as TodoList,
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  Color as TextColor,
  Highlight as TextHighlight,
  FontFamily as FontType,
  FontSize,
} from "element-tiptap-vue3-fixed";
import LogicFlow from "@logicflow/core";
export default {
  components: {
    "sketch-picker": Sketch,
    ColorSelect,
  },
  props: {
    lf: LogicFlow,
    elementsStyle: { type: Object, default: undefined },
    onlyEdge: Boolean, // 是否是只设置边的属性，当只设置边的属性时，隐藏快捷样式和背景色设置
    iconList: { type: Array, default: undefined },
    imageList: { type: Array, default: undefined },
    videoList: { type: Array, default: undefined },
    audioList: { type: Array, default: undefined },
    fileList: { type: Array, default: undefined },
  },
  emits: [
    "setStyle",
    "info",
    "title",
    "iconInfo",
    "getSource",
    "changeSelect",
    "showFile",
    "setIconList",
    "changeisPromptsList",
    "setImageList",
    "setFileList",
  ],
  data() {
    return {
      ai_source: null,
      is_description: false,
      isStop: false,
      isSending: false,
      extensions: [
        Doc,
        Text,
        Paragraph,
        Heading.configure({ level: 6 }),
        Bold.configure({ bubble: true }),
        Underline,
        Image.configure({
          uploadRequest(file) {
            return new Promise((resolve, reject) => {
              var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
              if (file) {
                var params = {
                  Key: file.name,
                  ContentType: file.type,
                  Body: file,
                  "Access-Control-Allow-Credentials": "*",
                  ACL: "public-read",
                }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
                var options = {
                  partSize: 2048 * 1024 * 1024,
                  queueSize: 2,
                  leavePartsOnError: true,
                };
                bucket.upload(params, options).send(function (err, data) {
                  if (err) {
                    console.log(err);
                    reject(err);
                  } else {
                    console.log(data.Location);
                    resolve(data.Location);
                  }
                });
              }
            });
          },
        }),
        Italic,
        Strike,
        TextAlign.configure({ bubble: true }),
        // new ListItem(),
        BulletList,
        OrderedList,
        // new TodoItem(),
        TodoList,
        Indent,
        HardBreak,
        HorizontalRule,
        FontType.configure({ bubble: true }),
        FontSize.configure({ bubble: true }),
        TextColor.configure({ bubble: true }),
        TextHighlight.configure({ bubble: true }),
        History,
      ],
      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      }),
      isKeywordLoading: false,
      isQuestionLoading: false,
      isImageLoading: false,
      isAudioLoading: false,
      languageData: {},
      shortStyles,
      borderStyles,
      fontFamilies,
      //颜色初始数据
      colorList: [
        "#2995FF",
        "#F8A68D",
        "#F6A1A6",
        "#F284B2",
        "#CC69A7",
        "#EC7079",
        "#80BFB0",
        "#70C2A1",
        "#7EAB55",
        "#F5C342",
        "#DE8344",
        "#AA64D1",
        "#6E44C9",
        "#1D51B6",
        "#3681FC",
      ],
      selectSourceUrl: null,
      style: {
        isExistSelfAdaption: false,
        id: "",
        route: "",
        stageTitleColor: "#1D51B6",
        taskTitleColor: "#6E44C9",
        titleColor: "#6266BC",
        selectTitleColor: "#F284B2",
        AITitleColor: "#80BFB0",
        MarkdownTitleColor: "#AA64D1",
        iframeUrl: "",
        embedCode: "",
        type: "",
        isAlFolded: false,
        // currentLineData: {},
        rightAnswer: null,
        radio: "1",
        styleRadio: "1",
        isShowTitle: false,
        iconRadio: false,
        checked: false,
        selectTitle: "",
        selectContent: "",
        // currentInputIndex: 0,
        // currentInput: '',
        selectOption: [""],
        alSelectOption: [],
        selectAnswer: "",
        stageContent: "",
        taskTitle: "",
        taskContent: "",
        homeworkName: "",
        homeworkTitleColor: "#8BAEE3",
        homeworkContent: "",
        title: "",
        thinkingTitle: "",
        thinkingContent:
          "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
        topic: "",
        stageName: "",
        aiContent: "",
        currentKeyWord: "",
        keyWordList: [],
        questionList: [],
        imgResultList: [],
        imgResult: "",
        isIconSelect: false,
        isHidden: "2",
        tagColor: [
          "#2995FF",
          "#F8A68D",
          "#F6A1A6",
          "#F284B2",
          "#CC69A7",
          "#EC7079",
        ],
        isLoading: false,
        isShowDelete: false,
        groupTitle: "",
        describe: "",
        isShowDescribe: false,
        content: "",
        contentLength: 0,
        sourceList: [],
        iconList: [],
        imageList: [],
        videoList: [],
        audioList: [],
        fileList: [],
        sourceUrl: "",
        sourceUrlList: [],
        isGradientColor: false,
        backgroundColor: "#3681FC", // 填充色
        gradientColor: "#FFF", // 渐变色
        borderType: 0, // 线条类型
        borderColor: "", // 填充颜色
        borderWidth: 1, // 线条宽度
        borderStyle: "", // 线条类型
        fontSize: 12, // 文本大小
        fontColor: "#FFF", // 文本颜色
        fontWeight: "", // 文本加粗
        fontFamily: "", // 文本样式
        lineHeight: "", // 行高
        textAlign: "", // 对齐
        whiteboardTitle: "白板卡片",
        AINewFileList: [],
        fileType: "",
        sourceCardId: "",
        isImageToText: false,
        isImageToImage: false,
        isTextToText: true,
        isTextToImage: false,
        isTextToAudio: false,
        audioPa: "",
        answer: "",
        up: "w",
        down: "s",
        left: "a",
        right: "d",
        distance_x: 5,
        distance_y: 5,
      },
      initStyle: {
        isExistSelfAdaption: false,
        id: "",
        route: "",
        stageTitleColor: "#1D51B6",
        taskTitleColor: "#6E44C9",
        titleColor: "#6266BC",
        selectTitleColor: "#F284B2",
        AITitleColor: "#80BFB0",
        MarkdownTitleColor: "#AA64D1",
        iframeUrl: "",
        embedCode: "",
        type: "",
        isAlFolded: false,
        // currentLineData: {},
        rightAnswer: null,
        radio: "1",
        styleRadio: "1",
        isShowTitle: false,
        iconRadio: false,
        checked: false,
        selectTitle: "",
        selectContent: "",
        // currentInputIndex: 0,
        // currentInput: '',
        selectOption: [""],
        alSelectOption: [],
        selectAnswer: "",
        stageContent: "",
        taskTitle: "",
        taskContent: "",
        homeworkName: "",
        homeworkTitleColor: "#8BAEE3",
        homeworkContent: "",
        title: "",
        thinkingTitle: "",
        thinkingContent:
          "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
        topic: "",
        stageName: "",
        aiContent: "",
        currentKeyWord: "",
        keyWordList: [],
        questionList: [],
        imgResultList: [],
        imgResult: "",
        isIconSelect: false,
        isHidden: "2",
        tagColor: [
          "#2995FF",
          "#F8A68D",
          "#F6A1A6",
          "#F284B2",
          "#CC69A7",
          "#EC7079",
        ],
        isLoading: false,
        isShowDelete: false,
        groupTitle: "",
        describe: "",
        isShowDescribe: false,
        content: "",
        contentLength: 0,
        sourceList: [],
        iconList: [],
        imageList: [],
        videoList: [],
        audioList: [],
        fileList: [],
        sourceUrl: "",
        sourceUrlList: [],
        isGradientColor: false,
        backgroundColor: "#3681FC", // 填充色
        gradientColor: "#FFF", // 渐变色
        borderType: 0, // 线条类型
        borderColor: "", // 填充颜色
        borderWidth: 1, // 线条宽度
        borderStyle: "", // 线条类型
        fontSize: 12, // 文本大小
        fontColor: "#FFF", // 文本颜色
        fontWeight: "", // 文本加粗
        fontFamily: "", // 文本样式
        lineHeight: "", // 行高
        textAlign: "", // 对齐
        whiteboardTitle: "白板卡片",
        AINewFileList: [],
        fileType: "",
        sourceCardId: "",
        isImageToText: false,
        isImageToImage: false,
        isTextToText: true,
        isTextToImage: false,
        isTextToAudio: false,
        audioPa: "",
        answer: "",
        up: "w",
        down: "s",
        left: "a",
        right: "d",
        distance_x: 10,
        distance_y: 10,
      },
      borderWidthOptions: Array(11)
        .fill()
        .map((_, i) => i),
      // SketchPicker: Sketch,
      fontWeight: "", // 文本加粗
      lineHeightOptions: Array(5)
        .fill(1)
        .map((_, i) => _ + i * 0.5),
      isStartRecordAudio: false,
      isStopRecordAudio: true,
      IconProgressPercent: 0,
      isIconProgress: false,
      ImageProgressPercent: 0,
      isImageProgress: false,
      videoProgressPercent: 0,
      fileProgressPercent: 0,
      isVideoProgress: false,
      AudioProgressPercent: 0,
      isAudioProgress: false,
      isFileProgress: false,
      letterList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      Radio: "",
      allSelectOption: [],
      alSelectOption: [],
      lang: "en",
      splitDesc: "",
    };
  },
  watch: {
    elementsStyle: {
      handler(val) {
        this.style = this.initStyle;
        this.style = { ...this.style, ...val };
        // console.log(this.style)
        // if(this.style.type == "icon-card" && !this.style.titleColor) {
        //   this.style.titleColor = '#2995FF'
        // }
        // else if(this.style.type == "icon-select" && !this.style.selectOption){
        //   this.style.selectOption = [""]
        // }
        if (
          this.style.type.indexOf("bezier") != -1 ||
          this.style.type.indexOf("line") != -1 ||
          this.style.type.indexOf("polyline") != -1 ||
          this.style.type.indexOf("curved") != -1
        ) {
          const edgeModel = this.$props.lf.getEdgeModelById(this.style.id);
          const edgeData = edgeModel.getData();
          const nodeModel = this.$props.lf.getNodeModelById(
            edgeData.sourceNodeId
          );
          const nodeData = nodeModel.getData();
          this.allSelectOption = nodeData.properties.selectOption;
          this.alSelectOption = nodeData.properties.alSelectOption
            ? nodeData.properties.alSelectOption
            : [];
        }
      },
      immediate: true,
    },
    "style.fontColor"(newVal) {
      if (newVal != undefined) {
        this.$_changeColorProperty(newVal.hex, "fontColor");
      }
    },
    "style.backgroundColor"(newVal) {
      if (newVal != undefined) {
        this.$_changeColorProperty(newVal.hex, "backgroundColor");
      }
    },
    "style.gradientColor"(newVal) {
      if (newVal != undefined) {
        this.$_changeColorProperty(newVal.hex, "gradientColor");
      }
    },
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.lang = "zh";
      this.languageData = this.zhHansLanguage;
      this.splitDesc = "字数: ";
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.lang = "zh_tw";
      this.languageData = this.zhHantLanguage;
      this.splitDesc = "字數: ";
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.lang = "en";
      this.languageData = this.enLanguage;
      this.splitDesc = "Characters:";
    } else {
      this.lang = "zh";
      this.languageData = this.zhHansLanguage;
      this.splitDesc = "字数: ";
    }
    this.dragControllerDiv();
    // this.$emit("setStyle", {
    //   type: localStorage.getItem("type"),
    //   // currentLineData: JSON.parse(localStorage.getItem("currentLineData"))
    // });
    // if (JSON.parse(localStorage.getItem("source"))) {
    //   this.style.sourceList = JSON.parse(localStorage.getItem("source"));
    //   this.$emit("setStyle", {
    //     sourceList: this.style.sourceList,
    //   });
    // }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "./aws-sdk-2.235.1.min.js";
    script.async = "async";
    script.onload = function () {
      var credentials = {
        accessKeyId: "AKIATLPEDU37QV5CHLMH",
        secretAccessKey: "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",
      }; //秘钥形式的登录上传
      window.AWS.config.update(credentials);
      window.AWS.config.region = "cn-northwest-1"; //设置区域
    };
    document.body.appendChild(script);
    // document.addEventListener('paste',function(event){
    //   //console.log(event)
    //   if(event.target.nodeName=="SPAN" || event.target.nodeName=="BR" || event.target.nodeName=="P"){
    //     if (event.clipboardData || event.originalEvent) {
    //         var clipboardData = (event.clipboardData || window.clipboardData);
    //         var val = clipboardData.getData('text/plain');
    //         var str= that.replaceBr(val)
    //         event.preventDefault();
    //         switch (that.style.type) {
    //           case "icon-stage":
    //             if(that.removeHTMLTags(that.style.stageContent).substring(that.removeHTMLTags(that.style.stageContent).lenght-str.lenght,that.removeHTMLTags(that.style.stageContent).lenght)!=str){
    //               that.style.stageContent+=str
    //             }
    //             break;
    //           case "icon-task":
    //           that.style.taskContent+=str
    //             break;
    //           case "icon-homework":
    //           that.style.homeworkContent+=str
    //             break;
    //           case "icon-card":
    //             console.log("本来的:"+that.style.content)
    //             console.log("复制的内容:"+str)
    //             if(!that.style.content.includes(str)){
    //               that.style.content+=str
    //             }
    //             break;
    //           default:
    //             break;
    //         }
    //     }
    //   }
    // })
    // var system = navigator.userAgent.toLowerCase();
    // // 判断android ios windows
    // var android = system.indexOf("android");
    // var iphone = system.indexOf("iphone");
    // var ipad = system.indexOf("ipad");
    // var windows = system.indexOf("windows");
    // var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    // if (android !== -1) {
    //   // console.log("android")
    //   // this.$nextTick(() => {
    //   //   $(".diagram-panel").css({
    //   //     "width": "75%"
    //   //   })
    //   // })
    //   this.$refs.diagramPanel.style.setProperty('width', "75%")
    // }
    // if (isMac) {
    //   console.log("ios")
    //   $(".diagram-panel").css({
    //     "width": "625px"
    //   })
    //   this.$refs.diagramPanel.style.setProperty('width', "625px")
    // }
    // if (windows !== -1) {
    //   console.log("windows")
    // }
  },
  methods: {
    setIsHidden(e) {
      this.style.isHidden = e;
      this.$emit("setStyle", {
        isHidden: this.style.isHidden,
      });
    },
    start() {
      let that = this;
      that.isStop = true;
      that.style.audioPa = "";
      that.style.topic = "";
      that.$emit("setStyle", {
        audioPa: that.style.audioPa,
        topic: that.style.topic,
      });
      Recorder.getPermission().then(
        () => {
          // console.log("开始录音");
          that.recorder.start(); // 开始录音
        },
        (error) => {
          that.$message({
            message: that.languageData.tip9,
            type: "info",
          });
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    stop() {
      let that = this;
      that.isStop = false;
      that.recorder.stop();
      var wavBlob = this.recorder.getWAVBlob();
      // 创建一个formData对象
      var formData = new FormData();
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([wavBlob], { type: "audio/wav" });
      //获取当时时间戳作为文件名
      const fileOfBlob = new File([newbolb], new Date().getTime() + ".wav");
      formData.append("file", fileOfBlob);
      let num = 1;
      let a = setInterval(function () {
        let str = "";
        for (let i = 0; i < num; i++) {
          str += ".";
        }
        that.style.audioPa = "正在解析语音，请稍后" + str;
        if (num < 6) {
          num = num + 1;
        } else {
          num = 1;
        }
        that.$emit("setStyle", {
          audioPa: that.style.audioPa,
        });
      }, 500);
      that.$ajax
        .post("https://gpt4.cocorobo.cn/transcribe_file_stream", formData)
        .then((res) => {
          console.log(res);
          clearInterval(a);
          that.style.audioPa = "";
          that.style.topic = res.data.FunctionResponse;
          that.$emit("setStyle", {
            topic: res.data.FunctionResponse,
            audioPa: that.style.audioPa,
          });
        })
        .catch(function (error) {
          console.error(error);
          clearInterval(a);
          that.style.audioPa = "";
          that.$emit("setStyle", {
            audioPa: that.style.audioPa,
          });
        });
    },
    onSpeechRecognition(event) {
      this.style.topic = event.target.value;
    },
    removeHTMLTags(text) {
      if (text) {
        return text.replace(/<[^>]+>/g, "").replace("&nbsp;", "");
      }
    },
    selfAdaption(val) {
      this.style.isExistSelfAdaption = val;
      this.$emit("setStyle", {
        isExistSelfAdaption: this.style.isExistSelfAdaption,
      });
    },
    replaceBr(key) {
      key = key.replace(/<\/?.+?>/g, "<br/>");
      key = key.replace(/[\r\n]/g, "<br/>");
      return key;
    },
    dragControllerDiv() {
      let midd = document.getElementById("moveDiv");
      let right = document.getElementById("diagram-panel");
      // $('#mouseMove').click()
      // window.addEventListener('mousemove', function () {
      midd.onmousedown = function (ev) {
        let pX1 = ev.clientX;

        // 得到两个 div 的宽度
        let widthB = right.clientWidth;
        // divB.style.height = '300px'
        document.onmousemove = function (ev) {
          // 鼠标移动是坐标
          let pX2 = ev.clientX;
          // 计算出鼠标左右移动的距离
          let Y = pX2 - pX1;
          right.style.width = widthB - Y + "px";
        };
        //清除
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
      // })
    },
    icon_ai_text(value) {
      this.style.aiContent = value;
      this.$emit("setStyle", {
        aiContent: value,
      });
    },
    onBlur({ editor }) {
      // console.log(editor)
      if (this.style.iconRadio != false) {
        this.$emit("setStyle", {
          iconRadio: false,
          width: 250,
          height: 250,
          nodeSize: {
            width: 250,
            height: 250,
          },
        });
      }
      var select =
        this.style.title == "" ||
        this.style.title == this.languageData.text ||
        this.style.title == this.languageData.iframe ||
        this.style.title == this.languageData.embedCode ||
        this.style.title == this.languageData.image ||
        this.style.title == this.languageData.video ||
        this.style.title == this.languageData.voice ||
        this.style.title == this.languageData.card;
      if (
        parseInt(
          $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
        ) != 0
      ) {
        this.$emit("setStyle", {
          content: editor.view.dom.innerHTML,
          contentLength: parseInt(
            $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
          ),
          // contentLength: parseInt(editor.view.dom.innerText.length),
          radio: "content",
          title: select ? this.languageData.text : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#DE8344"
            : this.style.titleColor,
        });
      }
    },
    onUpdate(e) {
      this.$nextTick(() => {
        if (this.style.iconRadio != false) {
          this.$emit("setStyle", {
            iconRadio: false,
            width: 250,
            height: 250,
            nodeSize: {
              width: 250,
              height: 250,
            },
          });
        }
        var select =
          this.style.title == "" ||
          this.style.title == this.languageData.text ||
          this.style.title == this.languageData.iframe ||
          this.style.title == this.languageData.embedCode ||
          this.style.title == this.languageData.image ||
          this.style.title == this.languageData.video ||
          this.style.title == this.languageData.voice ||
          this.style.title == this.languageData.card;
        if (
          parseInt(
            $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
          ) != 0
        ) {
          this.$emit("setStyle", {
            content: e,
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            // contentLength: parseInt(editor.view.dom.innerText.length),
            radio: "content",
            title: select ? this.languageData.text : this.style.title,
            titleColor: this.style.isExistSelfAdaption
              ? "#DE8344"
              : this.style.titleColor,
          });
        } else {
          if (this.style.iconRadio != false) {
            this.$emit("setStyle", {
              iconRadio: false,
              width: 250,
              height: 250,
              nodeSize: {
                width: 250,
                height: 250,
              },
            });
          }
          this.$emit("setStyle", {
            content: e,
            contentLength: 0,
            // contentLength: parseInt(editor.view.dom.innerText.length),
            radio: "content",
            title: select ? this.languageData.text : this.style.title,
            titleColor: this.style.isExistSelfAdaption
              ? "#DE8344"
              : this.style.titleColor,
          });
        }
      });
    },
    onUpdateStageContent(e) {
      this.$nextTick(() => {
        if (e != "<p></p>") {
          this.$emit("setStyle", {
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            stageContent: e,
            height: 154,
          });
        } else {
          this.$emit("setStyle", {
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            stageContent: e,
            height: 75,
          });
        }
      });
    },
    onUpdateAIContent(e) {
      this.$nextTick(() => {
        this.$emit("setStyle", {
          topic: this.languageData.markdownCard,
          contentLength: parseInt(
            $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
          ),
          aiContent: e,
          radio: 'description'
        });
      });
    },
    onUpdateTaskContent(e) {
      this.$nextTick(() => {
        if (e != "<p></p>") {
          this.$emit("setStyle", {
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            taskContent: e,
            height: 154,
          });
        } else {
          this.$emit("setStyle", {
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            taskContent: e,
            height: 75,
          });
        }
      });
    },
    onUpdateHomeworkContent(e) {
      this.$nextTick(() => {
        if (e != "<p></p>") {
          this.$emit("setStyle", {
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            homeworkContent: e,
            height: 154,
          });
        } else {
          this.$emit("setStyle", {
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            homeworkContent: e,
            height: 75,
          });
        }
      });
    },
    onBlurStageContent({ editor }) {
      this.$nextTick(() => {
        if (editor.view.dom.innerHTML != "<p></p>") {
          this.$emit("setStyle", {
            stageContent: editor.view.dom.innerHTML,
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            height: 154,
          });
        } else {
          this.$emit("setStyle", {
            stageContent: editor.view.dom.innerHTML,
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            height: 75,
          });
        }
      });
    },
    onBlurAIContent({ editor }) {
      this.$nextTick(() => {
        this.$emit("setStyle", {
          topic: this.languageData.markdownCard,
          aiContent: editor.view.dom.innerHTML,
          contentLength: parseInt(
            $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
          ),
          radio: 'description'
        });
      });
    },
    onBlurTaskContent({ editor }) {
      this.$nextTick(() => {
        if (editor.view.dom.innerHTML != "<p></p>") {
          this.$emit("setStyle", {
            taskContent: editor.view.dom.innerHTML,
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            height: 154,
          });
        } else {
          this.$emit("setStyle", {
            taskContent: editor.view.dom.innerHTML,
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            height: 75,
          });
        }
      });
    },
    // onPasteTaskContent({ editor }) {
    //   // console.log(editor.view.dom.innerHTML)
    //   this.$nextTick(() => {
    //     // if(editor.view.dom.innerHTML == "<p></p>") {
    //     //   // 获取粘贴板的内容
    //     //   let pasteValue = (event.clipboardData || window.clipboardData).getData('text/plain');
    //     //   console.log(pasteValue)
    //     //   editor.view.dom.innerHTML = '<p><span style="color: rgb(79, 79, 79);"><span style="font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif"><span style="font-size: 16px">' + pasteValue + '</span></span></span><br><br class="ProseMirror-trailingBreak"></p>'
    //     // }
    //     let pasteValue = (event.clipboardData || window.clipboardData).getData('text');
    //     console.log(pasteValue)
    //     try {

    //     }
    //     catch {
    //       let pasteValue = (event.clipboardData || window.clipboardData).getData('text/plain');
    //       editor.view.dom.innerHTML = '<p><span style="color: rgb(79, 79, 79);"><span style="font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif"><span style="font-size: 16px">' + pasteValue + '</span></span></span><br><br class="ProseMirror-trailingBreak"></p>'
    //     }
    //   })
    // },
    onBlurHomeworkContent({ editor }) {
      this.$nextTick(() => {
        if (editor.view.dom.innerHTML != "<p></p>") {
          this.$emit("setStyle", {
            homeworkContent: editor.view.dom.innerHTML,
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            height: 154,
          });
        } else {
          this.$emit("setStyle", {
            homeworkContent: editor.view.dom.innerHTML,
            contentLength: parseInt(
              $(".el-tiptap-editor__characters").text().split(this.splitDesc)[1]
            ),
            height: 75,
          });
        }
      });
    },
    addImage() {
      this.$emit("info", true);
      this.$emit("title", this.style.title);
    },
    addIcon() {
      this.$emit("iconInfo", true);
      this.$emit("title", this.style.title);
    },
    update(value) {
      this.$emit("setStyle", {
        titleColor: value,
      });
    },
    updateSelect(value) {
      this.$emit("setStyle", {
        selectTitleColor: value,
      });
    },
    updateAI(value) {
      this.$emit("setStyle", {
        AITitleColor: value,
      });
    },
    updateMarkdown(value) {
      this.$emit("setStyle", {
        topic: this.languageData.markdownCard,
        MarkdownTitleColor: value,
      });
    },
    updateStage(value) {
      this.$emit("setStyle", {
        stageTitleColor: value,
      });
    },
    updateTask(value) {
      this.$emit("setStyle", {
        taskTitleColor: value,
      });
    },
    updateHomework(value) {
      this.$emit("setStyle", {
        homeworkTitleColor: value,
      });
    },
    selectSource(e) {
      this.selectSourceUrl = e.target.dataset.url;
    },
    confirmSource() {
      // this.style.sourceUrl = this.selectSourceUrl;
      // this.$emit('setStyle', {
      //   sourceUrl: this.selectSourceUrl
      // })
      this.$emit("getSource", true);
    },
    changeRadio(e) {
      // console.log(e.target.value)
      this.$emit("setStyle", {
        rightAnswer: this.style.selectOption[parseInt(e.target.value)],
      });
    },
    addOption() {
      this.style.selectOption.push("");
    },
    deleteOption() {
      this.style.selectOption.splice(this.style.selectOption.length - 1, 1);
      // console.log(this.style.selectOption)
      this.$emit("setStyle", {
        selectOption: this.style.selectOption,
      });
    },
    setChecked(e) {
      console.log(e);
      this.$emit("setStyle", {
        checked: e,
      });
    },
    setRoute(e) {
      const edgeModel = this.$props.lf.getEdgeModelById(this.style.id);
      const edgeData = edgeModel.getData();
      const nodeModel = this.$props.lf.getNodeModelById(edgeData.sourceNodeId);
      const nodeData = nodeModel.getData();
      const alSelectOption =
        nodeData.properties.alSelectOption &&
        nodeData.properties.alSelectOption.length != 0
          ? nodeData.properties.alSelectOption
          : [];
      for (let i = 0; i < alSelectOption.length; i++) {
        if (alSelectOption[i] == edgeData.properties.route) {
          alSelectOption.splice(i, 1);
        }
      }
      if (this.style.type == "pro-red-bezier") {
        this.$props.lf.graphModel.changeEdgeType(this.style.id, "pro-bezier");
      } else if (this.style.type == "pro-red-curved") {
        this.$props.lf.graphModel.changeEdgeType(this.style.id, "pro-curved");
      } else if (this.style.type == "pro-red-line") {
        this.$props.lf.graphModel.changeEdgeType(this.style.id, "pro-line");
      } else if (this.style.type == "pro-red-polyline") {
        this.$props.lf.graphModel.changeEdgeType(this.style.id, "pro-polyline");
      }
      // this.$emit('setStyle', {
      //   route: e
      // })
      this.$props.lf.getEdgeModelById(this.style.id).setProperties({
        route: e,
      });
      localStorage.setItem("route", e);
      this.$props.lf.getNodeModelById(edgeData.targetNodeId).setProperties({
        title: e,
      });
      alSelectOption.push(e);
      this.$props.lf.getNodeModelById(edgeData.sourceNodeId).setProperties({
        alSelectOption: alSelectOption,
      });
      const edgeModel_new = this.$props.lf.getEdgeModelById(this.style.id);
      const edgeData_new = edgeModel_new.getData();
      const sourceNodeModel_new = this.$props.lf.getNodeModelById(
        edgeData.sourceNodeId
      );
      const sourceNodeData_new = sourceNodeModel_new.getData();
      const targetNodeModel_new = this.$props.lf.getNodeModelById(
        edgeData.targetNodeId
      );
      const targetNodeData_new = targetNodeModel_new.getData();
      let newNodes = [];
      let newEdges = [];
      newNodes.push(sourceNodeData_new);
      newNodes.push(targetNodeData_new);
      newEdges.push(edgeData_new);
      sendUpdate(newNodes, newEdges, "update", 0);
      let newData = {
        nodes: newNodes,
        edges: newEdges,
        backgroundUrl: localStorage.getItem("background"),
      };
      add_Operation(newData, "updateNode");
    },
    setSelect(e) {
      // console.log(e)
      if (this.style.iconRadio != false) {
        this.$emit("setStyle", {
          iconRadio: false,
          width: 250,
          height: 250,
          nodeSize: {
            width: 250,
            height: 250,
          },
        });
      }
      var select =
        this.style.title == "" ||
        this.style.title == this.languageData.text ||
        this.style.title == this.languageData.iframe ||
        this.style.title == this.languageData.embedCode ||
        this.style.title == this.languageData.image ||
        this.style.title == this.languageData.video ||
        this.style.title == this.languageData.voice ||
        this.style.title == this.languageData.file ||
        this.style.title == this.languageData.card;
      if (e == "content") {
        this.$emit("setStyle", {
          radio: e,
          title: select ? this.languageData.text : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#DE8344"
            : this.style.titleColor,
        });
      } else if (e == "iframeUrl") {
        this.$emit("setStyle", {
          radio: e,
          title: select ? this.languageData.iframe : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#F5C342"
            : this.style.titleColor,
        });
      } else if (e == "embedCode") {
        this.$emit("setStyle", {
          radio: e,
          title: select ? this.languageData.embedCode : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#F5C342"
            : this.style.titleColor,
        });
      } else if (e == "imageList") {
        this.$emit("setStyle", {
          radio: e,
          title: select ? this.languageData.image : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#AA64D1"
            : this.style.titleColor,
        });
      } else if (e == "videoList") {
        this.$emit("setStyle", {
          radio: e,
          title: select ? this.languageData.video : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#7EAB55"
            : this.style.titleColor,
        });
      } else if (e == "audioList") {
        this.$emit("setStyle", {
          radio: e,
          title: select ? this.languageData.voice : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#70C2A1"
            : this.style.titleColor,
        });
      } else if (e == "fileList") {
        this.$emit("setStyle", {
          radio: e,
          title: select ? this.languageData.file : this.style.title,
          titleColor: this.style.isExistSelfAdaption
            ? "#EC7079"
            : this.style.titleColor,
          // isShowTitle: false
        });
      } else if (e == "image") {
        this.$emit("setStyle", {
          radio: e,
        });
      } else if (e == "description") {
        this.$emit("setStyle", {
          radio: e,
        });
      } else if (e == "audio") {
        this.$emit("setStyle", {
          radio: e,
        });
      }
    },
    setIconSelect(e) {
      if (e == true) {
        this.$emit("setStyle", {
          iconRadio: e,
          nodeSize: {
            width: 80,
            height: 80,
          },
        });
      } else {
        this.$emit("setStyle", {
          iconRadio: e,
          nodeSize: {
            width: 250,
            height: 250,
          },
        });
      }
    },
    setGradientColorSelect(e) {
      this.style.isGradientColor = e;
      this.$emit("setStyle", {
        isGradientColor: this.style.isGradientColor,
      });
    },
    setIsShowTitleSelect(e) {
      this.style.isShowTitle = e;
      this.$emit("setStyle", {
        isShowTitle: this.style.isShowTitle,
      });
    },
    setStyleSelect(e) {
      this.$emit("setStyle", {
        styleRadio: e,
      });
    },
    // setInput(e) {
    //   this.style.currentInputIndex = e.target.dataset.index;
    // },
    //开始录音
    startRecordAudio() {
      Recorder.getPermission().then(
        () => {
          // console.log("开始录音");
          this.recorder.start(); // 开始录音
          this.isStartRecordAudio = true;
          this.isStopRecordAudio = false;
        },
        (error) => {
          this.$message({
            message: this.languageData.tip9,
            type: "info",
          });
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    //停止录音
    stopRecordAudio() {
      // console.log("停止录音");
      this.recorder.stop();
      this.uploadAudio();
      this.isStartRecordAudio = false;
      this.isStopRecordAudio = true;
    },
    uploadIcon(fileParams) {
      // console.log(fileParams.file)
      this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (fileParams.file) {
        var params = {
          Key: fileParams.file.name,
          ContentType: fileParams.file.type,
          Body: fileParams.file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            _this.isIconProgress = true;
            _this.IconProgressPercent = Number(
              ((e.loaded / e.total) * 100).toFixed(2)
            );
            if (_this.IconProgressPercent == 100) {
              _this.isIconProgress = false;
            }
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              // console.log(data);
              const iconList = [{ name: data.Key, url: data.Location }];
              _this.$emit("setIconList", iconList);
              _this.$emit("setStyle", {
                iconList: [{ name: data.Key, url: data.Location }],
                iconRadio: true,
                nodeSize: {
                  width: 80,
                  height: 80,
                },
              });
              _this.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            }
          });
      }
    },
    uploadSource(fileParams) {
      // console.log(fileParams.file)
      this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (fileParams.file) {
        var params = {
          Key: fileParams.file.name,
          ContentType: fileParams.file.type,
          Body: fileParams.file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function () {})
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              // console.log(data);
              _this.style.sourceList.push({
                name: data.Key,
                url: data.Location,
              });
              _this.$emit("setStyle", {
                sourceList: _this.style.sourceList,
              });
              localStorage.setItem(
                "source",
                JSON.stringify(_this.style.sourceList)
              );
              _this.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            }
          });
      }
    },
    uploadImage(fileParams) {
      // console.log(fileParams.file)
      this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (fileParams.file) {
        var params = {
          Key: fileParams.file.name,
          ContentType: fileParams.file.type,
          Body: fileParams.file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            _this.isImageProgress = true;
            _this.ImageProgressPercent = Number(
              ((e.loaded / e.total) * 100).toFixed(2)
            );
            if (_this.ImageProgressPercent == 100) {
              _this.isImageProgress = false;
            }
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              console.log(data);
              const imageList = [{ name: data.Key, url: data.Location }];
              _this.$emit("setImageList", imageList);
              var select =
                _this.style.title == "" ||
                _this.style.title == _this.languageData.text ||
                _this.style.title == _this.languageData.iframe ||
                _this.style.title == _this.languageData.embedCode ||
                _this.style.title == _this.languageData.image ||
                _this.style.title == _this.languageData.video ||
                _this.style.title == _this.languageData.voice ||
                _this.style.title == _this.languageData.card;
              if (_this.style.iconRadio != false) {
                _this.$emit("setStyle", {
                  iconRadio: false,
                  width: 250,
                  height: 250,
                  nodeSize: {
                    width: 250,
                    height: 250,
                  },
                });
              }
              _this.$emit("setStyle", {
                imageList: [{ name: data.Key, url: data.Location }],
                radio: "imageList",
                title: select ? _this.languageData.image : _this.style.title,
                titleColor: _this.style.isExistSelfAdaption
                  ? "#AA64D1"
                  : _this.style.titleColor,
                // isShowTitle: data.Location.indexOf(".png") != -1 ? true : false
              });
              _this.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            }
          });
      }
    },
    uploadVideo(fileParams) {
      // console.log(fileParams.file)
      this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (fileParams.file) {
        var params = {
          Key: fileParams.file.name,
          ContentType: fileParams.file.type,
          Body: fileParams.file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            // console.log(e)
            _this.isVideoProgress = true;
            _this.videoProgressPercent = Number(
              ((e.loaded / e.total) * 100).toFixed(2)
            );
            if (_this.videoProgressPercent == 100) {
              _this.isVideoProgress = false;
            }
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              // console.log(data);
              const videoList = [{ name: data.Key, url: data.Location }];
              _this.$emit("setVideoList", videoList);
              var select =
                _this.style.title == "" ||
                _this.style.title == _this.languageData.text ||
                _this.style.title == _this.languageData.iframe ||
                _this.style.title == _this.languageData.embedCode ||
                _this.style.title == _this.languageData.image ||
                _this.style.title == _this.languageData.video ||
                _this.style.title == _this.languageData.voice ||
                _this.style.title == _this.languageData.card;
              if (_this.style.iconRadio != false) {
                _this.$emit("setStyle", {
                  iconRadio: false,
                  width: 250,
                  height: 250,
                  nodeSize: {
                    width: 250,
                    height: 250,
                  },
                });
              }
              _this.$emit("setStyle", {
                videoList: [{ name: data.Key, url: data.Location }],
                radio: "videoList",
                styleRadio: "1",
                title: select ? _this.languageData.video : _this.style.title,
                titleColor: _this.style.isExistSelfAdaption
                  ? "#7EAB55"
                  : _this.style.titleColor,
              });
              _this.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            }
          });
      }
    },
    uploadAudio() {
      this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      var wavBlob = this.recorder.getWAVBlob();
      // 创建一个formData对象
      var formData = new FormData();
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([wavBlob], { type: "audio/wav" });
      //获取当时时间戳作为文件名
      const fileOfBlob = new File([newbolb], new Date().getTime() + ".wav");
      formData.append("file", fileOfBlob);
      // console.log(fileParams.file)
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (fileOfBlob) {
        var params = {
          Key: fileOfBlob.name,
          ContentType: fileOfBlob.type,
          Body: fileOfBlob,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            _this.isAudioProgress = true;
            _this.AudioProgressPercent = Number(
              ((e.loaded / e.total) * 100).toFixed(2)
            );
            if (_this.AudioProgressPercent == 100) {
              _this.isAudioProgress = false;
            }
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              // console.log(data);
              const audioList = [{ name: data.Key, url: data.Location }];
              _this.$emit("setAudioList", audioList);
              var select =
                _this.style.title == "" ||
                _this.style.title == _this.languageData.text ||
                _this.style.title == _this.languageData.iframe ||
                _this.style.title == _this.languageData.embedCode ||
                _this.style.title == _this.languageData.image ||
                _this.style.title == _this.languageData.video ||
                _this.style.title == _this.languageData.voice ||
                _this.style.title == _this.languageData.card;
              if (_this.style.iconRadio != false) {
                _this.$emit("setStyle", {
                  iconRadio: false,
                  width: 250,
                  height: 250,
                  nodeSize: {
                    width: 250,
                    height: 250,
                  },
                });
              }
              _this.$emit("setStyle", {
                audioList: [{ name: data.Key, url: data.Location }],
                radio: "audioList",
                title: select ? _this.languageData.voice : _this.style.title,
                titleColor: _this.style.isExistSelfAdaption
                  ? "#70C2A1"
                  : _this.style.titleColor,
              });
              _this.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            }
          });
      }
    },
    uploadFile(fileParams) {
      this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (fileParams.file) {
        var params = {
          Key: fileParams.file.name,
          ContentType: fileParams.file.type,
          Body: fileParams.file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            // console.log(e)
            _this.isFileProgress = true;
            _this.fileProgressPercent = Number(
              ((e.loaded / e.total) * 100).toFixed(2)
            );
            if (_this.fileProgressPercent == 100) {
              _this.isFileProgress = false;
            }
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              // console.log(data);
              const fileList = [{ name: data.Key, url: data.Location }];
              // _this.send_pdf(data.Location)
              _this.$emit("setFileList", fileList);
              var select =
                _this.style.title == "" ||
                _this.style.title == _this.languageData.text ||
                _this.style.title == _this.languageData.iframe ||
                _this.style.title == _this.languageData.embedCode ||
                _this.style.title == _this.languageData.image ||
                _this.style.title == _this.languageData.video ||
                _this.style.title == _this.languageData.voice ||
                _this.style.title == _this.languageData.file ||
                _this.style.title == _this.languageData.card;
              if (_this.style.iconRadio != false) {
                _this.$emit("setStyle", {
                  iconRadio: false,
                  width: 250,
                  height: 250,
                  nodeSize: {
                    width: 250,
                    height: 250,
                  },
                });
              }
              _this.$emit("setStyle", {
                fileList: [{ name: data.Key, url: data.Location }],
                radio: "fileList",
                styleRadio: "1",
                title: select ? _this.languageData.file : _this.style.title,
                titleColor: _this.style.isExistSelfAdaption
                  ? "#EC7079"
                  : _this.style.titleColor,
                // isShowTitle: false
              });
              _this.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            }
          });
      }
    },
    selectFileNew() {
      $("#new-AI").click();
    },
    uploadFileNew(e) {
      const { nodes } = this.$props.lf.getGraphData();
      let ids = [];
      let isAdd = false;
      console.log(this.style.sourceCardId);
      for (let i = 0; i < nodes.length; i++) {
        ids.push(nodes[i].id);
      }
      if (ids.indexOf(this.style.sourceCardId) == -1) {
        isAdd = true;
      }
      const file = e.target.files[0];
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (
        file &&
        !(file.type.indexOf("image/") != -1 && file.size > 4 * 1024 * 1024)
      ) {
        _this.$props.lf.updateEditConfig({
          isSilentMode: true,
          stopZoomGraph: true,
          stopScrollGraph: true,
          stopMoveGraph: true,
        });
        var params = {
          Key: file.name,
          ContentType: file.type,
          Body: file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            // console.log(e)
            _this.isFileProgress = true;
            _this.fileProgressPercent = Number(
              ((e.loaded / e.total) * 100).toFixed(2)
            );
            if (_this.fileProgressPercent == 100) {
              _this.isFileProgress = false;
            }
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              // console.log(data);
              const fileList = [{ name: data.Key, url: data.Location }];
              _this.style.isTextToText = false;
              _this.style.isTextToImage = false;
              _this.style.isTextToAudio = false;
              _this.style.isImageToImage = false;
              _this.style.isImageToText = false;
              _this.style.topic = "";
              _this.style.audioPa = "";
              _this.$emit("setStyle", {
                topic: _this.style.topic,
                audioPa: _this.style.audioPa,
                AINewFileList: fileList,
                fileType: file.type,
                isTextToText: _this.style.isTextToText,
                isTextToImage: _this.style.isTextToImage,
                isTextToAudio: _this.style.isTextToAudio,
                isImageToImage: _this.style.isImageToImage,
                isImageToText: _this.style.isImageToText,
              });
              let id = _this.style.sourceCardId;
              if (isAdd) {
                let oldData = _this.$props.lf.graphModel
                  .getNodeModelById(_this.style.id)
                  .getData();
                let node = {};
                if (file.type.indexOf("image/") != -1) {
                  node = {
                    id: id,
                    type: "icon-card",
                    properties: {
                      type: "icon-card",
                      radio: "imageList",
                      imageList: fileList,
                      title: _this.languageData.image,
                    },
                    x: oldData.x - 300,
                    y: oldData.y,
                    zIndex: 1002,
                  };
                  _this.$props.lf.graphModel.addNode(node);
                  _this.style.isImageToImage = false;
                  _this.style.isImageToText = true;
                  _this.$emit("setStyle", {
                    isImageToImage: _this.style.isImageToImage,
                    isImageToText: _this.style.isImageToText,
                  });
                } else if (file.type.indexOf("audio/") != -1) {
                  node = {
                    id: id,
                    type: "icon-card",
                    properties: {
                      type: "icon-card",
                      radio: "audioList",
                      audioList: fileList,
                      title: _this.languageData.voice,
                    },
                    x: oldData.x - 300,
                    y: oldData.y,
                    zIndex: 1002,
                  };
                  _this.$props.lf.graphModel.addNode(node);
                  let num = 1;
                  let a = setInterval(function () {
                    let str = "";
                    for (let i = 0; i < num; i++) {
                      str += ".";
                    }
                    _this.style.audioPa = _this.languageData.tip85 + str;
                    if (num < 6) {
                      num = num + 1;
                    } else {
                      num = 1;
                    }
                    _this.$emit("setStyle", {
                      audioPa: _this.style.audioPa,
                    });
                  }, 500);
                  _this.$ajax
                    .post("https://gpt4.cocorobo.cn/transcribe_new", {
                      fileName: fileList[0].name,
                      url: fileList[0].url,
                    })
                    .then(function (res) {
                      clearInterval(a);
                      _this.style.audioPa = "";
                      _this.style.topic = res.data.FunctionResponse;
                      _this.style.isTextToText = true;
                      _this.style.isTextToImage = false;
                      _this.style.isTextToAudio = false;
                      _this.$emit("setStyle", {
                        topic: _this.style.topic,
                        isTextToText: _this.style.isTextToText,
                        isTextToImage: _this.style.isTextToImage,
                        isTextToAudio: _this.style.isTextToAudio,
                        audioPa: _this.style.audioPa,
                      });
                      _this.$props.lf.updateEditConfig({
                        isSilentMode: false,
                        stopZoomGraph: false,
                        stopScrollGraph: false,
                        stopMoveGraph: false,
                      });
                    })
                    .catch(function (error) {
                      console.log(error);
                      _this.style.aiContent = "";
                      _this.style.isLoading = false;
                      clearInterval(a);
                      _this.style.audioPa = "";
                      _this.$emit("setStyle", {
                        isLoading: _this.style.isLoading,
                        aiContent: _this.style.aiContent,
                        audioPa: _this.style.audioPa,
                      });
                      _this.$props.lf.updateEditConfig({
                        isSilentMode: false,
                        stopZoomGraph: false,
                        stopScrollGraph: false,
                        stopMoveGraph: false,
                      });
                    });
                } else {
                  node = {
                    id: id,
                    type: "icon-card",
                    properties: {
                      type: "icon-card",
                      radio: "fileList",
                      fileList: fileList,
                      title: _this.languageData.file,
                    },
                    x: oldData.x - 300,
                    y: oldData.y,
                    zIndex: 1002,
                  };
                  _this.$props.lf.graphModel.addNode(node);
                }
                // const { transformModel } = _this.$props.lf.graphModel;
                // transformModel.focusOn(node.x, node.y, window.screen.width, window.screen.height);
                let x1 =
                  _this.$props.lf.graphModel.getNodeModelById(_this.style.id)
                    .x -
                  _this.$props.lf.graphModel.getNodeModelById(_this.style.id)
                    ._width /
                    2;
                let x2 =
                  _this.$props.lf.graphModel.getNodeModelById(id).x +
                  _this.$props.lf.graphModel.getNodeModelById(id)._width / 2;
                let y1 = _this.$props.lf.graphModel.getNodeModelById(
                  _this.style.id
                ).y;
                let y2 = _this.$props.lf.graphModel.getNodeModelById(id).y;
                let edgeId = uuidv4();
                let edge = {
                  id: edgeId,
                  properties: {},
                  zIndex: 1,
                  type: "pro-line",
                  sourceNodeId: id,
                  targetNodeId: _this.style.id,
                  startPoint: {
                    x: x2,
                    y: y2,
                  },
                  endPoint: {
                    x: x1,
                    y: y1,
                  },
                };
                _this.$props.lf.graphModel.addEdge(edge);
                let newNodes = [];
                let newEdges = [];
                newNodes.push(
                  _this.$props.lf.graphModel.getNodeModelById(id).getData()
                );
                newEdges.push(
                  _this.$props.lf.graphModel.getEdgeModelById(edgeId).getData()
                );
                sendUpdate(newNodes, newEdges, "update", 0);
                let newData = {
                  nodes: newNodes,
                  edges: newEdges,
                  backgroundUrl: localStorage.getItem("background"),
                };
                add_Operation(newData, "addNode");
                if (file.type.indexOf("audio/") == -1) {
                  _this.$props.lf.updateEditConfig({
                    isSilentMode: false,
                    stopZoomGraph: false,
                    stopScrollGraph: false,
                    stopMoveGraph: false,
                  });
                }
              } else {
                let properties = _this.lf.graphModel
                  .getNodeModelById(id)
                  .getData().properties;
                if (file.type.indexOf("image/") != -1) {
                  properties = {
                    type: "icon-card",
                    radio: "imageList",
                    imageList: fileList,
                    title: _this.languageData.image,
                  };
                  _this.lf.graphModel
                    .getNodeModelById(id)
                    .setProperties(properties);
                  let newNodes = [];
                  let newEdges = [];
                  newNodes.push(
                    _this.$props.lf.graphModel.getNodeModelById(id).getData()
                  );
                  sendUpdate(newNodes, newEdges, "update", 0);
                  let newData = {
                    nodes: newNodes,
                    edges: newEdges,
                    backgroundUrl: localStorage.getItem("background"),
                  };
                  add_Operation(newData, "updateNode");
                  _this.style.isImageToImage = false;
                  _this.style.isImageToText = true;
                  _this.$emit("setStyle", {
                    isImageToImage: _this.style.isImageToImage,
                    isImageToText: _this.style.isImageToText,
                  });
                } else if (file.type.indexOf("audio/") != -1) {
                  properties = {
                    type: "icon-card",
                    radio: "audioList",
                    audioList: fileList,
                    title: _this.languageData.voice,
                  };
                  _this.lf.graphModel
                    .getNodeModelById(id)
                    .setProperties(properties);
                  let newNodes = [];
                  let newEdges = [];
                  newNodes.push(
                    _this.$props.lf.graphModel.getNodeModelById(id).getData()
                  );
                  sendUpdate(newNodes, newEdges, "update", 0);
                  let newData = {
                    nodes: newNodes,
                    edges: newEdges,
                    backgroundUrl: localStorage.getItem("background"),
                  };
                  add_Operation(newData, "updateNode");
                  let num = 1;
                  let a = setInterval(function () {
                    let str = "";
                    for (let i = 0; i < num; i++) {
                      str += ".";
                    }
                    _this.style.audioPa = _this.languageData.tip85 + str;
                    if (num < 6) {
                      num = num + 1;
                    } else {
                      num = 1;
                    }
                    _this.$emit("setStyle", {
                      audioPa: _this.style.audioPa,
                    });
                  }, 500);
                  _this.$ajax
                    .post("https://gpt4.cocorobo.cn/transcribe_new", {
                      fileName: fileList[0].name,
                      url: fileList[0].url,
                    })
                    .then(function (res) {
                      clearInterval(a);
                      _this.style.audioPa = "";
                      _this.style.topic = res.data.FunctionResponse;
                      _this.style.isTextToText = true;
                      _this.style.isTextToImage = false;
                      _this.style.isTextToAudio = false;
                      _this.$emit("setStyle", {
                        topic: _this.style.topic,
                        isTextToText: _this.style.isTextToText,
                        isTextToImage: _this.style.isTextToImage,
                        isTextToAudio: _this.style.isTextToAudio,
                        audioPa: _this.style.audioPa,
                      });
                      _this.$props.lf.updateEditConfig({
                        isSilentMode: false,
                        stopZoomGraph: false,
                        stopScrollGraph: false,
                        stopMoveGraph: false,
                      });
                    })
                    .catch(function (error) {
                      console.log(error);
                      _this.style.aiContent = "";
                      _this.style.isLoading = false;
                      clearInterval(a);
                      _this.style.audioPa = "";
                      _this.$emit("setStyle", {
                        isLoading: _this.style.isLoading,
                        aiContent: _this.style.aiContent,
                        audioPa: _this.style.audioPa,
                      });
                      _this.$props.lf.updateEditConfig({
                        isSilentMode: false,
                        stopZoomGraph: false,
                        stopScrollGraph: false,
                        stopMoveGraph: false,
                      });
                    });
                } else {
                  properties = {
                    type: "icon-card",
                    radio: "fileList",
                    fileList: fileList,
                    title: _this.languageData.file,
                  };
                  _this.lf.graphModel
                    .getNodeModelById(id)
                    .setProperties(properties);
                  let newNodes = [];
                  let newEdges = [];
                  newNodes.push(
                    _this.$props.lf.graphModel.getNodeModelById(id).getData()
                  );
                  sendUpdate(newNodes, newEdges, "update", 0);
                  let newData = {
                    nodes: newNodes,
                    edges: newEdges,
                    backgroundUrl: localStorage.getItem("background"),
                  };
                  add_Operation(newData, "updateNode");
                }
                if (file.type.indexOf("audio/") == -1) {
                  _this.$props.lf.updateEditConfig({
                    isSilentMode: false,
                    stopZoomGraph: false,
                    stopScrollGraph: false,
                    stopMoveGraph: false,
                  });
                }
              }
              //_this.send_pdf(data.Location)
              // console.log(fileList,file.type)
            }
          });
      } else if (
        file.type.indexOf("image/") != -1 &&
        file.size > 4 * 1024 * 1024
      ) {
        _this.$message({
          message: _this.languageData.tip86,
          type: "danger",
        });
      }
    },
    askNewOwn(uid) {
      let that = this;
      // 创建EventSource
      let ai_source = new EventSource("https://gpt4.cocorobo.cn/stream/" + uid);
      // 存储获取的流数据（追加）
      let alltext = "";
      // 存储打字效果数据
      let newalltext = "";
      // 初始化md
      //const md = new MarkdownIt();
      // 监听流数据的返回
      ai_source.onmessage = function (e) {
        let eData = e.data;
        if (eData.replace("'", "").replace("'", "") == "[DONE]") {
          // that.isHiddenRightSide_flag=true;
          newalltext = newalltext.replace("_", "");
          //需要replace替换两次
          newalltext = newalltext.replace("_", "");
          // 在显示数组中存储当前数据
          that.is_description = true;
          that.style.aiContent = newalltext;
          that.style.isLoading = false;
          that.$emit("setStyle", {
            aiContent: that.style.aiContent,
            isLoading: that.style.isLoading,
            radio: "description",
            answer: that.style.answer,
          });
          that.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
          // that.switchPage()
          //重置数据
          ai_source.close();
          // alltext = "";
          // newalltext = "";
          return;
        } else {
          let content = eData.replace("'", "").replace("'", "");
          // if (eData.type == "a") {
          //   content = eData.content.replace("'", "").replace("'", "");
          // }
          if (alltext == "") {
            alltext = content.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
          } else {
            alltext += content;
          }
          // 处理流数据
          newalltext = alltext + "_";
          newalltext = newalltext.replace(/\\n/g, "\n"); // \\n
          newalltext = newalltext.replace(/\\/g, "");
          if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
          // 转化返回的回复流数据
          //newalltext = md.render(newalltext);
          // that.is_description = true;
          that.style.aiContent = newalltext;
          that.style.radio = "description";
          clearInterval(that.a);
          that.style.answer = "";
        }
      };
    },
    askOwnGpt4(uid) {
      let that = this;
      // 创建EventSource
      let ai_source = new EventSource(
        "https://gpt4.cocorobo.cn/ask_own/" + uid
      );
      // 存储获取的流数据（追加）
      let alltext = "";
      // 存储打字效果数据
      let newalltext = "";
      // 初始化md
      //const md = new MarkdownIt();
      // 监听流数据的返回
      ai_source.onmessage = function (e) {
        let eData = JSON.parse(e.data);
        if (eData.content.replace("'", "").replace("'", "") == "[DONE]") {
          // that.isHiddenRightSide_flag=true;
          newalltext = newalltext.replace("_", "");
          //需要replace替换两次
          newalltext = newalltext.replace("_", "");
          // 在显示数组中存储当前数据
          that.is_description = true;
          that.style.aiContent = newalltext;
          that.style.isLoading = false;
          that.$emit("setStyle", {
            aiContent: that.style.aiContent,
            isLoading: that.style.isLoading,
            radio: "description",
          });
          that.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
          // that.getMoreKeyword()
          // that.getMoreQuestion()
          // that.switchPage()
          //重置数据
          ai_source.close();
          // alltext = "";
          // newalltext = "";
          return;
        } else {
          let content = "";
          if (eData.type == "a") {
            content = eData.content.replace("'", "").replace("'", "");
          }
          if (alltext == "") {
            alltext = content.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
          } else {
            alltext += content;
          }
          // 处理流数据
          newalltext = alltext + "_";
          newalltext = newalltext.replace(/\\n/g, "\n"); // \\n
          newalltext = newalltext.replace(/\\/g, "");
          if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
          // 转化返回的回复流数据
          //newalltext = md.render(newalltext);
          // that.is_description = true;
          that.style.aiContent = newalltext;
          that.style.radio = "description";
        }
      };
    },
    sumbit_question() {
      let that = this;
      that.$emit("changeisPromptsList", false);
      if (
        that.style.fileType.indexOf("image/") != -1 &&
        that.style.AINewFileList.length != 0
      ) {
        that.style.isLoading = true;
        that.is_description = false;
        that.$props.lf.updateEditConfig({
          isSilentMode: true,
          stopZoomGraph: true,
          stopScrollGraph: true,
          stopMoveGraph: true,
        });
        if (that.style.isImageToText && that.style.topic != "") {
          that.style.aiContent = "";
          that.$emit("setStyle", {
            aiContent: that.style.aiContent,
            isLoading: that.style.isLoading,
          });
          let num = 1;
          that.a = setInterval(function () {
            let str = "";
            for (let i = 0; i < num; i++) {
              str += ".";
            }
            that.style.answer = that.languageData.tip84 + str;
            if (num < 6) {
              num = num + 1;
            } else {
              num = 1;
            }
            that.$emit("setStyle", {
              answer: that.style.answer,
            });
          }, 500);
          let uid = uuidv4();
          that.$ajax
            .post("https://gpt4.cocorobo.cn/imageAnalyseNew", {
              uid: uid,
              topic: that.style.topic,
              url: that.style.AINewFileList[0].url,
              fileName: that.style.AINewFileList[0].name,
              max_tokens: 4096,
            })
            .then(function () {})
            .catch(function (error) {
              console.log(error);
              that.style.aiContent = "";
              that.style.isLoading = false;
              that.style.answer = "";
              clearInterval(that.a);
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                aiContent: that.style.aiContent,
                answer: that.style.answer,
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            });
          that.askNewOwn(uid);
        } else if (that.style.isImageToText && that.style.topic == "") {
          that.$message({
            message: that.languageData.tip87,
            type: "warning",
          });
          that.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
        }
        if (that.style.isImageToImage) {
          that.style.imgResult = "";
          that.$emit("setStyle", {
            imgResult: that.style.imgResult,
            isLoading: that.style.isLoading,
          });
          that.isImageLoading = true;
          that.$ajax
            .post("https://gpt4.cocorobo.cn/imageCreateNew", {
              url: that.style.AINewFileList[0].url,
              fileName: that.style.AINewFileList[0].name,
              uid: uuidv4(),
            })
            .then(function (res) {
              that.style.isLoading = false;
              that.isImageLoading = false;
              that.style.imgResult =
                "https://gpt4.cocorobo.cn" +
                res.data.FunctionResponse.image_url_list[0];
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                imgResult: that.style.imgResult,
                radio: "image",
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            })
            .catch(function (error) {
              console.log(error);
              // that.style.aiContent = ""
              that.style.isLoading = false;
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                // aiContent: that.style.aiContent
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            });
        }
      } else if (
        (that.style.fileType.indexOf("audio/") != -1 &&
          that.style.AINewFileList.length != 0) ||
        that.style.AINewFileList.length == 0
      ) {
        that.style.isLoading = true;
        that.is_description = false;
        that.$props.lf.updateEditConfig({
          isSilentMode: true,
          stopZoomGraph: true,
          stopScrollGraph: true,
          stopMoveGraph: true,
        });
        if (that.style.isTextToText) {
          that.style.aiContent = "";
          let num = 1;
          that.a = setInterval(function () {
            let str = "";
            for (let i = 0; i < num; i++) {
              str += ".";
            }
            that.style.answer = that.languageData.tip84 + str;
            if (num < 6) {
              num = num + 1;
            } else {
              num = 1;
            }
            that.$emit("setStyle", {
              answer: that.style.answer,
            });
          }, 500);
          that.$emit("setStyle", {
            aiContent: that.style.aiContent,
            isLoading: that.style.isLoading,
          });
          let uid = uuidv4();
          that.$ajax
            .post("https://gpt4.cocorobo.cn/chat", {
              model: "gpt-3.5-turbo",
              temperature: 0,
              max_tokens: 2048,
              top_p: 1,
              frequency_penalty: 0,
              presence_penalty: 0,
              messages: [
                {
                  role: "user",
                  content: that.style.topic,
                },
              ],
              uid: uid,
            })
            .then(function () {})
            .catch(function (error) {
              console.log(error);
              that.style.aiContent = "";
              that.style.isLoading = false;
              that.style.answer = "";
              clearInterval(that.a);
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                aiContent: that.style.aiContent,
                answer: that.style.answer,
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            });
          that.askNewOwn(uid);
        }
        if (that.style.isTextToImage) {
          that.style.imgResult = "";
          that.isImageLoading = true;
          that.$emit("setStyle", {
            isLoading: that.style.isLoading,
            imgResult: that.style.imgResult,
          });
          that.$ajax
            .post("https://gpt4.cocorobo.cn/getImageNew", {
              size: "1024x1024",
              quality: "standard",
              n: 1,
              prompt: that.style.topic,
              style: "vivid",
              uid: uuidv4(),
            })
            .then(function (res) {
              console.log(res);
              that.style.isLoading = false;
              that.isImageLoading = false;
              that.style.imgResult =
                "https://gpt4.cocorobo.cn" +
                res.data.FunctionResponse.image_url_list[0];
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                imgResult: that.style.imgResult,
                radio: "image",
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            })
            .catch(function (error) {
              console.log(error);
              // that.style.aiContent = ""
              that.style.isLoading = false;
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                // aiContent: that.style.aiContent
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            });
        }
        if (that.style.isTextToAudio) {
          that.style.audioList = [];
          that.isAudioLoading = true;
          that.$emit("setStyle", {
            isLoading: that.style.isLoading,
            audioList: that.style.audioList,
          });
          that.$ajax
            .post("https://gpt4.cocorobo.cn/getAudio", {
              input: that.style.topic,
              uid: uuidv4(),
              response_format: "mp3",
              voice: "shimmer",
            })
            .then(function (res) {
              console.log(res);
              that.style.isLoading = false;
              that.isAudioLoading = false;
              that.style.audioList = [
                {
                  url:
                    "https://gpt4.cocorobo.cn" + res.data.FunctionResponse.url,
                  name: res.data.FunctionResponse.url.split("/pdf/")[1],
                },
              ];
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                audioList: that.style.audioList,
                radio: "audio",
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            })
            .catch(function (error) {
              console.log(error);
              // that.style.aiContent = ""
              that.style.isLoading = false;
              that.$emit("setStyle", {
                isLoading: that.style.isLoading,
                // aiContent: that.style.aiContent
              });
              that.$props.lf.updateEditConfig({
                isSilentMode: false,
                stopZoomGraph: false,
                stopScrollGraph: false,
                stopMoveGraph: false,
              });
            });
        }
      } else {
        //获取用户信息
        let user = {};
        if (
          window.location.href.includes("localhost") ||
          window.location.href.includes("192.168")
        ) {
          user.userId = "asaqwqwqwsd";
        } else {
          if (top.US.userInfo) {
            user.userId = top.US.userInfo.userid;
            user.userName = top.US.userInfo.username;
          }
        }
        that.style.aiContent = "";
        that.style.isLoading = true;
        that.is_description = false;
        that.$props.lf.updateEditConfig({
          isSilentMode: true,
          stopZoomGraph: true,
          stopScrollGraph: true,
          stopMoveGraph: true,
        });
        that.$emit("setStyle", {
          aiContent: that.style.aiContent,
          isLoading: that.style.isLoading,
        });
        let num = 1;
        that.a = setInterval(function () {
          let str = "";
          for (let i = 0; i < num; i++) {
            str += ".";
          }
          that.style.answer = that.languageData.tip84 + str;
          if (num < 6) {
            num = num + 1;
          } else {
            num = 1;
          }
          that.$emit("setStyle", {
            answer: that.style.answer,
          });
        }, 500);
        let config = {
          method: "post",
          url: "https://gpt4.cocorobo.cn/ask_question_new_excel",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            question: that.style.topic,
            userId: user.userId,
            groupId: that.$store.state.cid,
            userName: "",
            type: "document",
            file_id: that.style.id,
            file_name: that.style.AINewFileList[0].name,
            file_url: that.style.AINewFileList[0].url,
            mind_map_question: that.style.topic,
            domain_flag: true,
          },
        };
        that
          .$ajax(config)
          .then(() => {})
          .catch((error) => {
            console.log(error);
            that.style.aiContent = "";
            that.style.isLoading = false;
            that.style.answer = "";
            that.$emit("setStyle", {
              isLoading: that.style.isLoading,
              aiContent: that.style.aiContent,
              answer: that.style.answer,
            });
            that.$props.lf.updateEditConfig({
              isSilentMode: false,
              stopZoomGraph: false,
              stopScrollGraph: false,
              stopMoveGraph: false,
            });
          });
        that.askOwnGpt4(user.userId);
      }
    },
    changeTextToText() {
      this.style.isTextToText = true;
      this.style.isTextToImage = false;
      this.style.isTextToAudio = false;
      this.$emit("setStyle", {
        isTextToText: this.style.isTextToText,
        isTextToImage: this.style.isTextToImage,
        isTextToAudio: this.style.isTextToAudio,
      });
    },
    changeTextToImage() {
      this.style.isTextToText = false;
      this.style.isTextToImage = true;
      this.style.isTextToAudio = false;
      this.$emit("setStyle", {
        isTextToText: this.style.isTextToText,
        isTextToImage: this.style.isTextToImage,
        isTextToAudio: this.style.isTextToAudio,
      });
    },
    changeTextToAudio() {
      this.style.isTextToText = false;
      this.style.isTextToImage = false;
      this.style.isTextToAudio = true;
      this.$emit("setStyle", {
        isTextToText: this.style.isTextToText,
        isTextToImage: this.style.isTextToImage,
        isTextToAudio: this.style.isTextToAudio,
      });
    },
    changeImageToText() {
      this.style.isImageToText = true;
      this.style.isImageToImage = false;
      this.$emit("setStyle", {
        isImageToText: this.style.isImageToText,
        isImageToImage: this.style.isImageToImage,
      });
    },
    changeImageToImage() {
      this.style.isImageToText = false;
      this.style.isImageToImage = true;
      this.style.topic = "";
      this.$emit("setStyle", {
        isImageToText: this.style.isImageToText,
        isImageToImage: this.style.isImageToImage,
        topic: this.style.topic,
      });
    },
    deleteAINewFileList() {
      let edgeModels = this.$props.lf.getNodeIncomingEdge(this.style.id);
      if (edgeModels.length != 0) {
        let edge = this.$props.lf.graphModel
          .getEdgeModelById(edgeModels[0].id)
          .getData();
        this.$props.lf.deleteEdge(edge.id);
        let newNodes = [];
        let newEdges = [];
        newEdges.push(edge);
        sendUpdate(newNodes, newEdges, "delete", 0);
        let newData = {
          nodes: newNodes,
          edges: newEdges,
          backgroundUrl: localStorage.getItem("background"),
        };
        add_Operation(newData, "deleteEdge");
        this.$props.lf.clearSelectElements();
        this.$emit("changeSelect", "");
      }
      this.style.AINewFileList = [];
      this.style.isTextToText = true;
      this.style.isTextToImage = false;
      this.style.isTextToAudio = false;
      this.style.isImageToText = false;
      this.style.isImageToImage = false;
      this.$emit("setStyle", {
        AINewFileList: this.style.AINewFileList,
        isTextToText: this.style.isTextToText,
        isTextToImage: this.style.isTextToImage,
        isTextToAudio: this.style.isTextToAudio,
        isImageToText: this.style.isImageToText,
        isImageToImage: this.style.isImageToImage,
      });
    },
    sumbit_question_again() {
      let _this = this;
      let node = this.$props.lf.graphModel
        .getNodeModelById(_this.style.id)
        .getData();
      let x = node.x;
      let id = uuidv4();
      node.id = id;
      node.properties.aiContent = "";
      node.properties.imgResult = "";
      node.properties.audioList = [];
      node.properties.radio = "";
      node.x = x + 300;
      if (
        (node.properties.AINewFileList &&
          node.properties.AINewFileList.length != 0 &&
          node.properties.fileType &&
          node.properties.fileType.indexOf("audio/") == -1) ||
        !node.properties.fileType
      ) {
        node.properties.topic = "";
      }
      _this.$props.lf.graphModel.addNode(node);
      let x1 =
        _this.$props.lf.graphModel.getNodeModelById(_this.style.id).x +
        _this.$props.lf.graphModel.getNodeModelById(_this.style.id)._width / 2;
      let x2 =
        _this.$props.lf.graphModel.getNodeModelById(id).x -
        _this.$props.lf.graphModel.getNodeModelById(id)._width / 2;
      let y1 = _this.$props.lf.graphModel.getNodeModelById(_this.style.id).y;
      let y2 = _this.$props.lf.graphModel.getNodeModelById(id).y;
      let edgeId = uuidv4();
      let edge = {
        id: edgeId,
        properties: {},
        zIndex: 1,
        type: "pro-line",
        sourceNodeId: _this.style.id,
        targetNodeId: id,
        startPoint: {
          x: x1,
          y: y1,
        },
        endPoint: {
          x: x2,
          y: y2,
        },
      };
      _this.$props.lf.graphModel.addEdge(edge);
      _this.$props.lf.graphModel.selectNodeById(id);
      _this.$emit("changeSelect", "");
      let newNodes = [];
      let newEdges = [];
      newNodes.push(_this.$props.lf.graphModel.getNodeModelById(id).getData());
      newEdges.push(
        _this.$props.lf.graphModel.getEdgeModelById(edgeId).getData()
      );
      sendUpdate(newNodes, newEdges, "update", 0);
      let newData = {
        nodes: newNodes,
        edges: newEdges,
        backgroundUrl: localStorage.getItem("background"),
      };
      add_Operation(newData, "addNode");
    },
    send_pdf(pdfUrl) {
      let that = this;
      let uid = uuidv4();
      let config = {
        method: "post",
        url: "https://gpt.cocorobo.cn/createKnowledgeBase",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          url: pdfUrl,
          groupId: that.style.id,
          result_id: uid,
          user_id: that.$store.state.user.userId,
          domain_flag: true,
        },
        timeout: 0,
      };
      this.$ajax(config)
        .then((res) => {
          if (res.data.FunctionResponse.Error == "无法识别文本信息，分析失败") {
            console.log("无法识别文本信息，分析失败");
          }
        })
        .catch(() => {
          //that.$message.error("很抱歉，该文档字数超出，暂时无法解析");
          //alert(that.lang.wlcw);
        });
    },
    handleChange(file, fileList) {
      console.log(file, fileList);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handlePreviewFile(file) {
      console.log(file);
      this.$emit("showFile", "");
    },
    handleIconListRemove() {
      const iconList = [];
      this.$emit("setIconList", iconList);
      this.$emit("setStyle", {
        iconList: [],
      });
    },
    handleImageListRemove() {
      var _this = this;
      const imageList = [];
      _this.$emit("setImageList", imageList);
      _this.$emit("setStyle", {
        imageList: [],
        // radio: "imageList",
        // title: this.languageData.image,
        // titleColor: "#AA64D1"
      });
    },
    handleVideoListRemove() {
      var _this = this;
      const videoList = [];
      _this.$emit("setVideoList", videoList);
      _this.$emit("setStyle", {
        videoList: [],
        // radio: "videoList",
        // styleRadio: '1',
        // title: this.languageData.video,
        // titleColor: "#7EAB55"
      });
    },
    handleAudioListRemove() {
      var _this = this;
      const audioList = [];
      _this.$emit("setAudioList", audioList);
      _this.$emit("setStyle", {
        audioList: [],
        // radio: "audioList",
        // title: this.languageData.voice,
        // titleColor: "#70C2A1"
      });
      _this.recorder.duration = 0;
    },
    handleFileListRemove() {
      var _this = this;
      const fileList = [];
      _this.$emit("setFileList", fileList);
      _this.$emit("setStyle", {
        fileList: [],
        // radio: "audioList",
        // title: this.languageData.voice,
        // titleColor: "#70C2A1"
      });
    },
    setStyle(item) {
      this.$emit("setStyle", item);
    },
    $_selectBorder(val) {
      this.$emit("setStyle", {
        borderStyle: val,
      });
    },
    getGroupInputValue(e) {
      this.style.groupTitle = e;
      this.$emit("setStyle", {
        groupTitle: e,
      });
    },
    getInputValue(e) {
      this.style.title = e;
      this.$emit("setStyle", {
        title: this.style.title,
      });
    },
    getStageInputValue(e) {
      this.style.stageName = e;
      this.$emit("setStyle", {
        stageName: this.style.stageName,
      });
    },
    getThemeInputValue(e) {
      this.style.topic = e;
      this.$emit("setStyle", {
        topic: this.style.topic,
      });
    },
    getTaskInputValue(e) {
      this.style.taskTitle = e;
      this.$emit("setStyle", {
        taskTitle: this.style.taskTitle,
      });
    },
    getHomeworkInputValue(e) {
      this.style.homeworkName = e;
      this.$emit("setStyle", {
        homeworkName: this.style.homeworkName,
      });
    },
    getThinkingInputValue(e) {
      this.style.thinkingTitle = e;
      this.$emit("setStyle", {
        thinkingTitle: this.style.thinkingTitle,
      });
    },
    getAIInputValue(e) {
      this.style.topic = e;
      this.$emit("setStyle", {
        topic: this.style.topic,
      });
    },
    getUpInputValue(e) {
      this.style.up = e;
      this.$emit("setStyle", {
        up: this.style.up,
      });
    },
    getUpInputValueNew(e) {
      if(e.key == "ArrowUp") {
        this.style.up = e.key;
        this.$emit("setStyle", {
          up: this.style.up,
        });
      }
    },
    getDownInputValue(e) {
      this.style.down = e;
      this.$emit("setStyle", {
        down: this.style.down,
      });
    },
    getDownInputValueNew(e) {
      if(e.key == "ArrowDown") {
        this.style.down = e.key;
        this.$emit("setStyle", {
          down: this.style.down,
        });
      }
    },
    getLeftInputValue(e) {
      this.style.left = e;
      this.$emit("setStyle", {
        left: this.style.left,
      });
    },
    getLeftInputValueNew(e) {
      if(e.key == "ArrowLeft") {
        this.style.left = e.key;
        this.$emit("setStyle", {
          left: this.style.left,
        });
      }
    },
    getRightInputValue(e) {
      this.style.right = e;
      this.$emit("setStyle", {
        right: this.style.right,
      });
    },
    getRightInputValueNew(e) {
      if(e.key == "ArrowRight") {
        this.style.right = e.key;
        this.$emit("setStyle", {
          right: this.style.right,
        });
      }
    },
    getXInputValue(e) {
      this.style.distance_x = e;
      this.$emit("setStyle", {
        distance_x: this.style.distance_x,
      });
    },
    getYInputValue(e) {
      this.style.distance_y = e;
      this.$emit("setStyle", {
        distance_y: this.style.distance_y,
      });
    },
    getAIInputValueNew(e) {
      if (this.style.AINewFileList.length == 0) {
        if (e == "/") {
          this.style.topic = "";
          // this.$emit("setStyle", {
          //   topic: this.style.topic,
          // });
          this.$emit("changeisPromptsList", true);
        } else {
          this.style.topic = e;
          this.$emit("setStyle", {
            topic: this.style.topic,
          });
        }
      } else {
        this.style.topic = e;
        this.$emit("setStyle", {
          topic: this.style.topic,
        });
      }
    },
    getTextareaValue(e) {
      this.style.content = e;
    },
    getIframeValue(e) {
      this.style.iframeUrl = e;
      var select =
        this.style.title == "" ||
        this.style.title == this.languageData.text ||
        this.style.title == this.languageData.iframe ||
        this.style.title == this.languageData.embedCode ||
        this.style.title == this.languageData.image ||
        this.style.title == this.languageData.video ||
        this.style.title == this.languageData.voice ||
        this.style.title == this.languageData.card;
      if (this.style.iconRadio != false) {
        this.$emit("setStyle", {
          iconRadio: false,
          width: 250,
          height: 250,
          nodeSize: {
            width: 250,
            height: 250,
          },
        });
      }
      this.$emit("setStyle", {
        iframeUrl: e.replace('"', "'"),
        radio: "iframeUrl",
        title: select ? this.languageData.iframe : this.style.title,
        titleColor: this.style.isExistSelfAdaption
          ? "#F5C342"
          : this.style.titleColor,
      });
    },
    getEmbedCodeValue(e) {
      this.style.embedCode = e;
      var select =
        this.style.title == "" ||
        this.style.title == this.languageData.text ||
        this.style.title == this.languageData.iframe ||
        this.style.title == this.languageData.embedCode ||
        this.style.title == this.languageData.image ||
        this.style.title == this.languageData.video ||
        this.style.title == this.languageData.voice ||
        this.style.title == this.languageData.card;
      if (this.style.iconRadio != false) {
        this.$emit("setStyle", {
          iconRadio: false,
          width: 250,
          height: 250,
          nodeSize: {
            width: 250,
            height: 250,
          },
        });
      }
      this.$emit("setStyle", {
        embedCode: e,
        radio: "embedCode",
        title: select ? this.languageData.embedCode : this.style.title,
        titleColor: this.style.isExistSelfAdaption
          ? "#F5C342"
          : this.style.titleColor,
      });
    },
    getSelectOptionValue(e) {
      this.style.selectOption[e.target.dataset.index] = e.target.value;
      this.$emit("setStyle", {
        selectOption: this.style.selectOption,
      });
    },
    $_changeTitle(e) {
      this.$emit("setStyle", {
        title: e,
      });
    },
    $_changeStageTitle(e) {
      this.$emit("setStyle", {
        stageName: e,
      });
    },
    $_changeThemeTitle(e) {
      this.$emit("setStyle", {
        topic: e,
      });
    },
    $_changeTaskTitle(e) {
      this.$emit("setStyle", {
        taskTitle: e,
      });
    },
    $_changeHomeworkTitle(e) {
      this.$emit("setStyle", {
        homeworkName: e,
      });
    },
    $_changeThinkingTitle(e) {
      this.$emit("setStyle", {
        thinkingTitle: e,
      });
    },
    $_changeAITopic(e) {
      this.$emit("setStyle", {
        topic: e,
      });
    },
    $_changeUp(e) {
      this.$emit("setStyle", {
        up: e,
      });
    },
    $_changeDown(e) {
      this.$emit("setStyle", {
        down: e,
      });
    },
    $_changeLeft(e) {
      this.$emit("setStyle", {
        left: e,
      });
    },
    $_changeRight(e) {
      this.$emit("setStyle", {
        right: e,
      });
    },
    $_changeX(e) {
      this.$emit("setStyle", {
        distance_x: e,
      });
    },
    $_changeY(e) {
      this.$emit("setStyle", {
        distance_y: e,
      });
    },
    $_changeGroupTitle(e) {
      this.$emit("setStyle", {
        groupTitle: e,
      });
    },
    getSelectInputValue(e) {
      this.style.selectTitle = e;
      this.$emit("setStyle", {
        selectTitle: e,
      });
    },
    getSelectTextareaValue(e) {
      this.style.selectContent = e;
      this.$emit("setStyle", {
        selectContent: e,
      });
    },
    // getSelectOptionTextareaValue(e) {
    //   this.style.currentInput = e
    //   this.style.selectOption[this.style.currentInputIndex] = this.style.currentInput
    //   this.$set(this.style, 'selectOption', this.style.selectOption)
    // },
    getSelectAnswerInputValue(e) {
      this.style.selectAnswer = e;
    },
    getDescribeInputValue(e) {
      this.style.describe = e;
      // this.style.isShowDescribe = true
      this.$emit("setStyle", {
        describe: e,
        // isShowDescribe: true
      });
    },
    $_changeDescribe(e) {
      this.$emit("setStyle", {
        describe: e,
        // isShowDescribe: true
      });
    },
    getTaskContentInputValue(e) {
      this.style.taskContent = e;
      this.$emit("setStyle", {
        taskContent: e,
      });
    },
    $_changeTaskContent(e) {
      this.$emit("setStyle", {
        taskContent: e,
      });
    },
    // $_changeContent(e) {
    //   this.$emit('setStyle', {
    //     content: e,
    //     radio: "content",
    //     title: this.languageData.text,
    //     titleColor: "#DE8344"
    //   })
    // },
    $_changeIframeUrl(e) {
      var select =
        this.style.title == "" ||
        this.style.title == this.languageData.text ||
        this.style.title == this.languageData.iframe ||
        this.style.title == this.languageData.embedCode ||
        this.style.title == this.languageData.image ||
        this.style.title == this.languageData.video ||
        this.style.title == this.languageData.voice ||
        this.style.title == this.languageData.card;
      if (this.style.iconRadio != false) {
        this.$emit("setStyle", {
          iconRadio: false,
          width: 250,
          height: 250,
          nodeSize: {
            width: 250,
            height: 250,
          },
        });
      }
      this.$emit("setStyle", {
        iframeUrl: e.replace('"', "'"),
        radio: "iframeUrl",
        title: select ? this.languageData.iframe : this.style.title,
        titleColor: this.style.isExistSelfAdaption
          ? "#F5C342"
          : this.style.titleColor,
      });
    },
    $_changeEmbedCode(e) {
      var select =
        this.style.title == "" ||
        this.style.title == this.languageData.text ||
        this.style.title == this.languageData.iframe ||
        this.style.title == this.languageData.embedCode ||
        this.style.title == this.languageData.image ||
        this.style.title == this.languageData.video ||
        this.style.title == this.languageData.voice ||
        this.style.title == this.languageData.card;
      if (this.style.iconRadio != false) {
        this.$emit("setStyle", {
          iconRadio: false,
          width: 250,
          height: 250,
          nodeSize: {
            width: 250,
            height: 250,
          },
        });
      }
      this.$emit("setStyle", {
        embedCode: e,
        radio: "embedCode",
        title: select ? this.languageData.embedCode : this.style.title,
        titleColor: this.style.isExistSelfAdaption
          ? "#F5C342"
          : this.style.titleColor,
      });
    },
    $_changeSelectTitle(e) {
      this.$emit("setStyle", {
        selectTitle: e,
      });
    },
    $_changeSelectContent(e) {
      this.$emit("setStyle", {
        selectContent: e,
      });
    },
    $_changeSelectOption(e) {
      this.style.selectOption[e.target.dataset.index] = e.target.value;
      this.$emit("setStyle", {
        selectOption: this.style.selectOption,
      });
    },
    $_changeSelectAnswer(e) {
      this.$emit("setStyle", {
        selectAnswer: e,
      });
    },
    $_changeColorProperty(c, type) {
      const color = c;
      this[type] = color;
      this.$emit("setStyle", {
        [type]: color,
      });
    },
    $_changeFontSize(val) {
      this.$emit("setStyle", {
        fontSize: val,
      });
    },
    $_changeBorderWidth(val) {
      this.$emit("setStyle", {
        borderWidth: val,
      });
    },
    $_changeFontFamily(val) {
      this.$emit("setStyle", {
        fontFamily: val,
      });
    },
    $_changeLineHeight(val) {
      this.$emit("setStyle", {
        lineHeight: val,
      });
    },
    $_changeFontWeight() {
      if (this.style.fontWeight === "bold") {
        this.$emit("setStyle", {
          fontWeight: "normal",
        });
      } else {
        this.$emit("setStyle", {
          fontWeight: "bold",
        });
      }
    },
    $_changeTextDecoration() {
      if (this.style.textDecoration === "underline") {
        this.$emit("setStyle", {
          textDecoration: "none",
        });
      } else {
        this.$emit("setStyle", {
          textDecoration: "underline",
        });
      }
    },
    $_changeFontStyle() {
      if (this.style.fontStyle === "italic") {
        this.$emit("setStyle", {
          fontStyle: "normal",
        });
      } else {
        this.$emit("setStyle", {
          fontStyle: "italic",
        });
      }
    },
    $_changeTextAlign(val) {
      this.$emit("setStyle", {
        textAlign: val,
      });
    },
    getKeyword() {
      var _this = this;
      _this.style.isLoading = true;
      _this.style.keyWordList = [];
      _this.style.currentKeyWord = "";
      _this.style.aiContent = "";
      _this.$emit("setStyle", {
        keyWordList: _this.style.keyWordList,
        currentKeyWord: _this.style.currentKeyWord,
        isLoading: _this.style.isLoading,
        aiContent: _this.style.aiContent,
      });
      _this.$ajax
        .post("https://gpt.cocorobo.cn/getKeyword", {
          topic: _this.style.topic,
        })
        .then(function (response) {
          let result = response.data.FunctionResponse.result;
          result = result.replace(/\s/g, "");
          _this.style.keyWordList = result.split(";");
          _this.style.isLoading = false;
          _this.$emit("setStyle", {
            keyWordList: _this.style.keyWordList,
            isLoading: _this.style.isLoading,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    askForTopic() {
      var _this = this;
      _this.style.aiContent = "";
      _this.style.isLoading = true;
      _this.style.questionList = [];
      _this.style.keyWordList = [];
      _this.style.imgResultList = [];
      _this.is_description = false;
      _this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      _this.$emit("setStyle", {
        aiContent: _this.style.aiContent,
        keyWordList: _this.style.keyWordList,
        questionList: _this.style.questionList,
        imgResultList: _this.style.imgResultList,
        isLoading: _this.style.isLoading,
      });
      _this.isSending = true;
      let uid = uuidv4();
      _this.$ajax
        .post("https://gpt.cocorobo.cn/askNewForTopic", {
          uid: uid,
          topic: _this.style.topic,
        })
        .then(function () {
          // _this.is_description = true;
          // let result = response.data.FunctionResponse.result;
          // let keyword_result = response.data.FunctionResponse.keyword_result;
          // keyword_result = keyword_result.replace(/\s/g, "").replace(/\n/g, "");
          // let newkeywordResult = keyword_result.split(";");
          // newkeywordResult.sort(function (a, b) {
          //   return a.length - b.length;
          // });
          // _this.style.keyWordList = newkeywordResult;
          // let questions_result = response.data.FunctionResponse.questions_result;
          // let newResult = [];
          // if (result.indexOf(";") != -1) {
          //   questions_result = questions_result
          //     .replace(/\s/g, "")
          //     .replace(/\n/g, "");
          //   newResult = questions_result.split(";");
          // } else {
          //   newResult = questions_result.split("\n");
          // }
          // _this.style.questionList = newResult;
          // _this.style.aiContent = result;
          // _this.style.isLoading = false;
          // _this.style.imgResultList = response.data.FunctionResponse.imgResult;
          // _this.$emit("setStyle", {
          //   aiContent: _this.style.aiContent,
          //   keyWordList: _this.style.keyWordList,
          //   questionList: _this.style.questionList,
          //   imgResultList: _this.style.imgResultList,
          //   isLoading: _this.style.isLoading,
          //   radio: "description"
          // });
          // _this.$props.lf.updateEditConfig({
          //   isSilentMode: false,
          //   stopZoomGraph: false,
          //   stopScrollGraph: false,
          //   stopMoveGraph: false
          // });
        })
        .catch(function (error) {
          console.log(error);
          _this.style.isLoading = false;
          _this.$emit("setStyle", {
            isLoading: _this.style.isLoading,
          });
        });
      _this.askOwn(uid);
    },
    askOwn(uid) {
      let that = this;
      // 创建EventSource
      let ai_source = new EventSource("https://gpt.cocorobo.cn/ask_own/" + uid);
      that.ai_source = ai_source;
      // 存储获取的流数据（追加）
      let alltext = "";
      // 存储打字效果数据
      let newalltext = "";
      // 初始化md
      //const md = new MarkdownIt();
      // 监听流数据的返回
      ai_source.onmessage = function (e) {
        let eData = JSON.parse(e.data);
        if (eData.content.replace("'", "").replace("'", "") == "[DONE]") {
          // that.isHiddenRightSide_flag=true;
          newalltext = newalltext.replace("_", "");
          //需要replace替换两次
          newalltext = newalltext.replace("_", "");
          // 在显示数组中存储当前数据
          that.is_description = true;
          that.style.aiContent = newalltext;
          that.style.isLoading = false;
          that.$emit("setStyle", {
            aiContent: that.style.aiContent,
            isLoading: that.style.isLoading,
            radio: "description",
          });
          that.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
          that.getMoreKeyword();
          that.getMoreQuestion();
          that.switchPage();
          //重置数据
          ai_source.close();
          that.isSending = false;
          // alltext = "";
          // newalltext = "";
          return;
        } else {
          let content = "";
          if (eData.type == "a") {
            content = eData.content.replace("'", "").replace("'", "");
          }
          if (alltext == "") {
            alltext = content.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
          } else {
            alltext += content;
          }
          // 处理流数据
          newalltext = alltext + "_";
          newalltext = newalltext.replace(/\\n/g, "\n"); // \\n
          newalltext = newalltext.replace(/\\/g, "");
          if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
          // 转化返回的回复流数据
          //newalltext = md.render(newalltext);
          // that.is_description = true;
          that.style.aiContent = newalltext;
          that.style.radio = "description";
        }
      };
    },
    stopAsk() {
      let that = this;
      if (that.ai_source) {
        that.ai_source.close();
        that.isSending = false;
      }
      that.style.aiContent = "";
      that.style.isLoading = false;
      that.style.questionList = [];
      that.style.keyWordList = [];
      that.style.imgResultList = [];
      that.is_description = false;
      that.$props.lf.updateEditConfig({
        isSilentMode: false,
        stopZoomGraph: false,
        stopScrollGraph: false,
        stopMoveGraph: false,
      });
      that.$emit("setStyle", {
        aiContent: that.style.aiContent,
        keyWordList: that.style.keyWordList,
        questionList: that.style.questionList,
        imgResultList: that.style.imgResultList,
        isLoading: that.style.isLoading,
      });
    },
    askForKeyword(e) {
      var _this = this;
      _this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      let oldData = _this.$props.lf.graphModel
        .getNodeModelById(_this.style.id)
        .getData();
      let id = uuidv4();
      let node = {
        id: id,
        type: "icon-AI",
        properties: {
          type: "icon-AI",
          topic: e.target.innerText,
          keyWordList: [],
          questionList: [],
          imgResultList: [],
          isLoading: true,
          aiContent: "",
        },
        x: oldData.x + 300,
        y: oldData.y,
        zIndex: 1002,
      };
      this.$props.lf.graphModel.addNode(node);
      const { transformModel } = this.$props.lf.graphModel;
      transformModel.focusOn(
        node.x,
        node.y,
        window.screen.width,
        window.screen.height
      );
      let x1 =
        _this.$props.lf.graphModel.getNodeModelById(_this.style.id).x +
        _this.$props.lf.graphModel.getNodeModelById(_this.style.id)._width / 2;
      let x2 =
        _this.$props.lf.graphModel.getNodeModelById(id).x -
        _this.$props.lf.graphModel.getNodeModelById(id)._width / 2;
      let y1 = _this.$props.lf.graphModel.getNodeModelById(_this.style.id).y;
      let y2 = _this.$props.lf.graphModel.getNodeModelById(id).y;
      let edgeId = uuidv4();
      let edge = {
        id: edgeId,
        properties: {},
        zIndex: 1,
        type: "pro-line",
        sourceNodeId: _this.style.id,
        targetNodeId: id,
        startPoint: {
          x: x1,
          y: y1,
        },
        endPoint: {
          x: x2,
          y: y2,
        },
      };
      this.$props.lf.graphModel.addEdge(edge);
      let newNodes = [];
      let newEdges = [];
      newNodes.push(_this.$props.lf.graphModel.getNodeModelById(id).getData());
      newEdges.push(
        _this.$props.lf.graphModel.getEdgeModelById(edgeId).getData()
      );
      sendUpdate(newNodes, newEdges, "update", 0);
      let newData = {
        nodes: newNodes,
        edges: newEdges,
        backgroundUrl: localStorage.getItem("background"),
      };
      add_Operation(newData, "addNode");
      _this.$ajax
        .post("https://gpt.cocorobo.cn/askForKeyword", {
          keyword: e.target.innerText,
          content: _this.style.aiContent,
        })
        .then(function (response) {
          let keyword_result = response.data.FunctionResponse.keyword_result;
          keyword_result = keyword_result.replace(/\s/g, "").replace(/\n/g, "");
          let newKeywordResult = keyword_result.split(";");
          newKeywordResult.sort(function (a, b) {
            return a.length - b.length;
          });
          let result = response.data.FunctionResponse.questions_result;
          let newResult = [];
          if (result.indexOf(";") != -1) {
            result = result.replace(/\s/g, "").replace(/\n/g, "");
            newResult = result.split(";");
          } else {
            newResult = result.split("\n");
          }
          _this.$props.lf.graphModel.getNodeModelById(id).setProperties({
            isLoading: false,
            aiContent: response.data.FunctionResponse.result,
            keyWordList: newKeywordResult,
            questionList: newResult,
            imgResultList: response.data.FunctionResponse.imgResult,
            radio: "description",
          });
          newNodes = [];
          newNodes.push(
            _this.$props.lf.graphModel.getNodeModelById(id).getData()
          );
          sendUpdate(newNodes, [], "update", 0);
          let newData = {
            nodes: newNodes,
            edges: [],
            backgroundUrl: localStorage.getItem("background"),
          };
          add_Operation(newData, "updateNode");
          _this.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getMoreKeyword() {
      var _this = this;
      _this.isKeywordLoading = true;
      _this.style.keyWordList = [];
      _this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      _this.$emit("setStyle", {
        keyWordList: _this.style.keyWordList,
      });
      _this.$ajax
        .post("https://gpt.cocorobo.cn/getMoreKeyword", {
          alltext: _this.style.aiContent,
        })
        .then(function (response) {
          let result = response.data.FunctionResponse.questions_result;
          result = result.replace(/\s/g, "").replace(/\n/g, "");
          let newResult = result.split(";");
          newResult.sort(function (a, b) {
            return a.length - b.length;
          });
          _this.style.keyWordList = newResult;
          _this.isKeywordLoading = false;
          _this.$emit("setStyle", {
            keyWordList: _this.style.keyWordList,
          });
          _this.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
        })
        .catch(function (error) {
          console.log(error);
          _this.isKeywordLoading = false;
        });
    },
    getMoreQuestion() {
      var _this = this;
      _this.isQuestionLoading = true;
      _this.style.questionList = [];
      _this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      _this.$emit("setStyle", {
        questionList: _this.style.questionList,
      });
      _this.$ajax
        .post("https://gpt.cocorobo.cn/getMoreQuestion", {
          alltext: _this.style.aiContent,
        })
        .then(function (response) {
          let result = response.data.FunctionResponse.questions_result;
          let newResult = [];
          if (result.indexOf(";") != -1) {
            result = result.replace(/\s/g, "").replace(/\n/g, "");
            newResult = result.split(";");
          } else {
            newResult = result.split("\n");
          }
          _this.style.questionList = newResult;
          _this.isQuestionLoading = false;
          _this.$emit("setStyle", {
            questionList: _this.style.questionList,
          });
          _this.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
        })
        .catch(function (error) {
          console.log(error);
          _this.isQuestionLoading = false;
        });
    },
    askForQuestion(e) {
      var _this = this;
      _this.$props.lf.updateEditConfig({
        isSilentMode: true,
        stopZoomGraph: true,
        stopScrollGraph: true,
        stopMoveGraph: true,
      });
      let oldData = _this.$props.lf.graphModel
        .getNodeModelById(_this.style.id)
        .getData();
      let id = uuidv4();
      let node = {
        id: id,
        type: "icon-AI",
        properties: {
          type: "icon-AI",
          topic: e.target.innerText,
          keyWordList: [],
          questionList: [],
          imgResultList: [],
          isLoading: true,
          aiContent: "",
        },
        x: oldData.x,
        y: oldData.y + 300,
        zIndex: 1002,
      };
      this.$props.lf.graphModel.addNode(node);
      const { transformModel } = this.$props.lf.graphModel;
      transformModel.focusOn(
        node.x,
        node.y,
        window.screen.width,
        window.screen.height
      );
      let x1 = _this.$props.lf.graphModel.getNodeModelById(_this.style.id).x;
      let x2 = _this.$props.lf.graphModel.getNodeModelById(id).x;
      let y1 =
        _this.$props.lf.graphModel.getNodeModelById(_this.style.id).y +
        _this.$props.lf.graphModel.getNodeModelById(_this.style.id)._height / 2;
      let y2 =
        _this.$props.lf.graphModel.getNodeModelById(id).y -
        _this.$props.lf.graphModel.getNodeModelById(id)._height / 2;
      let edgeId = uuidv4();
      let edge = {
        id: edgeId,
        properties: {},
        zIndex: 1,
        type: "pro-line",
        sourceNodeId: _this.style.id,
        targetNodeId: id,
        startPoint: {
          x: x1,
          y: y1,
        },
        endPoint: {
          x: x2,
          y: y2,
        },
      };
      this.$props.lf.graphModel.addEdge(edge);
      let newNodes = [];
      let newEdges = [];
      newNodes.push(_this.$props.lf.graphModel.getNodeModelById(id).getData());
      newEdges.push(
        _this.$props.lf.graphModel.getEdgeModelById(edgeId).getData()
      );
      sendUpdate(newNodes, newEdges, "update", 0);
      let newData = {
        nodes: newNodes,
        edges: newEdges,
        backgroundUrl: localStorage.getItem("background"),
      };
      add_Operation(newData, "addNode");
      _this.$ajax
        .post("https://gpt.cocorobo.cn/askForTopic", {
          topic: e.target.innerText,
        })
        .then(function (response) {
          let keyword_result = response.data.FunctionResponse.keyword_result;
          keyword_result = keyword_result.replace(/\s/g, "").replace(/\n/g, "");
          let newKeywordResult = keyword_result.split(";");
          newKeywordResult.sort(function (a, b) {
            return a.length - b.length;
          });
          let result = response.data.FunctionResponse.questions_result;
          let newResult = [];
          if (result.indexOf(";") != -1) {
            result = result.replace(/\s/g, "").replace(/\n/g, "");
            newResult = result.split(";");
          } else {
            newResult = result.split("\n");
          }
          _this.$props.lf.graphModel.getNodeModelById(id).setProperties({
            isLoading: false,
            aiContent: response.data.FunctionResponse.result,
            keyWordList: newKeywordResult,
            questionList: newResult,
            imgResultList: response.data.FunctionResponse.imgResult,
            radio: "description",
          });
          newNodes = [];
          newNodes.push(
            _this.$props.lf.graphModel.getNodeModelById(id).getData()
          );
          sendUpdate(newNodes, [], "update", 0);
          let newData = {
            nodes: newNodes,
            edges: [],
            backgroundUrl: localStorage.getItem("background"),
          };
          add_Operation(newData, "updateNode");
          _this.$props.lf.updateEditConfig({
            isSilentMode: false,
            stopZoomGraph: false,
            stopScrollGraph: false,
            stopMoveGraph: false,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    switchPage() {
      let _this = this;
      _this.isImageLoading = true;
      let page = Math.ceil(Math.random() * 15);
      _this.$emit("setStyle", {
        imgResultList: [],
      });
      _this.$ajax
        .post("https://gpt.cocorobo.cn/search_image", {
          query: _this.style.topic,
          page: page,
          pagesize: 8,
        })
        .then(function (response) {
          _this.$emit("setStyle", {
            imgResultList: response.data.FunctionResponse.result,
          });
          _this.isImageLoading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    selectImage(url) {
      let _this = this;
      _this.style.imgResult = url;
      _this.$emit("setStyle", {
        imgResult: _this.style.imgResult,
        radio: "image",
      });
    },
  },
};
</script>
<style>
.el-upload-list--picture img {
  object-fit: cover;
}

.el-input__inner {
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
}

.el-input__wrapper {
  padding: 0;
}

.el-radio {
  margin-right: 0;
  margin-bottom: 35px;
}

.el-card__header {
  padding: 10px;
  border-bottom: none;
}

.el-button--primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

#diagram-panel .el-tab-pane {
  width: 359px;
}

.el-upload--picture-card {
  width: 104px;
  height: 60px;
  line-height: 70px;
  border: 1px solid #2995ff;
}

#thinkingCard .is-success {
  width: 104px !important;
  height: 60px !important;
}

#thinkingCard .is-success div {
  border-radius: 6px;
  width: 104px;
  height: 60px;
}

#thinkingCard .is-success div img {
  width: 104px;
  height: 60px;
}

.el-tiptap-editor__command-button {
  width: 20px;
  height: 20px;
}

/* #taskContent .el-tiptap-editor__content {
  font-size: 16px;
} */

#taskContent .el-tiptap-editor__footer,
#stageContent .el-tiptap-editor__footer {
  font-size: 12px;
}

.el-tiptap-editor > .el-tiptap-editor__content {
  padding: 10px;
  border: 1px solid #dcdfe6;
}

.el-tiptap-editor__footer {
  border: none;
  border-top: none;
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
}
.el-tiptap-editor__menu-bar {
  border: none;
  border-bottom: none;
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
}

.setting-item .el-upload-list__item-name {
  margin-right: 0;
}

.setting-item
  .el-upload-list--picture
  .el-upload-list__item
  .el-upload-list__item-name,
.setting-item
  .el-upload-list--picture
  .el-upload-list__item.is-success
  .el-upload-list__item-name {
  width: 170px;
}

#iconList
  .el-upload-list--picture
  .el-upload-list__item
  .el-upload-list__item-name,
#iconList
  .el-upload-list--picture
  .el-upload-list__item.is-success
  .el-upload-list__item-name {
  width: 128px;
}

#fileList
  .el-upload-list--text
  .el-upload-list__item
  .el-upload-list__item-name,
#fileList
  .el-upload-list--text
  .el-upload-list__item.is-success
  .el-upload-list__item-name {
  width: 320px;
}

#iconList .upload-demo,
#imageList .upload-demo,
#fileList .upload-demo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.micro_stop {
  display: flex;
  flex-direction: row;
  border: none;
  gap: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 450;
  font-size: inherit;
  text-align: center;
  margin: 0;
  border-radius: 1.5em;
  position: relative;
  padding: 0.5em;
  background: transparent;
  color: #000;
  fill: #000;
  margin-left: 10px;
}

.micro {
  display: flex;
  flex-direction: row;
  border: none;
  gap: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 450;
  font-size: inherit;
  text-align: center;
  margin: 0;
  border-radius: 1.5em;
  position: relative;
  padding: 0.5em;
  background: transparent;
  color: #000;
  fill: #000;
  margin-left: 10px;
}

.micro:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #5d5cde;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: none;
  animation: ChatMessageVoiceInputButton_pulse__5F2SB 2s infinite;
}

@keyframes ChatMessageVoiceInputButton_pulse__5F2SB {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  70% {
    transform: scale(1.25);
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

#new_AI_input {
  padding: 10px 30px 10px 10px;
}

#icon-new-AI:hover {
  color: #007bff;
}

#diagram-panel .el-tabs__content{
  overflow-y: auto;
  padding-right: 10px;
}

.el-tiptap-editor__menu-bubble {
  height: 36px;
}

.ProseMirror-focused {
  color: black;
}
</style>

<style scoped>
.moveDiv {
  height: 100%;
  width: 20px;
  cursor: col-resize;
}

.diagram-panel {
  width: 480px;
  min-width: 480px;
  padding: 20px 20px 20px 0px;
  overflow-y: auto;
  box-sizing: border-box;
}

.short-styles {
  width: 240px;
  margin-top: 10px;
}

.short-styles > div {
  width: 20px;
  height: 20px;
  margin: 0 10px 5px 0;
  box-sizing: border-box;
  float: left;
  border: 1px solid #fff;
  cursor: pointer;
}

.border-style {
  width: 150px;
  height: 0px;
  margin-top: 18px;
  border-bottom-width: 1px;
  border-bottom-color: black;
}

.setting-block {
  overflow: hidden;
}

.setting-item {
  line-height: 12px;
  font-size: 12px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.setting-item ::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: white;
  border-left: 1px solid #e8e8e8;
}

.setting-item ::-webkit-scrollbar-thumb {
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-radius: 6px;
  background: #c9c9c9;
}

.setting-item ::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5;
}

.setting-item > span {
  width: 30px;
  margin-right: 20px;
  text-align: right;
  flex-shrink: 0;
  flex-grow: 0;
}

.border-color {
  width: 40px;
  height: 30px;
  display: inline-block;
  border: 1px solid #eaeaeb;
}

.myPlayer {
  border: 4px solid #409eff;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px;
}

.controlBox {
  display: flex;
  justify-content: space-evenly;
  font-size: 30px;
  color: #409eff;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  /* width: 100%; */
  border: 1px solid #dcdfe6;
}
</style>
