import { h, PolygonNode, PolygonNodeModel } from "@logicflow/core";

class NodeSelectionView extends PolygonNode {
  getLabelShape() {
    const { id, x, y, width, height, properties } = this.props.model;
    const style = this.props.model.getNodeStyle();
    return h(
      "svg",
      {
        x: x - width / 2,
        y: y - height / 2,
        width: 50,
        height: 24,
        style: "z-index: 0; background: none; overflow: auto;",
      },
      [
        properties.labelText
          ? h(
              "text",
              {
                x: 0,
                y: -5,
                fontSize: "16px",
                fill: style.stroke,
              },
              properties.labelText
            )
          : "",
        properties.disabledDelete
          ? ""
          : h(
              "text",
              {
                x: properties.labelText ? 50 : 0,
                y: -5,
                fontSize: "24px",
                cursor: "pointer",
                fill: style.stroke,
                onclick: this.handleCustomDeleteIconClick.bind(this, id),
              },
              "x"
            ),
      ]
    );
  }

  getShape() {
    const { x, y, width, height, id } = this.props.model;
    const style = this.props.model.getNodeStyle();

    return h("g", {}, [
      h("rect", {
        ...style,
        x: x - width / 2,
        y: y - height / 2,
        width,
        height,
        id,
      }),
      this.getLabelShape(),
    ]);
  }
  // 避免点击时，该节点置于最高层，挡住内部节点
  toFront() {}

  /**
   * 点击删除
   * @param id
   */
  handleCustomDeleteIconClick(id) {
    const { graphModel } = this.props;
    graphModel.deleteNode(id);
  }
}

class NodeSelectionModel extends PolygonNodeModel {
  d = 10;

  initNodeData(data) {
    data.text = {
      value: "",
      x: data.x,
      y: data.y,
      draggable: false,
      editable: false,
    };
    super.initNodeData(data);
    this.zIndex = 0;
    this.draggable = true;
  }

  // setAttributes() {
  //     // 默认不显示
  //     this.points = []

  //     // 图render的时候，会把所有nodes数据实例化，全部实例化完成后，放到nodesMap里。
  //     // 节点的setAttributes在实例化的时候执行第一次
  //     // updatePointsByNodes中的getNodeModelById方法，是从nodesMap取的数据，第一次就拿不到，所以要加setTimeout
  //     if ((this.properties?.node_selection_ids).length > 1) {
  //         setTimeout(() => {
  //             this.updatePointsByNodes(this.properties?.node_selection_ids || [])
  //         })
  //     }
  // }

  getNodeStyle() {
    const style = super.getNodeStyle();
    style.stroke = this.properties.strokeColor || "#008000";
    style.strokeDasharray = "10 5";
    return style;
  }

  getDefaultAnchor() {
    return [];
  }

  /**
   * 更新points - 多边形顶点坐标集合
   * @param points
   */
  updatePoints(points) {
    this.points = points;
  }

  /**
   * 更新x y - 多边形中点坐标
   */
  updateCoordinate({ x, y }) {
    this.x = x;
    this.y = y;
  }

  /**
   * 计算新的 points 和 x y
   */
  updatePointsByNodes(nodesIds) {
    const points = [];
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    nodesIds.forEach((id) => {
      const model = this.graphModel.getNodeModelById(id);
      if (!model) return;
      const { width, height, x, y } = model;
      minX = Math.min(minX, x - width / 2 - this.d);
      minY = Math.min(minY, y - height / 2 - this.d);
      maxX = Math.max(maxX, x + width / 2 + this.d);
      maxY = Math.max(maxY, y + height / 2 + this.d);
    });
    points.push([minX, minY], [maxX, minY], [maxX, maxY], [minX, maxY]);

    if ([minX, minY, maxX, maxY].some((n) => Math.abs(n) === Infinity)) return;

    this.updatePoints(points);
    this.updateCoordinate({
      x: (maxX + minX) / 2,
      y: (maxY + minY) / 2,
    });
  }
}

export default {
  type: "my-group-new",
  view: NodeSelectionView,
  model: NodeSelectionModel,
};
