<template>
  <div style="width: 116px">
    <el-popover
      placement="bottom"
      width="116"
      trigger="click"
      popper-style="min-width: 116px;"
    >
      <div
        style="display: flex; flex-flow: wrap; justify-content: space-between"
      >
        <template v-for="(item, index) in colorList" :key="index">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div
              :style="
                'background:' +
                item +
                ';width: 20px;height: 20px;border-radius: 10px;margin:5px;'
              "
              :data-item="item"
              @click="handleChange"
            ></div>
          </div>
        </template>
        <el-checkbox v-if="isExistSelfAdaption" v-model="checked">
          {{ languageData.tip45 }}
        </el-checkbox>
      </div>
      <template #reference>
        <el-button style="border: none">
          <template #default>
            <div
              :style="'background:' + myColor + ';width: 20px;height: 20px;'"
            ></div>
          </template>
        </el-button>
      </template>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "ColorSelect",
  //允许一个自定义组件在使用 v-model 时定制 prop 和 event。默认情况下，一个组件上的 v-model 会把 value 用作 prop 且把 input 用作 event，
  //但是一些输入类型比如单选框和复选框按钮可能想使用 value prop 来达到不同的目的。使用 model 选项可以回避这些情况产生的冲突。
  model: {
    prop: "color",
    event: "update",
  },
  props: {
    //颜色数组
    colorList: {
      type: Array,
      default: () => {
        return ["#FFC0CB", "#DB7093", "#FF1493", "#DC143C"];
      },
    },
    //父组件绑定的值
    color: {
      type: String,
      default: undefined,
    },
    isSelfAdaption: {
      type: Boolean,
      default: false,
    },
    isExistSelfAdaption: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update", "selfAdaption"],
  data() {
    return {
      myColor: undefined,
      checked: this.isSelfAdaption,
      languageData: {},
    };
  },
  watch: {
    color: function (val) {
      this.myColor = val;
    },
    checked(val) {
      this.$emit("selfAdaption", val);
    },
    isSelfAdaption() {
      this.checked = this.isSelfAdaption;
    },
  },
  created() {
    if (this.color && this.color.length > 0) {
      this.myColor = this.color;
    }
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    handleChange(e) {
      //触发update事件更新父组件绑定值
      this.$emit("update", e.target.dataset.item);
    },
  },
};
</script>

<style>
.el-popover {
  min-width: 90px;
  /* top: 167px !important; */
  margin-top: 0 !important;
}
</style>

<style scoped>
.se {
  display: inline-block;
}

.el-button:hover,
.el-button:focus {
  background: #ffffff;
}
</style>
