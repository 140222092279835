<!-- eslint-disable no-case-declarations -->
<script setup>
import { ref, shallowRef, computed, onMounted, watch, defineEmits, defineProps } from 'vue'
import Konva from 'konva'
import { useElementBounding, useDebouncedRefHistory } from '@vueuse/core'
import IconPen from '../icon/Pen.vue'
import IconEraser from '../icon/Eraser.vue'
// import _ from 'lodash'

const emit = defineEmits(['close'])
const props = defineProps({
  transform: {
    type: Object,
    default: () => ({
      "SCALE_X": 1,
      "SCALE_Y": 1,
      "TRANSLATE_X": 0,
      "TRANSLATE_Y": 0,
    })
  },
  presets: {
    type: Array, // array of logicflow node model
    default: () => []
  }
})

const container$ = ref()
const stage$ = ref()
const layer$ = ref()
const stage = shallowRef()
const layer = shallowRef()
const layerSnapShot = ref([])
const { redo, undo } = useDebouncedRefHistory(layerSnapShot, { deep: true, debounce: 1000, capacity: 15 })
watch(() => layerSnapShot.value, () => {
  if (!layer.value) return;
  layer.value.destroyChildren()
  for (const { attrs, className } of layerSnapShot.value) {
    const shape = new Konva[className](attrs)
    layer.value.add(shape)
  }
})

const { width, height } = useElementBounding(container$)

const stageConfig = computed(() => ({
  width: width.value,
  height: height.value,
  scaleX: props.transform.SCALE_X,
  scaleY: props.transform.SCALE_Y,
  x: props.transform.TRANSLATE_X,
  y: props.transform.TRANSLATE_Y,
  // offsetX: props.transform.TRANSLATE_X,
  // offsetY: props.transform.TRANSLATE_Y,
}))


const mode = ref('brush')
const stroke = ref('black')
const strokeOptions = [
  'black', 'gray', 'red', 'green', 'yellow', 'blue',
]
const strokeWidth = ref(3)

onMounted(() => {
  stage.value = stage$.value.getNode()
  layer.value = layer$.value.getNode()
  // TEST
  window.stage = stage.value
  window.layer = layer.value

  props.presets.map(preset => {
    const line = new Konva[preset.properties.kvData.className]({
      ...preset.properties.kvData.attrs,
      // NOTE logicflow的x y是中心点，要转换为left top喂给konva
      x: preset.x - preset.width / 2,
      y: preset.y - preset.height / 2,
      // scaleX: preset.width / preset.rect.width,
      // scaleY: preset.height / preset.rect.height,
      scaleX: preset.width / (preset.properties.rect.width / (preset.properties.kvData.attrs?.scaleX ?? 1)),
      scaleY: preset.height / (preset.properties.rect.height / (preset.properties.kvData.attrs?.scaleY ?? 1)),
    })
    layer.value.add(line)
  })

  let isPaint = false
  let lastLine

  stage.value.on('mousedown touchstart', function () {
    isPaint = true;
    const pos = stage.value.getRelativePointerPosition();
    const pos1 = stage.value.getPointerPosition();
    switch (mode.value) {
      case 'brush':
        lastLine = new Konva.Line({
          stroke: stroke.value,
          strokeWidth: strokeWidth.value,
          hitStrokeWidth: Math.max(strokeWidth.value, 5),
          globalCompositeOperation: 'source-over',
          // round cap for smoother lines
          lineCap: 'round',
          lineJoin: 'round',
          // add point twice, so we have some drawings even on a simple click
          points: [pos.x, pos.y, pos.x, pos.y],
        });
        layer.value.add(lastLine);
        break;
      case 'eraser':
        const shape = layer.value.getIntersection(pos1)
        shape?.destroy()
        break;
      default:
        break;
    }
  });

  stage.value.on('mouseup touchend', function () {
    isPaint = false;
    const rect = lastLine.getSelfRect()
    const points = lastLine.points()
    lastLine.points(points.map((p, i) => i % 2 === 0 ? p - rect.x : p - rect.y))
    lastLine.position({ x: rect.x, y: rect.y })
    layerSnapShot.value = JSON.parse(layer.value.toJSON()).children
  });

  stage.value.on('mousemove touchmove', function (e) {
    if (!isPaint) {
      return;
    }
    // prevent scrolling on touch devices
    e.evt.preventDefault();
    const pos = stage.value.getRelativePointerPosition();
    const pos1 = stage.value.getPointerPosition();
    switch (mode.value) {
      case 'brush':
        const newPoints = lastLine.points().concat([pos.x, pos.y]);
        lastLine.points(newPoints);
        break;
      case 'eraser':
        const shape = layer.value.getIntersection(pos1)
        shape?.destroy()
        break;
      default:
        break;
    }
  });
})

const onClose = () => {
  const childs = layer.value.getChildren()
  const kvNodeProperties = childs.map(c => {
    const clientRect = c.getClientRect({ relativeTo: stage.value })
    return {
      kvData: JSON.parse(c.toJSON()),
      rect: clientRect,
      href: c.toDataURL()
    }
  })
  emit('close', kvNodeProperties)
}
</script>

<template>
  <div ref="container$" class="konva-container" :class="mode">
    <v-stage ref="stage$" :config="stageConfig">
      <v-layer ref="layer$">
      </v-layer>
    </v-stage>
    <Transition appear appear-active-class="animate__animated animate__fadeInUp">
      <div class="draw-config-bar">
        <el-radio-group v-model="mode">
          <el-radio-button value="brush">
            <IconPen />
          </el-radio-button>
          <el-radio-button value="eraser">
            <IconEraser />
          </el-radio-button>
        </el-radio-group>
        <el-divider direction="vertical" />
        <el-radio-group v-model="strokeWidth">
          <el-radio-button :value="3">
            •
          </el-radio-button>
          <el-radio-button :value="6">
            ●
          </el-radio-button>
        </el-radio-group>
        <el-divider direction="vertical" />
        <div class="color-picker">
          <div v-for="preset in strokeOptions" :key="preset" class="color-picker__item"
            :class="{ active: stroke === preset }" :style="{ background: preset }" @click="stroke = preset">
          </div>
          <el-color-picker v-model="stroke" :class="{ active: stroke.startsWith('#') }" />
        </div>
        <el-divider direction="vertical" />
        <el-button-group>
          <el-button text @click="undo">
            <el-icon>
              <RefreshLeft />
            </el-icon>
          </el-button>
          <el-button text @click="redo">
            <el-icon>
              <RefreshRight />
            </el-icon>
          </el-button>
        </el-button-group>
        <el-divider direction="vertical" />
        <el-button text @click="onClose">
          <el-icon>
            <Close />
          </el-icon>
        </el-button>
      </div>
    </Transition>
  </div>
</template>
<style scoped lang="scss">
.konva-container {
  width: 100%;
  height: 100%;

  &.brush :deep(.konvajs-content) {
    cursor: url('../../assets/icon/pen.svg') 2 12, auto;
  }

  &.eraser :deep(.konvajs-content) {
    cursor: url('../../assets/icon/eraser.svg') 2 12, auto;
  }

  .draw-config-bar {
    height: 32px;
    padding: 10px 20px;
    background: #FDFFFF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: stretch;
    position: absolute;
    left: 10px;
    bottom: 0px;

    .el-divider {
      height: 100%;
    }

    .color-picker {
      display: flex;
      align-items: center;
      gap: 8px;

      &__item {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        &.active {
          // outline
          outline: 2px solid #409eff;
          outline-offset: 2px;
        }
      }

      :deep() .el-color-picker {
        &__trigger {
          border: 2px solid;
          border-color: transparent;
        }

        &.active .el-color-picker__trigger {
          border-color: #409eff;
        }
      }
    }
  }



}
</style>