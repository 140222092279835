import ThinkingNode from "./ThinkingNode";

// 左上角ICON为消息的节点
class ElWhiteboardNode extends ThinkingNode.view {
  getImageHref() {
    return require("@/assets/tools/9电子白板.png");
  }
}

export default {
  type: "icon-el-whiteboard",
  view: ElWhiteboardNode,
  model: ThinkingNode.model,
};
