<template>
  <div>
    <div id="headUrl">
      <el-dialog
        :model-value="dialogVisible"
        width="30%"
        :destroy-on-close="true"
        :fullscreen="fullscreen"
        :show-close="false"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <template #header>
          <div style="width: 100%; position: relative">
            <span class="el-dialog__title">{{
              languageData.upload_image
            }}</span>

            <button type="button" class="close" style="top: 4px">
              <img
                v-if="hoverClose"
                src="../../assets/close_pre.png"
                width="16"
                height="16"
                @mouseout="blurClose"
                @click="handleClose"
              />
              <img
                v-else
                src="../../assets/close_nor.png"
                width="16"
                height="16"
                @mouseover="focusClose"
              />
            </button>
          </div>
        </template>
        <div style="height: 100%">
          <div
            style="
              display: flex;
              height: calc(100% - 32px);
              width: calc(100% - 32px);
              padding: 16px;
              justify-content: center;
              align-items: center;
              background: var(--font-icon-white, #fff);
              flex-direction: column;
            "
          >
            <div
              style="
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-direction: row;
                width: 100%;
              "
            >
              <el-button type="primary" @click="selectFile">
                {{ languageData.select_local_file }}
              </el-button>

              <el-button @click="selectNetwork">
                {{ languageData.select_network_file }}
              </el-button>
            </div>
            <input
              id="File"
              type="file"
              style="display: none"
              accept="image/*"
              @change="uploadFile"
            />

            <div
              style="
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-direction: row;
                width: 100%;
                margin-top: 10px;
              "
            >
              <el-button
                disabled
                style="color: #606266; cursor: default; border: none"
              >
                {{ languageData.select_local_file }}
              </el-button>

              <el-button
                disabled
                style="color: #606266; cursor: default; border: none"
              >
                {{ languageData.select_network_file }}
              </el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
emits: ['info', 'setNetwork'],
  data() {
    return {
      hoverMax: false,
      hoverRecovery: false,
      hoverClose: false,
      fullscreen: false,
      languageData: {},
    };
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "./aws-sdk-2.235.1.min.js";
    script.async = "async";
    script.onload = function () {
      var credentials = {
        accessKeyId: "AKIATLPEDU37QV5CHLMH",
        secretAccessKey: "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",
      }; //秘钥形式的登录上传
      window.AWS.config.update(credentials);
      window.AWS.config.region = "cn-northwest-1"; //设置区域
    };
    document.body.appendChild(script);
  },
  methods: {
    focusMax() {
      this.hoverMax = true;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    blurMax() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    focusRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = true;
      this.hoverClose = false;
    },
    blurRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    focusClose() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    handleClose() {
      this.fullscreen = false;
      $("#headUrl .el-dialog__body").css({
        height: "69%",
      });
      this.$emit("info", false);
      // this.$emit("clearList", []);
    },
    clickMax() {
      this.fullscreen = true;
      $("#headUrl .el-dialog__body").css({
        height: "calc(100% - 41px)",
      });
    },
    clickRecovery() {
      this.fullscreen = false;
      $("#headUrl .el-dialog__body").css({
        height: "69%",
      });
    },
    selectFile() {
      $("#File").click();
    },
    uploadFile(e) {
      console.log(e.target.files[0]);
      var file = e.target.files[0];
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (file) {
        let fileType2 = file.name.split(".");
        let fileType = fileType2[fileType2.length - 1];
        let timestamp = new Date().getTime();
        var params = {
          Key: file.name.split(".")[0] + timestamp + "." + fileType,
          ContentType: file.type,
          Body: file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            console.log(e);
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              _this.$emit("setHeadUrl", data.Location);
              _this.handleClose();
            }
          });
      }
    },
    selectNetwork() {
      this.handleClose();
      this.$emit("setNetwork", true);
    },
  },
};
</script>

<style>
#headUrl .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
  width: 30%;
}

#headUrl .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  margin-right: 0;
}

#headUrl .el-dialog__body {
  width: 100%;
  height: 30%;
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  padding: 0px;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}

#headUrl .el-dialog__footer {
  box-sizing: initial;
  padding: 16px 0px;
  width: 100%;
  height: 31px;
  background: #ffffff;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#headUrl .el-dialog__headerbtn {
  top: none;
}

#headUrl i:hover {
  color: #5cb6ff;
}

#headUrl .max {
  position: absolute;
  top: 0;
  right: 25px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#headUrl .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#headUrl .el-dialog.is-fullscreen {
  overflow: hidden;
}
</style>
