<template>
  <div>
    <el-dialog
      title="选择模板"
      :model-value="dialogVisible"
      width="52%"
      :destroy-on-close="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <span>
        <div
          :style="
            imageList.length != 0
              ? 'width: 100%;height: 640px;display: flex;flex-direction: row;'
              : 'width: 100%;'
          "
        >
          <div style="overflow-y: auto" class="formwork">
            <!-- <div style="width:100%;display:flex;align-items:center;">
                            <el-input placeholder="请输入内容" v-model="input" class="input-with-select">
                                <el-button slot="append" icon="el-icon-search" @click="searchImage"></el-button>
                            </el-input>
                        </div> -->

            <div
              style="
                display: flex;
                width: 100%;
                flex-flow: row wrap;
                justify-content: space-evenly;
                margin-top: 20px;
              "
            >
              <template v-for="(item, index) in imageList" :key="index">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 20px;
                  "
                >
                  <img
                    :src="item.url"
                    width="160"
                    height="120"
                    :style="
                      item.url != ''
                        ? 'margin-bottom:20px;'
                        : 'visibility: hidden;margin-bottom:20px;'
                    "
                    @click="goToAddImage"
                  />
                  <span style="font-size: 12px; font-weight: bold">{{
                    item.text
                  }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import img_1 from "../../assets/formwork/钉耙图.png";
import img_2 from "../../assets/formwork/蝴蝶图.png";
import img_3 from "../../assets/formwork/金字塔图.png";
import img_4 from "../../assets/formwork/流程分析图.png";
import img_5 from "../../assets/formwork/情节构成图（右）.png";
import img_6 from "../../assets/formwork/情节构成图（左）.png";
import img_7 from "../../assets/formwork/数据图（3）.png";
import img_8 from "../../assets/formwork/数据图（6).png";
import img_9 from "../../assets/formwork/水母图.png";
import img_10 from "../../assets/formwork/糖果图.png";
import img_11 from "../../assets/formwork/同心圆图.png";
import img_12 from "../../assets/formwork/韦恩三圆图.png";
import img_13 from "../../assets/formwork/韦恩图.png";
import img_14 from "../../assets/formwork/鱼骨图.png";
import img_15 from "../../assets/formwork/中心圆图.png";
import img_16 from "../../assets/formwork/钻石图.png";
import img_17 from "../../assets/formwork/坐标横轴图.png";
import img_18 from "../../assets/formwork/坐标轴图.png";
import img_19 from "../../assets/formwork/PMI_KWL图.png";
import img_20 from "../../assets/formwork/W图.png";
import img_21 from "../../assets/formwork/X图.png";
import img_22 from "../../assets/formwork/Y图.png";
import $ from "jquery";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
emits: ['info', 'setStyle'],
  data() {
    return {
      input: "",
      imageList: [
        {
          height: 500,
          url: img_1,
          width: 750,
          text: "钉耙图",
        },
        {
          height: 500,
          url: img_2,
          width: 750,
          text: "蝴蝶图",
        },
        {
          height: 500,
          url: img_3,
          width: 750,
          text: "金字塔图",
        },
        {
          height: 500,
          url: img_4,
          width: 750,
          text: "流程分析图",
        },
        {
          height: 500,
          url: img_5,
          width: 750,
          text: "情节构成图（右）",
        },
        {
          height: 500,
          url: img_6,
          width: 750,
          text: "情节构成图（左）",
        },
        {
          height: 500,
          url: img_7,
          width: 750,
          text: "数据图（3）",
        },
        {
          height: 500,
          url: img_8,
          width: 750,
          text: "数据图（6)",
        },
        {
          height: 500,
          url: img_9,
          width: 750,
          text: "水母图",
        },
        {
          height: 500,
          url: img_10,
          width: 750,
          text: "糖果图",
        },
        {
          height: 500,
          url: img_11,
          width: 750,
          text: "同心圆图",
        },
        {
          height: 500,
          url: img_12,
          width: 750,
          text: "韦恩三圆图",
        },
        {
          height: 500,
          url: img_13,
          width: 750,
          text: "韦恩图",
        },
        {
          height: 500,
          url: img_14,
          width: 750,
          text: "鱼骨图",
        },
        {
          height: 500,
          url: img_15,
          width: 750,
          text: "中心圆图",
        },
        {
          height: 500,
          url: img_16,
          width: 750,
          text: "钻石图",
        },
        {
          height: 500,
          url: img_17,
          width: 750,
          text: "坐标横轴图",
        },
        {
          height: 500,
          url: img_18,
          width: 750,
          text: "坐标轴图",
        },
        {
          height: 500,
          url: img_19,
          width: 750,
          text: "PMI_KWL图",
        },
        {
          height: 500,
          url: img_20,
          width: 750,
          text: "W图",
        },
        {
          height: 500,
          url: img_21,
          width: 750,
          text: "X图",
        },
        {
          height: 500,
          url: img_22,
          width: 750,
          text: "Y图",
        },
        {
          height: 500,
          url: "",
          width: 750,
          text: "",
        },
        {
          height: 500,
          url: "",
          width: 750,
          text: "",
        },
        {
          height: 500,
          url: "",
          width: 750,
          text: "",
        },
      ],
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          $(".el-dialog").css("width", "52%");
          $(".el-dialog").css({
            height: "auto",
          });
        });
      }
    },
  },
  created() {
    this.$nextTick(() => {
      $(".el-dialog").css("width", "52%");
      $(".el-dialog").css({
        height: "auto",
      });
    });
  },
  methods: {
    handleClose() {
      this.$emit("info", false);
      // this.$emit("clearList", []);
    },
    goToAddImage(e) {
      // console.log(e.target.currentSrc)
      this.$emit("setStyle", {
        sourceUrlList: [{ name: "", url: e.target.currentSrc }],
        radio: "imageList",
      });
      this.$emit("info", false);
    },
  },
};
</script>

<style>
.formwork::-webkit-scrollbar {
  width: 4px;
}

.formwork::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(#606266, 60%);
}

.formwork::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#606266, 30%);
}
</style>
