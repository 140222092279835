import EvaluationNode from "./EvaluationNode";

// 左上角ICON为消息的节点
class JobSubmissionNode extends EvaluationNode.view {
  getImageHref() {
    return require("@/assets/tools/暂无-作业提交.png");
  }
}

export default {
  type: "icon-job-submission",
  view: JobSubmissionNode,
  model: EvaluationNode.model,
};
