import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
// import { HtmlResize } from "@logicflow/extension";
import "../../../uml.css";
import $ from "jquery";
import { zhHansLanguage } from "../../../lang/zh-Hans.js";
import { zhHantLanguage } from "../../../lang/zh-Hant.js";
import { enLanguage } from "../../../lang/en.js";

class CardModel extends HtmlNodeModel {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.editable = false; // 禁止节点文本编辑
    // 设置节点宽高和鼠标移动多少距离后开始缩放节点
    const width =
      data.properties.nodeSize && data.properties.nodeSize.width
        ? data.properties.nodeSize.width
        : 100;
    const height =
      data.properties.nodeSize && data.properties.nodeSize.height
        ? data.properties.nodeSize.height
        : 100;
    // const height = 250
    this.width = width;
    this.height = height;
    this.minWidth = 100;
    this.minHeight = 100;
    this.gridSize = 1;
    const unable_to_connect_with_oneself = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.tip43
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.tip43
          : enLanguage.tip43,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_theme
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_theme
          : enLanguage.unable_to_connect_with_theme,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor);
        return targetNode.type != "icon-theme";
      },
    };
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_other
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_other
          : enLanguage.unable_to_connect_with_other,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id);
        let isConnect = false;
        for (let i = 0; i < edges.length; i++) {
          if (
            edges[i].sourceNodeId == sourceNode.id &&
            edges[i].targetNodeId == targetNode.id
          ) {
            isConnect = true;
            break;
          }
        }
        return isConnect == false;
      },
    };
    this.sourceRules.push(unable_to_connect_with_other);
    // 设置锚点
    // this.anchorsOffset = [
    //   [width / 2, 0],
    //   [0, height / 2],
    //   [-width / 2, 0],
    //   [0, -height / 2],
    // ]
  }
  setAttributes() {
    if (
        (this.properties.nodeSize &&
        this.properties.nodeSize.height &&
        parseInt(this.properties.nodeSize.height) == 100) ||
        (this.properties.nodeSize &&
        this.properties.nodeSize.width &&
        parseInt(this.properties.nodeSize.width) == 100)
    ) {
        this.width = 100;
        this.height = 100;
    }
  }
  getData() {
    const data = super.getData();
    let properties = data.properties;
    properties = {
      ...properties,
      nodeSize: {
        width: this.width,
        height: this.height,
      }
    }
    return {
      ...super.getData(),
      properties: properties
    }
  }
}
class CardNode extends HtmlNode {
  setHtml(rootEl) {
    const { id, properties } = this.props.model;
    const el = document.createElement("div");
    el.className = "uml-wrapper";
    var html = ``;
    if (
      properties.nodeSize &&
      properties.nodeSize.width &&
      properties.nodeSize.height
    ) {
      el.style = `width:${properties.nodeSize.width}px;height:${
        properties.nodeSize.height
      }px;display: flex;justify-content: center;align-items: center;background:none;border-radius: ${
        properties.nodeSize.width / 2
      }px;`;
      el.id = "div_" + id;
      html = `<div style="width:${properties.nodeSize.width}px;height:${
        properties.nodeSize.height
      }px;background:url(${
        properties.url
      }) no-repeat;background-size:cover;border-radius: ${
        properties.nodeSize.width / 2
      }px;">
        </div>
        `;
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = "";
      rootEl.appendChild(el);
      $("#div_" + id)
        .parent()
        .css("border-radius", properties.nodeSize.width / 2 + "px");
    } else {
      el.style =
        "width:100px;height:100px;display: flex;justify-content: center;align-items: center;background:none;border-radius: 50px;";
      el.id = "div_" + id;
      html = `<div style="width:100px;height:100px;background:url(${properties.url}) no-repeat;background-size:cover;border-radius: 50px;">
        </div>
        `;
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = "";
      rootEl.appendChild(el);
      $("#div_" + id)
        .parent()
        .css("border-radius", "50px");
    }
  }
}

export default {
  type: "icon-ai-npc",
  view: CardNode,
  model: CardModel,
};
