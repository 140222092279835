<template>
  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjI5OyYjMTc1
OyYjMTg4OyYjMjI5OyYjMTM1OyYjMTg2OyI+CjxwYXRoIGlkPSJWZWN0b3IgNDMgKFN0cm9rZSki
IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiA4QzIgNy43MjM4
NiAyLjIyMzg2IDcuNSAyLjUgNy41SDRDNC4yNzYxNCA3LjUgNC41IDcuNzIzODYgNC41IDhDNC41
IDguMjc2MTQgNC4yNzYxNCA4LjUgNCA4LjVIM1YxM0gxM1Y4LjVIMTJDMTEuNzIzOSA4LjUgMTEu
NSA4LjI3NjE0IDExLjUgOEMxMS41IDcuNzIzODYgMTEuNzIzOSA3LjUgMTIgNy41SDEzLjVDMTMu
Nzc2MSA3LjUgMTQgNy43MjM4NiAxNCA4VjEzLjVDMTQgMTMuNzc2MSAxMy43NzYxIDE0IDEzLjUg
MTRIMi41QzIuMjIzODYgMTQgMiAxMy43NzYxIDIgMTMuNVY4WiIgZmlsbD0iYmxhY2siIGZpbGwt
b3BhY2l0eT0iMC45Ii8+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMC44NzUgNS41TDggMkw1LjEy
NSA1LjVINy41VjExLjVDNy41IDExLjc3NjEgNy43MjM4NiAxMiA4IDEyQzguMjc2MTQgMTIgOC41
IDExLjc3NjEgOC41IDExLjVWNS41SDEwLjg3NVoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9
IjAuOSIvPgo8L2c+Cjwvc3ZnPgo="
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
