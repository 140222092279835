<template>
  <div>
    <el-dialog
      title="导入"
      :model-value="dialogVisible"
      width="20%"
      :destroy-on-close="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <span>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          "
        >
          <el-radio v-model="radio" value="1" label="1" style="margin-bottom: 0"
            >Xml文件</el-radio
          >
          <el-radio v-model="radio" value="2" label="2" style="margin-bottom: 0"
            >Json文件</el-radio
          >
        </div>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">关 闭</el-button>
          <el-button type="primary" @click="confirmImport">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
emits: ['info', 'saveGraph'],
  data() {
    return {
      radio: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("info", false);
    },
    confirmImport() {
      if (this.radio != "") {
        this.$emit("saveGraph", this.radio);
        this.radio = "";
        this.$emit("info", false);
      } else {
        this.$message({
          message: "请选择导入其中一种文件",
          offset: 400,
        });
      }
    },
  },
};
</script>

<style></style>
