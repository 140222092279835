// import Vue from 'vue'
// import Vuex from 'vuex'
// Vue.use(Vuex)
import { createStore } from "vuex";
const store = new createStore({
  state: {
    //state里面放置的是数据源
    remap: null,
    remarkerLayer: null,
    addressArr: [],
    group_member: [],
    cid: "",
    user: {
      userId: "1b406ce7-3b32-11ee-91d8-005056b86db5",
      userName: "harry",
    }, //当前用户
    group_active_id: "", //切换的当前小组
    group_title: "", //切换的当前小组名
    select_CSCLDataCount: [],
    select_CSCLDataByID: [], //用户的操作记录，res.data[1]
    group_member_active: [], //当前小组的在线用户
    group: [], //存储this.group的值
    groupUsers: [], //该小组/项目的所有成员，getCourseGroup2的res.data[1],
    creator: {}, //创建者，getCourseGroup2的res.data[0],
  },
  actions: {
    //Action 提交的是 mutation，而不是直接变更状态。Action 可以包含任意异步操作。
    //Action 函数接受一个与 store 实例具有相同方法和属性的 context 对象，因此你可以调用context .commit（）
    //提交一个 mutation，或者通过context .state 和 context .getters 来获取 state 和 getters
    changeRemapAction(ctx, MapValue, markerValue) {
      //接收dispatch方法中的触发事件的名称“change”，
      //第一个参数是上下文，能用他调用commit方法；
      //第二个参数是dispatch方法传过来的参数city
      ctx.commit("changeRemap", MapValue, markerValue); //通过commit 提交一个 mutation
    },
    set_lat(ctx, lat, lng, id) {
      ctx.commit("change_lat", lat, lng, id);
    },
  },
  mutations: {
    changeRemap(state, obj) {
      state.remap = obj.map;
      state.remarkerLayer = obj.markerLayer;
    },
    change_lat(state, obj) {
      if (state.addressArr.length == 0) {
        state.addressArr.push(obj);
        return;
      }
      state.addressArr.forEach((item) => {
        if (item.id != obj.id) {
          state.addressArr.push(obj);
        }
      });
    },
    set_group_member(state, value) {
      // state.group_member = value;
      let arr = Object.assign([], state.group_member, value);
      state.group_member = arr;
    },
    set_cid(state, value) {
      state.cid = value;
    },
    set_user(state, value) {
      // state.user = value;
      let data = Object.assign({}, state.user, value);
      state.user = data;
    },
    set_group_active_id(state, value) {
      state.group_active_id = value;
    },
    set_group_title(state, value) {
      state.group_title = value;
    },
    set_select_CSCLDataCount(state, value) {
      // state.select_CSCLDataCount = value;
      let arr = Object.assign([], state.select_CSCLDataCount, value);
      state.select_CSCLDataCount = arr;
    },
    set_select_CSCLDataByID(state, value) {
      // state.select_CSCLDataByID = value;
      let arr = Object.assign([], state.select_CSCLDataByID, value);
      state.select_CSCLDataByID = arr;
    },
    set_group_member_active(state, value) {
      // state.group_member_active = value;
      let arr = Object.assign([], state.group_member_active, value);
      state.group_member_active = arr;
    },
    set_group(state, value) {
      // state.group = value;
      let arr = Object.assign([], state.group, value);
      state.group = arr;
    },
    set_groupUsers(state, value) {
      // state.groupUsers = value;
      let arr = Object.assign([], state.groupUsers, value);
      state.groupUsers = arr;
    },
    set_creator(state, value) {
      // state.creator = value;
      let data = Object.assign({}, state.creator, value);
      state.creator = data;
    },
  },
});
export default store;
