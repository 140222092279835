<template>
  <div
    class="MessageDialog2"
    :style="bgColor ? `background:${bgColor}` : ''"
    @click="skip_card"
  >
    <el-icon size="16" class="xx" @click="close"><CircleClose /></el-icon>
    <div v-if="font">
      {{ font }}
    </div>
    <div v-else>
      {{ data.name }}{{ languageData.tip29 }}{{ data.length
      }}{{ languageData.tip30 }}
    </div>
  </div>
</template>

<script>
import LogicFlow from "@logicflow/core";
export default {
  name: "MessageDialog",
  props: {
    data: { type: Object, default: undefined },
    lf: LogicFlow,
    bgColor: { type: String, default: '' },
    font: { type: String, default: '' },
  },
emits: ['close'],
  data() {
    return {
      languageData: {},
    };
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    skip_card() {
      this.lf.focusOn({
        id: this.data.id,
      });
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
<style scoped>
.MessageDialog2 {
  width: 300px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  border-radius: 5px;
  border: #d8ed61;
  background: #f16d9e;
  text-align: center;
  box-shadow: 3px 4px 5px 2px;
}
.xx {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
</style>
