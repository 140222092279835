import { EllipseResize } from "@logicflow/extension";
import {
  getShapeStyleFuction,
  getTextStyleFunction,
} from "../getShapeStyleUtil";

// 负责任务
class UndertakeModel extends EllipseResize.model {
  initNodeData(data) {
    super.initNodeData(data);
    this.rx = 40;
    this.ry = 40;
  }

  setToBottom() {
    this.zIndex = 0;
  }

  getNodeStyle() {
    const style = super.getNodeStyle();
    style.fill = "#8CBBD7";
    style.stroke = "#8CBBD7";
    const properties = this.getProperties();
    return getShapeStyleFuction(style, properties);
  }

  getTextStyle() {
    const style = super.getTextStyle();
    style.color = "#FFFFFF";
    const properties = this.getProperties();
    return getTextStyleFunction(style, properties);
  }
}

export default {
  type: "pro-undertake-circle",
  view: EllipseResize.view,
  model: UndertakeModel,
};
