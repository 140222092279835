export const shortStyles = [
  {
    backgroundColor: "rgb(255, 255, 255)",
    borderWidth: "1px",
    borderColor: "rgb(42, 42, 42)",
  },
  {
    backgroundColor: "rgb(245, 245, 245)",
    borderWidth: "1px",
    borderColor: "rgb(102, 102, 102)",
  },
  {
    backgroundColor: "rgb(218, 232, 252)",
    borderWidth: "1px",
    borderColor: "rgb(108, 142, 191)",
  },
  {
    backgroundColor: "rgb(213, 232, 212)",
    borderWidth: "1px",
    borderColor: "rgb(130, 179, 102)",
  },
  {
    backgroundColor: "rgb(255, 230, 204)",
    borderWidth: "1px",
    borderColor: "rgb(215, 155, 0)",
  },
  {
    backgroundColor: "rgb(255, 242, 204)",
    borderWidth: "1px",
    borderColor: "rgb(214, 182, 86)",
  },
  {
    backgroundColor: "rgb(248, 206, 204)",
    borderWidth: "1px",
    borderColor: "rgb(184, 84, 80)",
  },
  {
    backgroundColor: "rgb(220, 210, 230)",
    borderWidth: "1px",
    borderColor: "rgb(150, 115, 166)",
  },
];

export const borderStyles = [
  {
    value: "solid",
  },
  {
    value: "dashed",
  },
  {
    value: "dotted",
  },
];

export const fontFamilies = [
  {
    value: "Arial",
  },
  {
    value: "Verdana",
  },
  {
    value: "Georgia",
  },
  {
    value: "Times New Roman",
  },
];
