<template>
  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjI5OyYjMTc2
OyYjMTQzOyYjMjI5OyYjMTU2OyYjMTc2OyYjMjI5OyYjMTU1OyYjMTkwOyI+CjxnIGlkPSJHcm91
cCA4OTQiPgo8cGF0aCBpZD0iVmVjdG9yIDExIChTdHJva2UpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQi
IGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIuNSA4QzIuNzc2MTQgOCAzIDguMjIzODYgMyA4LjVW
MTIuMDc0Mkw1LjU0MDEzIDEwLjkyNzJDNS42ODE0OSAxMC44NjM0IDUuODQ0NDcgMTAuODY4OCA1
Ljk4MTMgMTAuOTQxOEw5LjczMTEyIDEyLjk0MjlMMTMgMTEuNDYxMVY4LjVDMTMgOC4yMjM4NiAx
My4yMjM5IDggMTMuNSA4QzEzLjc3NjEgOCAxNCA4LjIyMzg2IDE0IDguNVYxMS43ODM0QzE0IDEx
Ljk3OTcgMTMuODg1MiAxMi4xNTc4IDEzLjcwNjQgMTIuMjM4OEw5LjkxOTU0IDEzLjk1NTRDOS43
NzgwNSAxNC4wMTk1IDkuNjE0NzcgMTQuMDE0MyA5LjQ3NzcxIDEzLjk0MTFMNS43Mjc1IDExLjkz
OThMMi43MDU3NyAxMy4zMDQzQzIuNTUxMDMgMTMuMzc0MiAyLjM3MTQxIDEzLjM2MDcgMi4yMjg3
OCAxMy4yNjg2QzIuMDg2MTUgMTMuMTc2NSAyIDEzLjAxODQgMiAxMi44NDg2VjguNUMyIDguMjIz
ODYgMi4yMjM4NiA4IDIuNSA4WiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC45Ii8+Cjxw
YXRoIGlkPSJVbmlvbiIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9
Ik04LjAwMDAxIDJDNi45MDM0MiAyIDYuMTIwODkgMi40NjQyNCA1LjYzMDUyIDMuMTMyMDdDNS4x
NTU0MiAzLjc3OTExIDQuOTc2NjYgNC41ODM5NiA0Ljk4NzM5IDUuMjcwNjhDNC45OTE1NSA1LjUz
NjMzIDUuMDgxNjggNS43NzQwOCA1LjIwMTg5IDUuOTY2NDJMNy41NzYgOS43NjVDNy42NjczNyA5
LjkxMTE5IDcuODI3NiAxMCA4IDEwQzguMTcyMzkgMTAgOC4zMzI2MyA5LjkxMTE5IDguNDI0IDku
NzY1TDEwLjc4NTIgNS45ODcxNEMxMC45MTggNS43NzQ2IDExLjAwOTYgNS41MTI5OCAxMS4wMDI2
IDUuMjI2MDZDMTAuOTg2MiA0LjU1NTk2IDEwLjgwOTMgMy43NjI1MiAxMC4zNDI2IDMuMTIyNTFD
OS44NTkyOSAyLjQ1OTU2IDkuMDkwODQgMiA4LjAwMDAxIDJaTTkgNS4wMDAwMkM5IDUuNTUyMyA4
LjU1MjI4IDYuMDAwMDIgOCA2LjAwMDAyQzcuNDQ3NzIgNi4wMDAwMiA3IDUuNTUyMyA3IDUuMDAw
MDJDNyA0LjQ0NzczIDcuNDQ3NzIgNC4wMDAwMiA4IDQuMDAwMDJDOC41NTIyOCA0LjAwMDAyIDkg
NC40NDc3MyA5IDUuMDAwMDJaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiLz4KPC9n
Pgo8L2c+Cjwvc3ZnPgo="
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
