<template>
    <div :style="sidebarWidth > 1600
        ? 'display:flex;flex-direction:row;'
        : 'display:flex;flex-direction:row;margin-top:10px;'
        ">
        <!-- 按钮区 -->
        <div v-if="!isFullscreen && !isProjection" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="isFullScreen" :title="languageData.fullScreen"
                @click="$_fullScreen">
                {{ languageData.fullScreen }}
            </el-button>
        </div>
        <div v-else-if="isFullscreen && !isProjection" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="fullScreen" :title="languageData.exitFullScreen"
                @click="$_fullScreen">
                {{ languageData.exitFullScreen }}
            </el-button>
        </div>
        <div v-if="!isProjection" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="danger" class="clear" :title="languageData.clear" @click="$_clearData">
                {{ languageData.clear }}
            </el-button>
        </div>
        <div style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="primary" class="run" :title="languageData.run" @click="$_runGraph">
                {{ languageData.run }}
            </el-button>
        </div>
        <div v-if="!isProjection && !isFullscreen" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="projection" :title="languageData.projection"
                @click="$_projection">
                {{ languageData.projection }}
            </el-button>
        </div>
        <div v-else-if="isProjection && isFullscreen" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="fullScreen" :title="languageData.exitProjection"
                @click="$_projection">
                {{ languageData.exitProjection }}
            </el-button>
        </div>

        <div v-if="isProjection && isExitPrevious" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="projection" :title="languageData.previousPage"
                @click="$_previous_projection">
                {{ languageData.previousPage }}
            </el-button>
        </div>
        <div v-if="isProjection && isExitNext" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="projection" :title="languageData.nextPage"
                @click="$_next_projection">
                {{ languageData.nextPage }}
            </el-button>
        </div>
        <div style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="loginout" style="
                background: var(--brand-normal, #3681fc);
                border-color: var(--brand-normal, #3681fc);
            " :title="languageData.dashboard" @click="$parent.handle_dashboard()">
                {{ languageData.dashboard }}
            </el-button>
        </div>
        <div style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="loginout" style="
                background: var(--brand-normal, #f00573);
                border-color: var(--brand-normal, #f00573);
            " :title="languageData.refresh" @click="handle_refresh">
                {{ languageData.refresh }}
            </el-button>
        </div>
        <div style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="loginout" :title="languageData.share3" @click="createShareNew">
                {{ languageData.share3 }}
            </el-button>
        </div>
        <div v-if="isExistError" style="
            display: flex;
            align-items: center;
            justify-content: center;
        ">
            <el-button size="small" type="info" class="loginout" :title="languageData.repair" @click="$_repairData">
                {{ languageData.repair }}
            </el-button>
        </div>
    </div>
    <RunResultDialog :dialog-visible="dialogVisible" :list="dataList" :source-list="sourceDataList"
        :is-home-work="isHomeWork" @info="getInfo" @setThemeId="setThemeId" @createShare="createShare"
        @createCourse="$_createCourse" @createProject="$_createProject" @createDemoList="createDemoList"
        @getNavigationList="getNavigationList" @getThemeName="getThemeName"></RunResultDialog>
    <DemonstrateDialog :show="Show" :dialog-visible="DemonstrateDialogVisible" :list="showDemoList"
        :theme-name="themeName" :navigation-list="navigationList" :is-projection="isProjection"
        :is-exit-previous="isExitPrevious" :is-exit-next="isExitNext" @getNavigationList="getNavigationList"
        @info="getDemonstrateInfo" @setSelectMulu="setSelectMulu"></DemonstrateDialog>

    <ShareDialog :dialog-visible="ShareDialogVisible" :theme-id="themeId" @info="getShare"></ShareDialog>

    <div id="way">
        <el-dialog :model-value="selectPreviousDialogVisible" width="30%" :show-close="false"
            :close-on-press-escape="false" :before-close="handlePreviousClose">
            <template #header>
                <span style="width: 100%; position: relative">
                    <span class="el-dialog__title">{{ languageData.tip19 }}</span>
                    <button type="button" class="close" style="top: 4px; right: 0">
                        <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16"
                            @mouseout="blurClose" @click="handlePreviousClose" />
                        <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
                    </button>
                </span>
            </template>
            <span style="
            display: flex;
            height: 100%;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          ">
                <template v-for="(item, index) in selectWayList" :key="index">
                    <el-radio v-model="radio" :value="index" :label="index"
                        style="margin-right: 8px; margin-bottom: 10px" :title="item.type == 'icon-card'
                            ? item.properties.title
                            : item.type == 'icon-select'
                                ? item.properties.selectTitle
                                : item.type == 'icon-thinking'
                                    ? item.properties.thinkingTitle
                                    : item.type == 'icon-AI' || item.type == 'icon-markdown'
                                        ? item.properties.topic
                                        : item.type == 'icon-stage'
                                            ? languageData.stageName + ': ' + item.properties.stageName
                                            : 'icon-task'
                                                ? languageData.taskName + ': ' + item.properties.taskTitle
                                                : item.type == 'icon-new-AI'
                                                    ? item.properties.topic
                                                    : ''
                            " @change="selectPreviousWay">
                        {{
                            item.type == "icon-card"
                                ? item.properties.title
                                : item.type == "icon-select"
                                    ? item.properties.selectTitle
                                    : item.type == "icon-thinking"
                                        ? item.properties.thinkingTitle
                                        : item.type == "icon-AI" || item.type == "icon-markdown"
                                            ? item.properties.topic
                                            : item.type == "icon-stage"
                                                ? languageData.stageName + ": " + item.properties.stageName
                                                : item.type == "icon-task"
                                                    ? languageData.taskName + ": " + item.properties.taskTitle
                                                    : item.type == "icon-new-AI"
                                                        ? item.properties.topic
                                                        : ""
                        }}</el-radio>
                </template>
            </span>
        </el-dialog>

        <el-dialog :model-value="selectNextDialogVisible" width="30%" :show-close="false" :close-on-press-escape="false"
            :before-close="handleNextClose">
            <template #header>
                <span style="width: 100%; position: relative">
                    <span class="el-dialog__title">{{ languageData.tip19 }}</span>
                    <button type="button" class="close" style="top: 4px; right: 0">
                        <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16"
                            @mouseout="blurClose" @click="handleNextClose" />
                        <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
                    </button>
                </span>
            </template>
            <span style="
            display: flex;
            height: 100%;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          ">
                <template v-for="(item, index) in selectWayList" :key="index">
                    <el-radio v-model="radio" :value="index" :label="index"
                        style="margin-right: 8px; margin-bottom: 10px" :title="item.type == 'icon-card'
                            ? item.properties.title
                            : item.type == 'icon-select'
                                ? item.properties.selectTitle
                                : item.type == 'icon-thinking'
                                    ? item.properties.thinkingTitle
                                    : item.type == 'icon-AI' || item.type == 'icon-markdown'
                                        ? item.properties.topic
                                        : item.type == 'icon-stage'
                                            ? languageData.stageName + ': ' + item.properties.stageName
                                            : 'icon-task'
                                                ? languageData.taskName + ': ' + item.properties.taskTitle
                                                : item.type == 'icon-new-AI'
                                                    ? item.properties.topic
                                                    : ''
                            " @change="selectNextWay">
                        {{
                            item.type == "icon-card"
                                ? item.properties.title
                                : item.type == "icon-select"
                                    ? item.properties.selectTitle
                                    : item.type == "icon-thinking"
                                        ? item.properties.thinkingTitle
                                        : item.type == "icon-AI" || item.type == "icon-markdown"
                                            ? item.properties.topic
                                            : item.type == "icon-stage"
                                                ? languageData.stageName + ": " + item.properties.stageName
                                                : item.type == "icon-task"
                                                    ? languageData.taskName + ": " + item.properties.taskTitle
                                                    : item.type == "icon-new-AI"
                                                        ? item.properties.topic
                                                        : ""
                        }}</el-radio>
                </template>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import LogicFlow from "@logicflow/core";
import screenfull from "screenfull";
import $ from "jquery";
import { ElLoading as Loading } from "element-plus";
import { v4 as uuidv4 } from "uuid";
import qs from "qs";
import RunResultDialog from "../dialog/RunResultDialog.vue";
import DemonstrateDialog from "../dialog/DemonstrateDialog.vue"
import ShareDialog from "../dialog/ShareDialog.vue"
import {
    deepFilter,
    convertToTreeData
} from "../../tools/utils";
export default {
    name: "ButtonToolbar",
    components: {
        RunResultDialog,
        DemonstrateDialog,
        ShareDialog
    },
    props: {
        lf: LogicFlow,
        group: { type: Array, default: undefined },
        sidebarWidth: {
            type: Number,
            default: 0
        }
    },
    emits: ["full"],
    data() {
        return {
            isFullscreen: false,
            isProjection: false,
            isExistError: false,
            isShowDemoList: false,
            selectNextDialogVisible: false,
            selectPreviousDialogVisible: false,
            DemonstrateDialogVisible: false,
            dialogVisible: false,
            currentProjection: [],
            isShare: false,
            ShareDialogVisible: false,
            isHomeWork: false,
            languageData: {},
            navigationList: [],
            dataList: [],
            sourceDataList: [],
            showDemoList: [],
            selectWayList: [],
            finalNodes: [],
            stageData: [],
            taskData: [],
            themeId: "",
            Show: false,
            themeName: '',
            isExitPrevious: true,
            isExitNext: true,
            radio: "",
            DemoLists: [],
            demoIndex: 0,
            hoverClose: false,
        }
    },
    mounted() {
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
            this.languageData = this.zhHansLanguage;
        } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
            this.languageData = this.zhHantLanguage;
        } else if (window.location.href.indexOf("cocorobo.com") != -1) {
            this.languageData = this.enLanguage;
        } else {
            this.languageData = this.zhHansLanguage;
        }
        let that = this;
        that.init();
        window.addEventListener("resize", function () {
            if (!that.isFullScreen() && that.isProjection) {
                that.isFullscreen = false;
                that.$emit("full", false);
            } else if (!that.isFullScreen()) {
                that.$nextTick(() => {
                    that.isFullscreen = false;
                    that.$emit("full", false);
                });
            }
        });
    },
    destroy() {
        if (screenfull.isEnabled) {
            screenfull.off("change", this.fullChange);
        }
    },
    methods: {
        focusClose() {
            this.hoverClose = true;
        },
        handlePreviousClose() {
            this.selectPreviousDialogVisible = false;
        },
        handleNextClose() {
            this.selectNextDialogVisible = false;
        },
        selectPreviousWay(e) {
            // const { transformModel } = this.lf.graphModel;
            // transformModel.focusOn(this.selectWayList[e].x, this.selectWayList[e].y, window.screen.width, window.screen.height);
            this.showDemoList = [this.selectWayList[e]];
            this.DemonstrateDialogVisible = true;
            let that = this;
            that.Show = false;
            setTimeout(function () {
                that.Show = true;
            }, 100);
            this.currentProjection = [this.selectWayList[e]];
            let node = this.lf.getNodeIncomingNode(this.currentProjection[0].id);
            if (node.length != 0) {
                this.isExitPrevious = true;
                this.isExitNext = true;
            } else {
                this.isExitPrevious = false;
                this.isExitNext = true;
            }
            this.selectPreviousDialogVisible = false;
            this.radio = "";
        },
        selectNextWay(e) {
            // const { transformModel } = this.lf.graphModel;
            // transformModel.focusOn(this.selectWayList[e].x, this.selectWayList[e].y, window.screen.width, window.screen.height);
            this.showDemoList = [this.selectWayList[e]];
            this.DemonstrateDialogVisible = true;
            let that = this;
            that.Show = false;
            setTimeout(function () {
                that.Show = true;
            }, 100);
            this.currentProjection = [this.selectWayList[e]];
            let node = this.lf.getNodeOutgoingNode(this.currentProjection[0].id);
            if (node.length != 0) {
                this.isExitPrevious = true;
                this.isExitNext = true;
            } else {
                this.isExitPrevious = true;
                this.isExitNext = false;
            }
            this.selectNextDialogVisible = false;
            this.radio = "";
        },
        getShare(val) {
            this.themeId = "";
            this.ShareDialogVisible = val;
            if (!this.isShare) {
                this.dialogVisible = !val;
            }
            if (this.isShare) {
                $(".diagram-sidebar").css("z-index", "1032")
            }
            this.isShare = false;
        },
        handle_refresh() {
            window.location.reload();
        },
        getDemonstrateInfo(value) {
            this.DemonstrateDialogVisible = value;
            if (!this.isShowDemoList) {
                $(".diagram-sidebar").css("z-index", "1032")
            }
            this.isProjection = false;
            this.isExitNext = false;
            this.isExitPrevious = false;
            this.isShowDemoList = false;
            this.DemoLists = [];
            this.demoIndex = 0;
            this.showDemoList = [];
        },
        createShareNew() {
            if (
                localStorage.getItem("roomId") &&
                localStorage.getItem("roomId") != null &&
                localStorage.getItem("roomId") != ""
            ) {
                $(".diagram-sidebar").css("z-index", "1029")
                this.themeId = "111";
                this.isShare = true;
                this.ShareDialogVisible = true;
            } else {
                this.$message({
                    message: this.languageData.tip111,
                    type: "warning",
                });
            }
        },
        /*
            异常修复事件
        */
        $_repairData() {
            let that = this;
            that
                .$confirm(that.languageData.errorTip1, that.languageData.tip2, {
                    confirmButtonText: that.languageData.confirm,
                    cancelButtonText: that.languageData.cancel,
                    type: "warning",
                })
                .then(() => {
                    let that = this;
                    let params =
                        window.location.href.indexOf("localhost") != -1 ||
                            window.location.href.indexOf("192.168") != -1
                            ? "mode=" +
                            [
                                "getcscldata",
                                localStorage.getItem("roomId"),
                                "d7300d31-8cf6-38dc-e295-2c71622b4df1",
                                "1cf9dc4b-d95f-11ea-af4c-52540005ab01",
                            ].join(",")
                            : "mode=" +
                            [
                                "getcscldata",
                                localStorage.getItem("roomId"),
                                window.parent.US.pageId,
                                window.parent.US.userInfo.userid,
                            ].join(",");
                    let loadingInstance = Loading.service({ fullscreen: true });
                    this.$ajax
                        .post("https://poll.cocorobo.cn", params)
                        .then((res) => {
                            let data = {};
                            localStorage.setItem("user", JSON.stringify(res.data[0][0].user));
                            that.$store.commit(
                                "set_group_member_active",
                                []
                            );
                            that.$store.commit(
                                "set_group_member_active",
                                res.data[0][0].user
                            );
                            try {
                                data = JSON.parse(res.data[0][0].data);
                            } catch {
                                data = res.data[0][0].data;
                            }
                            let newData = {
                                nodes: [],
                                edges: data.edges,
                                backgroundUrl: data.backgroundUrl,
                                userName:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                                userId:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                roomId: res.data[0][0].id,
                            };
                            let mindinfo = {
                                "us.cscl": [
                                    {
                                        sendId:
                                            window.location.href.indexOf("localhost") != -1 ||
                                                window.location.href.indexOf("192.168") != -1
                                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                                : window.parent.US.userInfo.userid, //发送人id
                                        receiveId: res.data[0][0].id, //文件id
                                        pageId:
                                            window.location.href.indexOf("localhost") != -1 ||
                                                window.location.href.indexOf("192.168") != -1
                                                ? "d7300d31-8cf6-38dc-e295-2c71622b4df1"
                                                : window.parent.US.pageId,
                                        type: "delete", //消息类型
                                        messageInfo: newData,
                                    },
                                ],
                            };
                            let params =
                                "mode=" +
                                [
                                    "send",
                                    encodeURIComponent(
                                        encodeURIComponent(JSON.stringify(mindinfo))
                                    ),
                                ].join(",");
                            this.$ajax
                                .post("https://poll.cocorobo.cn", params)
                                .then((res) => {
                                    console.log(res);
                                    setTimeout(() => {
                                        that.$nextTick(() => {
                                            // 以服务的方式调用的 Loading 需要异步关闭
                                            loadingInstance.close();
                                        });
                                        that.$message({
                                            type: "success",
                                            message: that.languageData.tip42,
                                        });
                                        window.location.reload();
                                    }, 1000);
                                })
                                .catch((err) => {
                                    console.error(err);
                                });
                        })
                        .catch((err) => {
                            console.error(err);
                            that.$message({
                                message: err,
                                type: "error",
                            });
                        });
                })
                .catch(() => {
                    // this.$message({
                    //   type: 'info',
                    //   message: '已取消删除'
                    // });
                });
        },
        setSelectMulu(val) {
            for (let i = 0; i < this.DemoLists.length; i++) {
                if (val == this.DemoLists[i].id) {
                    if (i == 0) {
                        this.isExitPrevious = false;
                        this.isExitNext = true;
                    } else if (i == this.DemoLists.length - 1) {
                        this.isExitPrevious = true;
                        this.isExitNext = false;
                    } else {
                        this.isExitPrevious = true;
                        this.isExitNext = true;
                    }
                    this.demoIndex = i;
                    this.showDemoList = [this.DemoLists[i]];
                    let that = this;
                    that.Show = false;
                    setTimeout(function () {
                        that.Show = true;
                    }, 100);
                    break;
                }
            }
        },
        $_previous_projection() {
            // this.lf.updateEditConfig({
            //   isSilentMode: true,
            // });
            if (!this.isShowDemoList) {
                let currentProjection = this.currentProjection;
                let previousNode = this.lf.getNodeIncomingNode(currentProjection[0].id);
                if (previousNode.length != 0) {
                    if (previousNode.length != 1) {
                        this.selectWayList = [];
                        this.selectPreviousDialogVisible = true;
                        for (let i = 0; i < previousNode.length; i++) {
                            let node = this.lf.getNodeModelById(previousNode[i].id).getData();
                            this.selectWayList.push(node);
                        }
                    } else {
                        let newNode = this.lf
                            .getNodeModelById(previousNode[0].id)
                            .getData();
                        this.currentProjection = [newNode];
                        // const { transformModel } = this.lf.graphModel;
                        // transformModel.focusOn(previousNode[0].x, previousNode[0].y, window.screen.width, window.screen.height);
                        this.showDemoList = [newNode];
                        this.DemonstrateDialogVisible = true;
                        let node = this.lf.getNodeIncomingNode(
                            this.currentProjection[0].id
                        );
                        if (node.length != 0) {
                            this.isExitPrevious = true;
                            this.isExitNext = true;
                        } else {
                            this.isExitPrevious = false;
                            this.isExitNext = true;
                        }
                        let that = this;
                        that.Show = false;
                        setTimeout(function () {
                            that.Show = true;
                        }, 100);
                    }
                }
            } else {
                this.DemonstrateDialogVisible = true;
                this.demoIndex = this.demoIndex - 1;
                if (this.demoIndex == 0) {
                    this.isExitPrevious = false;
                }
                this.isExitNext = true;
                this.showDemoList = [this.DemoLists[this.demoIndex]];
                let that = this;
                that.Show = false;
                setTimeout(function () {
                    that.Show = true;
                }, 100);
            }
        },
        $_next_projection() {
            // this.lf.updateEditConfig({
            //   isSilentMode: true,
            // });
            if (!this.isShowDemoList) {
                let currentProjection = this.currentProjection;
                let NextNode = this.lf.getNodeOutgoingNode(currentProjection[0].id);
                if (NextNode.length != 0) {
                    if (NextNode.length != 1) {
                        this.selectWayList = [];
                        this.selectNextDialogVisible = true;
                        for (let i = 0; i < NextNode.length; i++) {
                            let node = this.lf.getNodeModelById(NextNode[i].id).getData();
                            this.selectWayList.push(node);
                        }
                    } else {
                        let newNode = this.lf.getNodeModelById(NextNode[0].id).getData();
                        this.currentProjection = [newNode];
                        // const { transformModel } = this.lf.graphModel;
                        // transformModel.focusOn(NextNode[0].x, NextNode[0].y, window.screen.width, window.screen.height);
                        this.showDemoList = [newNode];
                        this.DemonstrateDialogVisible = true;
                        let node = this.lf.getNodeOutgoingNode(
                            this.currentProjection[0].id
                        );
                        if (node.length != 0) {
                            this.isExitPrevious = true;
                            this.isExitNext = true;
                        } else {
                            this.isExitPrevious = true;
                            this.isExitNext = false;
                        }
                        let that = this;
                        that.Show = false;
                        setTimeout(function () {
                            that.Show = true;
                        }, 100);
                    }
                }
            } else {
                this.demoIndex = this.demoIndex + 1;
                if (this.demoIndex == this.DemoLists.length - 1) {
                    this.isExitNext = false;
                }
                this.isExitPrevious = true;
                this.showDemoList = [this.DemoLists[this.demoIndex]];
                this.DemonstrateDialogVisible = true;
                let that = this;
                that.Show = false;
                setTimeout(function () {
                    that.Show = true;
                }, 100);
            }
        },
        getThemeName(val) {
            this.themeName = val;
        },
        createDemoList(val) {
            this.DemoLists = val;
            this.isProjection = true;
            this.isShowDemoList = true;
            this.isExitPrevious = false;
            this.isExitNext = true;
            this.showDemoList = [this.DemoLists[0]];
            this.DemonstrateDialogVisible = true;
            let that = this;
            that.Show = false;
            setTimeout(function () {
                that.Show = true;
            }, 100);
            this.$_fullScreen();
        },
        getNavigationList(val) {
            this.navigationList = val;
        },
        createShare(val) {
            this.dialogVisible = !val;
            this.isShare = false;
            this.ShareDialogVisible = val;
        },
        setThemeId(val) {
            this.themeId = val;
        },
        getInfo(value) {
            this.dialogVisible = value;
            $(".diagram-sidebar").css("z-index", "1032")
            this.dataList = [];
            this.sourceDataList = [];
        },
        //全屏
        fullele() {
            return (
                document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement ||
                document.mozFullScreenElement ||
                null
            );
        },
        //判断是否全屏
        isFullScreen() {
            return !!(document.webkitIsFullScreen || this.fullele());
        },
        init() {
            if (screenfull.isEnabled) {
                screenfull.on("change", this.fullChange);
            }
        },
        /*
          全屏事件或者退出全屏事件
        */
        $_fullScreen() {
            if (!screenfull.isEnabled) {
                this.$message({
                    message: "Your browser does not work",
                    type: "warning",
                });
                return false;
            }
            screenfull.toggle();
            this.$nextTick(() => {
                $(".diagram-sidebar").css("display", "flex");
            });
        },
        fullChange() {
            this.isFullscreen = screenfull.isFullscreen;
            if (screenfull.isFullscreen) {
                this.$nextTick(() => {
                    // $(".header_bg").css("display", "none");
                    $(".diagram-main").css("height", "100%");
                });
            } else {
                this.isShowDemoList = false;
                this.isProjection = false;
                this.navigationList = [];
                this.selectNextDialogVisible = false;
                this.selectPreviousDialogVisible = false;
                this.DemonstrateDialogVisible = false;
            }
        },
        /*
            清空事件
        */
        $_clearData() {
            let that = this;
            let data = that.lf.getGraphData();
            if (!that.lf.getEditConfig().isSilentMode) {
                if (data.nodes.length != 0 || data.edges.length != 0) {
                    that
                        .$confirm(that.languageData.tip1, that.languageData.tip2, {
                            confirmButtonText: that.languageData.confirm,
                            cancelButtonText: that.languageData.cancel,
                            type: "warning",
                        })
                        .then(() => {
                            let loadingInstance = Loading.service({ fullscreen: true });
                            that.$parent.sendUpdate([], [], "clean", 0);
                            that.$parent.add_Operation({}, "clean");
                            setTimeout(() => {
                                that.lf.graphModel.clearData();
                                // localStorage.removeItem("data");
                                let new_id = uuidv4();
                                let newData = {
                                    nodes: [
                                        {
                                            id: new_id,
                                            type: "icon-theme",
                                            x: 355,
                                            y: 350,
                                            properties: {
                                                isShowDelete: false,
                                                id: new_id,
                                                type: "icon-theme",
                                                topic: that.theme != "" ? that.theme : "起始",
                                            },
                                            zIndex: 1002,
                                        },
                                    ],
                                    edges: [],
                                    backgroundUrl: localStorage.getItem("background"),
                                };
                                that.$parent.sendUpdate(newData.nodes, [], "update", 0);
                                that.$parent.add_Operation(newData, "addNode");
                                that.isAutomatic = false;
                                that.lf.graphModel.addNode(newData.nodes[0]);
                                that.$nextTick(() => {
                                    // 以服务的方式调用的 Loading 需要异步关闭
                                    loadingInstance.close();
                                });
                                that.$message({
                                    type: "success",
                                    message: that.languageData.tip3,
                                });
                            }, 1000);
                        })
                        .catch(() => {
                            // this.$message({
                            //   type: 'info',
                            //   message: '已取消删除'
                            // });
                        });
                }
            }
        },
        /*
            运行事件
        */
        $_runGraph() {
            let data = this.lf.getGraphData();
            this.dataList = [];
            this.sourceDataList = [];
            $(".diagram-sidebar").css("z-index", "1029")
            let themeNum = 0;
            for (let i = 0; i < data.nodes.length; i++) {
                if (data.nodes[i].type == "icon-theme") {
                    themeNum = themeNum + 1;
                }
            }
            if (data.nodes.length != 0 && data.edges.length != 0) {
                let isRun = true;
                for (let i = 0; i < data.edges.length; i++) {
                    if (data.edges[i].type.indexOf("pro-red") != -1) {
                        isRun = false;
                        break;
                    }
                }

                if (isRun) {
                    // console.log(this.dataList)
                    this.sourceDataList.push(data);

                    // 获取所有节点的id列表
                    var nodeIdList = [];
                    for (let i = 0; i < data.nodes.length; i++) {
                        nodeIdList.push(data.nodes[i].id);
                    }
                    // 获取所有连线的目标节点id列表
                    var targetNodeIdList = [];
                    for (let i = 0; i < data.edges.length; i++) {
                        targetNodeIdList.push(data.edges[i].targetNodeId);
                    }
                    // 获取所有连线的源节点id列表
                    var sourceNodeIdList = [];
                    for (let i = 0; i < data.edges.length; i++) {
                        sourceNodeIdList.push(data.edges[i].sourceNodeId);
                    }
                    // 获取图上的根节点id
                    var firstNodeId = "";
                    // for (let i = 0; i < nodeIdList.length; i++) {
                    //   if (targetNodeIdList.includes(nodeIdList[i]) == false) {
                    //     firstNodeId = nodeIdList[i]
                    //   }
                    // }
                    for (let i = 0; i < data.nodes.length; i++) {
                        nodeIdList.push(data.nodes[i].id);
                        if (
                            data.nodes[i].type == "icon-start" ||
                            data.nodes[i].type == "icon-theme"
                        ) {
                            firstNodeId = data.nodes[i].id;
                        }
                    }
                    if (firstNodeId != "") {
                        // 获取图上的最后节点id列表
                        var lastNodeIdList = [];
                        for (let i = 0; i < nodeIdList.length; i++) {
                            if (sourceNodeIdList.includes(nodeIdList[i]) == false) {
                                lastNodeIdList.push(nodeIdList[i]);
                            }
                        }
                        // 获取自定义的所有连线列表
                        let finalEedges = [];
                        for (let i = 0; i < data.edges.length; i++) {
                            let edges = data.edges[i];
                            for (let j = 0; j < data.nodes.length; j++) {
                                if (edges.targetNodeId == data.nodes[j].id) {
                                    edges.targetNode = data.nodes[j];
                                    break;
                                }
                            }
                            finalEedges.push(edges);
                        }
                        const { nodes } = this.lf.getSelectElements();
                        this.activeNodes = [];
                        let list = [];
                        if (nodes.length != 0 && nodes[0].type == "icon-theme") {
                            firstNodeId = nodes[0].id;
                            for (let j = 0; j < data.nodes.length; j++) {
                                if (data.nodes[j].id == firstNodeId) {
                                    finalEedges.push({
                                        id: "0000",
                                        sourceNodeId: "0",
                                        targetNodeId: firstNodeId,
                                        targetNode: data.nodes[j],
                                    });
                                    break;
                                }
                            }
                            let tempList = deepFilter(
                                convertToTreeData(finalEedges, nodes[0].id),
                                nodeIdList,
                                lastNodeIdList
                            );
                            // console.log(tempList)
                            if (tempList.length == 0) {
                                this.$message.error(this.languageData.tip4);
                            }
                            // else if(tempList.length != 0 && tempList[0].children.length == 0) {
                            //   this.$message.error(this.languageData.tip4);
                            // }
                            else {
                                nodes[0].children = tempList;
                                list = nodes;
                                let finalDataList = { id: "0", children: list };
                                this.isHomeWork = false;
                                this.lf.clearSelectElements()
                                for (let i = 0; i < nodeIdList.length; i++) {
                                    this.lf.graphModel
                                        .getNodeModelById(nodeIdList[i])
                                        .setProperties({
                                            isShowDelete: false,
                                        });
                                }
                                this.dialogVisible = true;
                                this.dataList.push(finalDataList);
                            }
                        } else if (nodes.length == 0 && themeNum == 1) {
                            for (let j = 0; j < data.nodes.length; j++) {
                                if (data.nodes[j].id == firstNodeId) {
                                    finalEedges.push({
                                        id: "0000",
                                        sourceNodeId: "0",
                                        targetNodeId: firstNodeId,
                                        targetNode: data.nodes[j],
                                    });
                                    break;
                                }
                            }
                            // 处理数据获取树状结构数据
                            list = deepFilter(
                                convertToTreeData(finalEedges, "0"),
                                nodeIdList,
                                lastNodeIdList
                            );
                            if (list.length == 0) {
                                this.$message.error(this.languageData.tip4);
                            }
                            // else if (list.length != 0 && list[0].children.length == 0) {
                            //   this.$message.error(this.languageData.tip4);
                            // }
                            else {
                                let finalDataList = { id: "0", children: list };
                                this.isHomeWork = false;
                                this.lf.clearSelectElements()
                                for (let i = 0; i < nodeIdList.length; i++) {
                                    this.lf.graphModel
                                        .getNodeModelById(nodeIdList[i])
                                        .setProperties({
                                            isShowDelete: false,
                                        });
                                }
                                this.dialogVisible = true;
                                this.dataList.push(finalDataList);
                            }
                        } else if (
                            (nodes.length != 0 &&
                                nodes[0].type != "icon-theme" &&
                                nodes[0].type != "icon-task") ||
                            (nodes.length == 0 && themeNum > 1)
                        ) {
                            this.$message.error(this.languageData.tip22);
                        } else if (nodes.length != 0 && nodes[0].type == "icon-task") {
                            firstNodeId = nodes[0].id;
                            for (let j = 0; j < data.nodes.length; j++) {
                                if (data.nodes[j].id == firstNodeId) {
                                    finalEedges.push({
                                        id: "0000",
                                        sourceNodeId: "0",
                                        targetNodeId: firstNodeId,
                                        targetNode: data.nodes[j],
                                    });
                                    break;
                                }
                            }
                            // 处理数据获取树状结构数据
                            list = deepFilter(
                                convertToTreeData(finalEedges, nodes[0].id),
                                nodeIdList,
                                lastNodeIdList
                            );
                            // console.log(list)
                            if (list.length == 0) {
                                this.$message.error(this.languageData.tip4);
                            }
                            // else if (list.length != 0 && list[0].children.length == 0) {
                            //   this.$message.error(this.languageData.tip4);
                            // }
                            else {
                                nodes[0].children = list;
                                list = nodes;
                                let finalDataList = { id: "0", children: list };
                                this.isHomeWork = true;
                                this.lf.clearSelectElements()
                                for (let i = 0; i < nodeIdList.length; i++) {
                                    this.lf.graphModel
                                        .getNodeModelById(nodeIdList[i])
                                        .setProperties({
                                            isShowDelete: false,
                                        });
                                }
                                this.dialogVisible = true;
                                this.dataList.push(finalDataList);
                            }
                        }
                        // if (list[0].children.length == 0) {
                        //   this.$message.error('请从起始点出发，顺序连接所有需要运行的卡片内容');
                        // }
                        // else {
                        //   let finalDataList = { 'id': "0", 'children': list }
                        //   // console.log(finalDataList)

                        //   this.dialogVisible = true;
                        //   this.dataList.push(finalDataList);
                        //   // for (var i = 0; i < this.dataList[0].nodes.length; i++) {
                        //   //   if (this.dataList[0].nodes[i].type == "icon-select") {
                        //   //     this.selectIndex.push(i);
                        //   //   }
                        //   // }

                        //   // var listData;
                        //   // for (let j = 0; j < data.nodes.length; j++) {
                        //   //   if (data.nodes[j].id == firstNodeId) {
                        //   //     listData = data.nodes[j]
                        //   //     break;
                        //   //   }
                        //   // }
                        //   // console.log(listData)
                        // }
                    } else {
                        this.$message.error(this.languageData.tip5);
                    }
                } else {
                    this.$message.error(this.languageData.tip6);
                }
            } else if (data.nodes.length != 0 && data.edges.length == 0) {
                this.$message.error(this.languageData.tip4);
            }
            // this.download(this.filename, JSON.stringify(data))
            // console.log(this.dataList)
        },
        /*
            演示事件
        */
        $_projection() {
            // this.isShowDemoList = false
            $(".diagram-sidebar").css("z-index", "1029")
            if (!this.isProjection) {
                // this.top_menu = true
                // this.lf.updateEditConfig({
                //   isSilentMode: true,
                // });
                let data = this.lf.getGraphData();
                // console.log(data.edges)
                let themeNum = 0;
                for (let i = 0; i < data.nodes.length; i++) {
                    if (data.nodes[i].type == "icon-theme") {
                        themeNum = themeNum + 1;
                    }
                }
                if (data.nodes.length != 0 && data.edges.length != 0) {
                    let isRun = true;
                    for (let i = 0; i < data.edges.length; i++) {
                        if (data.edges[i].type.indexOf("pro-red") != -1) {
                            isRun = false;
                            break;
                        }
                    }

                    if (isRun) {
                        // console.log(this.dataList)

                        // 获取所有节点的id列表
                        var nodeIdList = [];
                        for (let i = 0; i < data.nodes.length; i++) {
                            nodeIdList.push(data.nodes[i].id);
                        }
                        // 获取所有连线的目标节点id列表
                        var targetNodeIdList = [];
                        for (let i = 0; i < data.edges.length; i++) {
                            targetNodeIdList.push(data.edges[i].targetNodeId);
                        }
                        // 获取所有连线的源节点id列表
                        var sourceNodeIdList = [];
                        for (let i = 0; i < data.edges.length; i++) {
                            sourceNodeIdList.push(data.edges[i].sourceNodeId);
                        }
                        // 获取图上的根节点id
                        var firstNodeId = "";
                        // for (let i = 0; i < nodeIdList.length; i++) {
                        //   if (targetNodeIdList.includes(nodeIdList[i]) == false) {
                        //     firstNodeId = nodeIdList[i]
                        //   }
                        // }
                        for (let i = 0; i < data.nodes.length; i++) {
                            nodeIdList.push(data.nodes[i].id);
                            if (
                                data.nodes[i].type == "icon-start" ||
                                data.nodes[i].type == "icon-theme"
                            ) {
                                firstNodeId = data.nodes[i].id;
                            }
                        }
                        if (firstNodeId != "") {
                            // 获取图上的最后节点id列表
                            var lastNodeIdList = [];
                            for (let i = 0; i < nodeIdList.length; i++) {
                                if (sourceNodeIdList.includes(nodeIdList[i]) == false) {
                                    lastNodeIdList.push(nodeIdList[i]);
                                }
                            }
                            // 获取自定义的所有连线列表
                            let finalEedges = [];
                            for (let i = 0; i < data.edges.length; i++) {
                                let edges = data.edges[i];
                                for (let j = 0; j < data.nodes.length; j++) {
                                    if (edges.targetNodeId == data.nodes[j].id) {
                                        edges.targetNode = data.nodes[j];
                                        break;
                                    }
                                }
                                finalEedges.push(edges);
                            }
                            for (let j = 0; j < data.nodes.length; j++) {
                                if (data.nodes[j].id == firstNodeId) {
                                    finalEedges.push({
                                        id: "0000",
                                        sourceNodeId: "0",
                                        targetNodeId: firstNodeId,
                                        targetNode: data.nodes[j],
                                    });
                                    break;
                                }
                            }
                            const { nodes } = this.lf.getSelectElements();
                            this.activeNodes = [];
                            let list = [];
                            this.isChangeRate = true;
                            if (nodes.length != 0) {
                                let tempList = deepFilter(
                                    convertToTreeData(finalEedges, nodes[0].id),
                                    nodeIdList,
                                    lastNodeIdList
                                );
                                nodes[0].children = tempList;
                                list = nodes;
                                // this.$emit("full", true)
                                let finalDataList = { id: "0", children: list };
                                // const { transformModel } = this.lf.graphModel;
                                // transformModel.zoom(4, [finalDataList.children[0].x, finalDataList.children[0].y])
                                // transformModel.focusOn(finalDataList.children[0].x, finalDataList.children[0].y, window.screen.width, window.screen.height);
                                this.currentProjection = finalDataList.children;
                                this.isExitPrevious = true;
                                this.isExitNext = true;
                                let previousNode = this.lf.getNodeIncomingNode(
                                    this.currentProjection[0].id
                                );
                                if (previousNode.length == 0) {
                                    this.isExitPrevious = false;
                                }
                                let NextNode = this.lf.getNodeOutgoingNode(
                                    this.currentProjection[0].id
                                );
                                if (NextNode.length == 0) {
                                    this.isExitNext = false;
                                }
                                this.lf.clearSelectElements();
                                for (let i = 0; i < nodeIdList.length; i++) {
                                    this.lf.graphModel
                                        .getNodeModelById(nodeIdList[i])
                                        .setProperties({
                                            isShowDelete: false,
                                        });
                                }
                                this.isProjection = true;
                                // this.$nextTick(() => {
                                //   // $(".header_bg").css("display", "none");
                                //   $(".diagram-sidebar").css("display", "none");
                                //   $(".diagram-main").css("height", "100%");
                                // })
                                this.showDemoList = [finalDataList.children[0]];
                                this.DemonstrateDialogVisible = true;
                                let that = this;
                                that.Show = false;
                                setTimeout(function () {
                                    that.Show = true;
                                }, 100);
                                this.$_fullScreen();
                            } else if (nodes.length == 0 && themeNum == 1) {
                                // 处理数据获取树状结构数据
                                list = deepFilter(
                                    convertToTreeData(finalEedges, "0"),
                                    nodeIdList,
                                    lastNodeIdList
                                );
                                console.log("list", list);
                                if (list[0].children.length == 0) {
                                    this.$message.error(this.languageData.tip7);
                                } else {
                                    let finalDataList = { id: "0", children: list };
                                    // this.$emit("full", true)
                                    // const { transformModel } = this.lf.graphModel;
                                    // transformModel.zoom(4, [finalDataList.children[0].x, finalDataList.children[0].y])
                                    // transformModel.focusOn(finalDataList.children[0].x, finalDataList.children[0].y, window.screen.width, window.screen.height);
                                    this.currentProjection = finalDataList.children;
                                    this.isExitPrevious = true;
                                    this.isExitNext = true;
                                    let previousNode = this.lf.getNodeIncomingNode(
                                        this.currentProjection[0].id
                                    );
                                    if (previousNode.length == 0) {
                                        this.isExitPrevious = false;
                                    }
                                    let NextNode = this.lf.getNodeOutgoingNode(
                                        this.currentProjection[0].id
                                    );
                                    if (NextNode.length == 0) {
                                        this.isExitNext = false;
                                    }
                                    this.lf.clearSelectElements();
                                    for (let i = 0; i < nodeIdList.length; i++) {
                                        this.lf.graphModel
                                            .getNodeModelById(nodeIdList[i])
                                            .setProperties({
                                                isShowDelete: false,
                                            });
                                    }
                                    this.isProjection = true;
                                    // this.$nextTick(() => {
                                    //   // $(".header_bg").css("display", "none");
                                    //   $(".diagram-sidebar").css("display", "none");
                                    //   $(".diagram-main").css("height", "100%");
                                    // })
                                    this.showDemoList = [finalDataList.children[0]];
                                    this.DemonstrateDialogVisible = true;
                                    let that = this;
                                    that.Show = false;
                                    setTimeout(function () {
                                        that.Show = true;
                                    }, 100);
                                    this.$_fullScreen();
                                }
                            }
                            else {
                                this.$message.error(this.languageData.tip125);
                            }
                            // if (list[0].children.length == 0) {
                            //   this.$message.error('请从起始点出发，顺序连接所有需要投影的卡片内容');
                            // }
                            // else {
                            //   let finalDataList = { 'id': "0", 'children': list }
                            //   const { transformModel, width, height } = this.lf.graphModel;
                            //   transformModel.zoom(3)
                            //   transformModel.focusOn(finalDataList.children[0].x, finalDataList.children[0].y, width, height);
                            //   this.currentProjection = finalDataList.children;
                            //   this.isExitPrevious = true
                            //   this.isExitNext = true
                            //   let previousNode = this.lf.getNodeIncomingNode(this.currentProjection[0].id)
                            //   if (previousNode.length == 0) {
                            //     this.isExitPrevious = false
                            //   }
                            //   let NextNode = this.lf.getNodeOutgoingNode(this.currentProjection[0].id)
                            //   if (NextNode.length == 0) {
                            //     this.isExitNext = false
                            //   }
                            //   this.lf.clearSelectElements()
                            // }
                        } else {
                            this.$message.error(this.languageData.tip5);
                            // this.lf.updateEditConfig({
                            //   isSilentMode: false,
                            // });
                        }
                    } else {
                        this.$message.error(this.languageData.tip8);
                        // this.lf.updateEditConfig({
                        //   isSilentMode: false,
                        // });
                    }
                }
            } else {
                // this.$nextTick(() => {
                //   $(".header_bg").css("display", "flex");
                //   $(".diagram-sidebar").css("display", "flex");
                //   $(".diagram-main").css("height", "94%");
                // })
                // this.lf.updateEditConfig({
                //   isSilentMode: false,
                // });
                clearTimeout(localStorage.top_menu);
                // this.top_menu = false
                this.isProjection = false;
                this.selectNextDialogVisible = false;
                this.selectPreviousDialogVisible = false;
                this.isChangeRate = false;
                // this.lf.resetZoom()
                // this.lf.resetTranslate()
                this.$_fullScreen();
            }
        },
        /*
            获取只有一条路径的所有节点列表
        */
        getWayData(data) {
            let Data = Object.values(data);
            return Data.filter((item) => {
                if (item.children.length == 1) {
                    this.finalNodes.push(item.children[0]);
                    this.getWayData(item.children);
                }
                return item.children.length == 1;
            });
        },
        /*
         获取所有阶段节点
        */
        getStageData(data) {
            let Data = Object.values(data);
            return Data.filter((item) => {
                if (item.type == "icon-stage") {
                    this.stageData.push(item);
                }
                if (item.children.length != 0) {
                    this.getStageData(item.children);
                }
                return item.type == "icon-stage";
            });
        },
        /*
          获取所有任务节点
        */
        getTaskData(data) {
            let Data = Object.values(data);
            return Data.filter((item) => {
                if (item.type == "icon-task") {
                    this.taskData.push(item);
                }
                if (item.children.length != 0) {
                    this.getTaskData(item.children);
                }
                return item.type == "icon-task";
            });
        },
        /*
          判断双路径型阶段课程连线是否合理
        */
        getSomeStageData(data, id) {
            let Data = Object.values(data);
            return Data.some((item) => {
                if (item.id != id && item.type != "icon-stage") {
                    this.finalNodes.push(item);
                    if (item.children.length != 0) {
                        this.getSomeStageData(item.children, id);
                    }
                }
                return item.type == "icon-stage";
            });
        },
        getSomeTaskData(data, id) {
            let Data = Object.values(data);
            return Data.some((item) => {
                if (item.id != id && item.type != "icon-task") {
                    this.finalNodes.push(item);
                    if (item.children.length != 0) {
                        this.getSomeTaskData(item.children, id);
                    }
                }
                return item.type == "icon-task";
            });
        },
        isTaskReasonable(data) {
            let Data = Object.values(data);
            return Data.filter((item) => {
                if (item.type == "icon-task") {
                    return true;
                } else {
                    this.isTaskReasonable(item.children);
                }
            });
        },
        // isStageReasonable(data) {
        //   let Data = Object.values(data);
        //   return Data.filter(item => {
        //     if (item.type == "icon-stage") {
        //       return true
        //     }
        //     else {
        //       this.isStageReasonable(item.children)
        //     }
        //   })
        // },
        /* 
          生成课程中任务的json
        */
        getTaskJson(taskJson) {
            for (let i = 0; i < this.finalNodes.length; i++) {
                if (this.finalNodes[i].type == "icon-task") {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolText: "",
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        isFold2: true,
                        progress: 100,
                        proVisible: false,
                        isFinishSize: "0.15",
                        isAllSize: "0.15",
                        proVisible2: false,
                    });
                }
                if (this.finalNodes[i].properties.radio == "imageList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.imageList[0].name
                            ? this.finalNodes[i].properties.imageList[0].name
                            : this.finalNodes[i].properties.imageList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.imageList[0].url,
                        type: 13,
                    });
                }
                if (this.finalNodes[i].properties.radio == "videoList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.videoList[0].name
                            ? this.finalNodes[i].properties.videoList[0].name
                            : this.finalNodes[i].properties.videoList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.videoList[0].url,
                        type: 2,
                    });
                }
                if (this.finalNodes[i].properties.radio == "iframeUrl") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: "链接",
                        title: "",
                        url: this.finalNodes[i].properties.iframeUrl,
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "embedCode") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: "链接",
                        title: "嵌入代码",
                        url: this.finalNodes[i].properties.embedCode,
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "audioList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.audioList[0].name
                            ? this.finalNodes[i].properties.audioList[0].name
                            : this.finalNodes[i].properties.audioList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.audioList[0].url,
                        type: 12,
                    });
                }
                if (this.finalNodes[i].properties.radio == "fileList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.fileList[0].name
                            ? this.finalNodes[i].properties.fileList[0].name
                            : this.finalNodes[i].properties.fileList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.fileList[0].url,
                        type: 3,
                    });
                }
                if (this.finalNodes[i].properties.radio == "content") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.title,
                        url: this.finalNodes[i].properties.content,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-markdown" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic,
                        url: this.finalNodes[i].properties.aiContent,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic,
                        url: this.finalNodes[i].properties.aiContent,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic
                            ? this.finalNodes[i].properties.topic
                            : this.finalNodes[i].properties.imgResult.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.imgResult,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic,
                        url: this.finalNodes[i].properties.aiContent,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic
                            ? this.finalNodes[i].properties.topic
                            : this.finalNodes[i].properties.imgResult.split("/pdf/")[1],
                        url: this.finalNodes[i].properties.imgResult,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "audio"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.audioList[0].name
                            ? this.finalNodes[i].properties.audioList[0].name
                            : this.finalNodes[i].properties.audioList[0].url.split(
                                "/pdf/"
                            )[1],
                        url: this.finalNodes[i].properties.audioList[0].url,
                        type: 12,
                    });
                }
                if (this.finalNodes[i].type == "icon-thinking") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.thinkingContent
                            ? this.finalNodes[i].properties.thinkingContent.split(
                                ".com.cn/"
                            )[1]
                            : "e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
                        url: this.finalNodes[i].properties.thinkingContent
                            ? this.finalNodes[i].properties.thinkingContent
                            : "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-whiteboard" &&
                    this.finalNodes[i].properties.whiteboardContent
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.whiteboardContent.split(
                            ".com.cn/"
                        )[1],
                        url: this.finalNodes[i].properties.whiteboardContent,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-map" &&
                    this.finalNodes[i].properties.map_url
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.map_url.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.map_url,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-camera" &&
                    this.finalNodes[i].properties.src
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.src.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.src,
                        type: 13,
                    });
                }
            }
            return taskJson;
        },
        /*
          发送生成任务课程请求
        */
        sendCreateTaskRequest(title, chaptersData, loadingInstance) {
            let that = this;
            let currentUserId =
                window.location.href.indexOf("localhost") != -1 ||
                    window.location.href.indexOf("192.168") != -1
                    ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                    : window.parent.US.userInfo.userid;
            let ateacher = "";
            for (let i = 0; i < that.group.length; i++) {
                if (that.group[i].id == that.active_group_id) {
                    for (let j = 0; j < that.group[i].group_member.length; j++) {
                        if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j != that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid + ",";
                        } else if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j == that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid;
                        }
                    }
                }
            }
            let params = [
                {
                    uid:
                        window.location.href.indexOf("localhost") != -1 ||
                            window.location.href.indexOf("192.168") != -1
                            ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                            : window.parent.US.userInfo.userid,
                    title: title,
                    brief: "",
                    cover: JSON.stringify([
                        {
                            name: "noBanner.jpg",
                            url: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/wu1676621845022.png",
                            uid: 1656409780264,
                            status: "success",
                        },
                    ]),
                    evaId: "",
                    astudent: "",
                    see: 0,
                    chapters: JSON.stringify(chaptersData),
                    template: "",
                    courseType: JSON.stringify([]),
                    ateacher: ateacher,
                    inviteCode: JSON.stringify([]),
                },
            ];
            this.$ajax
                .post(
                    "https://pbl.cocorobo.cn/api/pbl/addWorkNew2E",
                    qs.stringify(params)
                )
                .then((res) => {
                    console.log(res.data);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    that.$message({
                        message: that.languageData.tip23,
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    that.$message({
                        message: err,
                        type: "error",
                    });
                });
        },
        /* 
          生成直线型阶段课程的json
        */
        getStageJson() {
            let taskJson = [];
            let chaptersData = [];
            for (let i = 0; i < this.finalNodes.length; i++) {
                if (this.finalNodes[i].type == "icon-stage") {
                    if (taskJson.length != 0) {
                        chaptersData[chaptersData.length - 1].chapterInfo[0].taskJson =
                            taskJson;
                        taskJson = [];
                    }
                    chaptersData.push({
                        dyName: this.finalNodes[i].properties.stageName,
                        chapterInfo: [
                            {
                                isread: false,
                                chapterid: uuidv4(),
                                title: "",
                                courseName: "",
                                taskJson: [],
                                itemCount: 1,
                                fileList1: [],
                                video: [],
                                testData: [],
                                pData: [],
                                templateArray: [],
                            },
                        ],
                    });
                }
                if (this.finalNodes[i].type == "icon-task") {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolText: "",
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        isFold2: true,
                        progress: 100,
                        proVisible: false,
                        isFinishSize: "0.15",
                        isAllSize: "0.15",
                        proVisible2: false,
                    });
                }
                if (this.finalNodes[i].properties.radio == "imageList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.imageList[0].name
                            ? this.finalNodes[i].properties.imageList[0].name
                            : this.finalNodes[i].properties.imageList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.imageList[0].url,
                        type: 13,
                    });
                }
                if (this.finalNodes[i].properties.radio == "videoList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.videoList[0].name
                            ? this.finalNodes[i].properties.videoList[0].name
                            : this.finalNodes[i].properties.videoList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.videoList[0].url,
                        type: 2,
                    });
                }
                if (this.finalNodes[i].properties.radio == "iframeUrl") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: "链接",
                        title: "",
                        url: this.finalNodes[i].properties.iframeUrl,
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "embedCode") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: "链接",
                        title: "嵌入代码",
                        url: this.finalNodes[i].properties.embedCode,
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "audioList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.audioList[0].name
                            ? this.finalNodes[i].properties.audioList[0].name
                            : this.finalNodes[i].properties.audioList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.audioList[0].url,
                        type: 12,
                    });
                }
                if (this.finalNodes[i].properties.radio == "fileList") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.fileList[0].name
                            ? this.finalNodes[i].properties.fileList[0].name
                            : this.finalNodes[i].properties.fileList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.fileList[0].url,
                        type: 3,
                    });
                }
                if (this.finalNodes[i].properties.radio == "content") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.title,
                        url: this.finalNodes[i].properties.content,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-markdown" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic,
                        url: this.finalNodes[i].properties.aiContent,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic,
                        url: this.finalNodes[i].properties.aiContent,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic
                            ? this.finalNodes[i].properties.topic
                            : this.finalNodes[i].properties.imgResult.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.imgResult,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic,
                        url: this.finalNodes[i].properties.aiContent,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.topic
                            ? this.finalNodes[i].properties.topic
                            : this.finalNodes[i].properties.imgResult.split("/pdf/")[1],
                        url: this.finalNodes[i].properties.imgResult,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "audio"
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.audioList[0].name
                            ? this.finalNodes[i].properties.audioList[0].name
                            : this.finalNodes[i].properties.audioList[0].url.split(
                                "/pdf/"
                            )[1],
                        url: this.finalNodes[i].properties.audioList[0].url,
                        type: 12,
                    });
                }
                if (this.finalNodes[i].type == "icon-thinking") {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.thinkingContent
                            ? this.finalNodes[i].properties.thinkingContent.split(
                                ".com.cn/"
                            )[1]
                            : "e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
                        url: this.finalNodes[i].properties.thinkingContent
                            ? this.finalNodes[i].properties.thinkingContent
                            : "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-whiteboard" &&
                    this.finalNodes[i].properties.whiteboardContent
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.whiteboardContent.split(
                            ".com.cn/"
                        )[1],
                        url: this.finalNodes[i].properties.whiteboardContent,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-map" &&
                    this.finalNodes[i].properties.map_url
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.map_url.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.map_url,
                        type: 13,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-camera" &&
                    this.finalNodes[i].properties.src
                ) {
                    taskJson[taskJson.length - 1].chapterData.push({
                        name: this.finalNodes[i].properties.src.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.src,
                        type: 13,
                    });
                }
                if (i == this.finalNodes.length - 1) {
                    chaptersData[chaptersData.length - 1].chapterInfo[0].taskJson =
                        taskJson;
                }
            }
            return chaptersData;
        },
        formatDate(date, fmt) {
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    (date.getFullYear() + "").substr(4 - RegExp.$1.length)
                );
            }
            let o = {
                "M+": date.getMonth() + 1,
                "d+": date.getDate(),
                "h+": date.getHours(),
                "m+": date.getMinutes(),
                "s+": date.getSeconds(),
            };
            for (let k in o) {
                if (new RegExp(`(${k})`).test(fmt)) {
                    let str = o[k] + "";
                    fmt = fmt.replace(
                        RegExp.$1,
                        RegExp.$1.length === 1 ? str : this.padLeftZero(str)
                    );
                }
            }
            return fmt;
        },
        padLeftZero(str) {
            return ("00" + str).substr(str.length);
        },
        /*
          发送生成阶段课程请求
        */
        sendCreateStageRequest(title, chaptersData, loadingInstance) {
            let that = this;
            let currentUserId =
                window.location.href.indexOf("localhost") != -1 ||
                    window.location.href.indexOf("192.168") != -1
                    ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                    : window.parent.US.userInfo.userid;
            let ateacher = "";
            for (let i = 0; i < that.group.length; i++) {
                if (that.group[i].id == that.active_group_id) {
                    for (let j = 0; j < that.group[i].group_member.length; j++) {
                        if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j != that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid + ",";
                        } else if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j == that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid;
                        }
                    }
                }
            }
            let params = [
                {
                    uid:
                        window.location.href.indexOf("localhost") != -1 ||
                            window.location.href.indexOf("192.168") != -1
                            ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                            : window.parent.US.userInfo.userid,
                    title: title,
                    brief: "",
                    cover: JSON.stringify([
                        {
                            name: "noBanner.jpg",
                            url: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/wu1676621845022.png",
                            uid: 1656409780264,
                            status: "success",
                        },
                    ]),
                    evaId: "",
                    astudent: "",
                    see: 0,
                    chapters: JSON.stringify(chaptersData),
                    template: "",
                    courseType: JSON.stringify([]),
                    ateacher: ateacher,
                    inviteCode: JSON.stringify([]),
                },
            ];
            // console.log(qs.stringify(params))
            this.$ajax
                .post(
                    "https://pbl.cocorobo.cn/api/pbl/addWorkNew2",
                    qs.stringify(params)
                )
                .then((res) => {
                    console.log(res.data);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    that.$message({
                        message: that.languageData.tip23,
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    that.$message({
                        message: err,
                        type: "error",
                    });
                });
        },
        /*
          创建课程
        */
        $_createCourse() {
            let data = this.lf.getGraphData();
            let courseType = "";

            let title = "";
            let themeNum = 0;
            for (let i = 0; i < data.nodes.length; i++) {
                if (data.nodes[i].type == "icon-theme") {
                    themeNum = themeNum + 1;
                }
            }

            for (let i = 0; i < data.nodes.length; i++) {
                if (data.nodes[i].type == "icon-theme") {
                    title = data.nodes[i].properties.topic;
                    break;
                }
            }
            // 获取所有节点的id列表
            var nodeIdList = [];
            for (let i = 0; i < data.nodes.length; i++) {
                nodeIdList.push(data.nodes[i].id);
            }
            // 获取所有连线的目标节点id列表
            var targetNodeIdList = [];
            for (let i = 0; i < data.edges.length; i++) {
                targetNodeIdList.push(data.edges[i].targetNodeId);
            }
            // 获取所有连线的源节点id列表
            var sourceNodeIdList = [];
            for (let i = 0; i < data.edges.length; i++) {
                sourceNodeIdList.push(data.edges[i].sourceNodeId);
            }
            // 获取图上的根节点id
            var firstNodeId = "";
            for (let i = 0; i < data.nodes.length; i++) {
                nodeIdList.push(data.nodes[i].id);
                if (
                    data.nodes[i].type == "icon-start" ||
                    data.nodes[i].type == "icon-theme"
                ) {
                    firstNodeId = data.nodes[i].id;
                }
            }
            if (firstNodeId != "") {
                // 获取图上的最后节点id列表
                var lastNodeIdList = [];
                for (let i = 0; i < nodeIdList.length; i++) {
                    if (sourceNodeIdList.includes(nodeIdList[i]) == false) {
                        lastNodeIdList.push(nodeIdList[i]);
                    }
                }
                // 获取自定义的所有连线列表
                let finalEedges = [];
                for (let i = 0; i < data.edges.length; i++) {
                    let edges = data.edges[i];
                    for (let j = 0; j < data.nodes.length; j++) {
                        if (edges.targetNodeId == data.nodes[j].id) {
                            edges.targetNode = data.nodes[j];
                            break;
                        }
                    }
                    finalEedges.push(edges);
                }
                for (let j = 0; j < data.nodes.length; j++) {
                    if (data.nodes[j].id == firstNodeId) {
                        finalEedges.push({
                            id: "0000",
                            sourceNodeId: "0",
                            targetNodeId: firstNodeId,
                            targetNode: data.nodes[j],
                        });
                        break;
                    }
                }
                const { nodes } = this.lf.getSelectElements();
                this.activeNodes = [];
                let list = [];
                if (nodes.length != 0) {
                    let tempList = deepFilter(
                        convertToTreeData(finalEedges, nodes[0].id),
                        nodeIdList,
                        lastNodeIdList
                    );
                    nodes[0].children = tempList;
                    list = nodes;
                    title = nodes[0].properties.topic;
                } else if (nodes.length == 0 && themeNum == 1) {
                    // 处理数据获取树状结构数据
                    list = deepFilter(
                        convertToTreeData(finalEedges, "0"),
                        nodeIdList,
                        lastNodeIdList
                    );
                }
                if (list.length != 0) {
                    this.finalNodes = [];
                    this.getWayData(list);
                    // console.log(this.finalNodes)
                    for (let i = 0; i < this.finalNodes.length; i++) {
                        if (this.finalNodes[i].type == "icon-stage") {
                            courseType = "stage";
                            break;
                        }
                        if (this.finalNodes[i].type == "icon-task") {
                            courseType = "task";
                            break;
                        }
                    }
                    let taskJson = [];
                    // 直线型任务课程
                    if (
                        courseType == "task" &&
                        (this.finalNodes[0].children.length == 1 ||
                            this.finalNodes[0].children.length == 0)
                    ) {
                        let loadingInstance = Loading.service({
                            fullscreen: true,
                            text: this.languageData.taskCourseTip,
                        });
                        taskJson = this.getTaskJson(taskJson);
                        let chaptersData = [
                            {
                                dyName: "",
                                chapterInfo: [
                                    {
                                        isread: false,
                                        chapterid: uuidv4(),
                                        title: "",
                                        courseName: "",
                                        taskJson: taskJson,
                                        itemCount: 1,
                                        fileList1: [],
                                        video: [],
                                        testData: [],
                                        pData: [],
                                        templateArray: [],
                                    },
                                ],
                            },
                        ];
                        this.sendCreateTaskRequest(title, chaptersData, loadingInstance);
                    }
                    // 任务双路径型课程
                    else if (
                        courseType == "task" &&
                        this.finalNodes[0].children.length == 2
                    ) {
                        this.taskData = [];
                        this.getTaskData(list);
                        let isReasonable = false;
                        let loadingInstance = Loading.service({
                            fullscreen: true,
                            text: this.languageData.taskCourseTip,
                        });
                        for (let k = 0; k < this.taskData.length; k++) {
                            for (let m = 0; m < this.taskData[k].children.length; m++) {
                                if (this.taskData[k].children[m].type != "icon-task") {
                                    let temp = this.isTaskReasonable(
                                        this.taskData[k].children[m].children
                                    );
                                    if (temp.length > 0) {
                                        isReasonable = true;
                                        break;
                                    }
                                }
                            }
                        }
                        for (let i = 0; i < this.taskData.length; i++) {
                            this.finalNodes = [];
                            this.finalNodes.push(this.taskData[i]);
                            if (this.taskData[i].children.length != 0) {
                                for (let j = 0; j < this.taskData[i].children.length; j++) {
                                    if (this.taskData[i].children[j].type != "icon-task") {
                                        this.finalNodes.push(this.taskData[i].children[j]);
                                        if (this.taskData[i].children[j].children.length != 0) {
                                            this.finalNodes.push(
                                                this.taskData[i].children[j].children[0]
                                            );
                                            this.getWayData(this.taskData[i].children[j].children);
                                        }
                                    }
                                }
                            }
                            taskJson = this.getTaskJson(taskJson);
                        }
                        if (taskJson.length != 0 && !isReasonable) {
                            let chaptersData = [
                                {
                                    dyName: "",
                                    chapterInfo: [
                                        {
                                            isread: false,
                                            chapterid: uuidv4(),
                                            title: "",
                                            courseName: "",
                                            taskJson: taskJson,
                                            itemCount: 1,
                                            fileList1: [],
                                            video: [],
                                            testData: [],
                                            pData: [],
                                            templateArray: [],
                                        },
                                    ],
                                },
                            ];
                            this.sendCreateTaskRequest(title, chaptersData, loadingInstance);
                        } else {
                            this.$nextTick(() => {
                                // 以服务的方式调用的 Loading 需要异步关闭
                                loadingInstance.close();
                            });
                            this.$message.error(this.languageData.createErrorTip2);
                        }
                    }
                    // 直线型阶段课程
                    else if (
                        courseType == "stage" &&
                        this.finalNodes[0].children.length == 1
                    ) {
                        if (
                            this.finalNodes[0].children[0].children.length == 1 ||
                            this.finalNodes[0].children[0].children.length == 0
                        ) {
                            // this.finalNodes.push(this.finalNodes[this.finalNodes.length - 1].children[0])
                            let loadingInstance = Loading.service({
                                fullscreen: true,
                                text: this.languageData.stageCourseTip,
                            });
                            let chaptersData = this.getStageJson();
                            this.sendCreateStageRequest(title, chaptersData, loadingInstance);
                        } else if (this.finalNodes[0].children[0].children.length == 2) {
                            let stageName = this.finalNodes[0].properties.stageName;
                            // this.$message.error(this.languageData.createErrorTip2);
                            this.finalNodes.splice(1, 1);
                            this.taskData = [];
                            this.getTaskData(list);
                            let isReasonable = false;
                            let loadingInstance = Loading.service({
                                fullscreen: true,
                                text: this.languageData.taskCourseTip,
                            });
                            for (let k = 0; k < this.taskData.length; k++) {
                                for (let m = 0; m < this.taskData[k].children.length; m++) {
                                    if (this.taskData[k].children[m].type != "icon-task") {
                                        let temp = this.isTaskReasonable(
                                            this.taskData[k].children[m].children
                                        );
                                        if (temp.length > 0) {
                                            isReasonable = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            for (let i = 0; i < this.taskData.length; i++) {
                                this.finalNodes = [];
                                this.finalNodes.push(this.taskData[i]);
                                if (this.taskData[i].children.length != 0) {
                                    for (let j = 0; j < this.taskData[i].children.length; j++) {
                                        if (this.taskData[i].children[j].type != "icon-task") {
                                            this.finalNodes.push(this.taskData[i].children[j]);
                                            if (this.taskData[i].children[j].children.length != 0) {
                                                this.finalNodes.push(
                                                    this.taskData[i].children[j].children[0]
                                                );
                                                this.getWayData(this.taskData[i].children[j].children);
                                            }
                                        }
                                    }
                                }
                                taskJson = this.getTaskJson(taskJson);
                            }
                            if (taskJson.length != 0 && !isReasonable) {
                                let chaptersData = [
                                    {
                                        dyName: stageName,
                                        chapterInfo: [
                                            {
                                                isread: false,
                                                chapterid: uuidv4(),
                                                title: "",
                                                courseName: "",
                                                taskJson: taskJson,
                                                itemCount: 1,
                                                fileList1: [],
                                                video: [],
                                                testData: [],
                                                pData: [],
                                                templateArray: [],
                                            },
                                        ],
                                    },
                                ];
                                this.sendCreateStageRequest(
                                    title,
                                    chaptersData,
                                    loadingInstance
                                );
                            } else {
                                this.$nextTick(() => {
                                    // 以服务的方式调用的 Loading 需要异步关闭
                                    loadingInstance.close();
                                });
                                this.$message.error(this.languageData.createErrorTip2);
                            }
                        }
                    }
                    // 阶段双路径型课程
                    else if (
                        courseType == "stage" &&
                        this.finalNodes[0].children.length == 2
                    ) {
                        this.stageData = [];
                        this.getStageData(list);
                        let loadingInstance = Loading.service({
                            fullscreen: true,
                            text: this.languageData.stageCourseTip,
                        });
                        let chaptersData = [];
                        let isReasonable = false;
                        for (let i = 0; i < this.stageData.length; i++) {
                            taskJson = [];
                            this.finalNodes = [];
                            this.finalNodes.push(this.stageData[i]);
                            if (this.stageData[i].children.length != 0) {
                                for (let j = 0; j < this.stageData[i].children.length; j++) {
                                    if (this.stageData[i].children[j].type != "icon-stage") {
                                        if (this.stageData[i].children[j].children.length == 0) {
                                            this.finalNodes.push(this.stageData[i].children[j]);
                                            this.getWayData(this.stageData[i].children[j].children);
                                        }
                                        if (this.stageData[i].children[j].children.length == 1) {
                                            this.finalNodes.push(this.stageData[i].children[j]);
                                            let temp = this.getSomeStageData(
                                                this.stageData[i].children[j].children,
                                                this.stageData[i].children[j].id
                                            );
                                            if (temp) {
                                                isReasonable = true;
                                            }
                                        } else if (
                                            this.stageData[i].children[j].children.length == 2
                                        ) {
                                            this.finalNodes.push(this.stageData[i].children[j]);
                                            this.taskData = [];
                                            this.getTaskData(this.stageData[i].children[j].children);
                                            for (
                                                let n = 0;
                                                n < this.stageData[i].children[j].children.length;
                                                n++
                                            ) {
                                                if (
                                                    this.stageData[i].children[j].children[n].type !=
                                                    "icon-task"
                                                ) {
                                                    this.finalNodes.push(
                                                        this.stageData[i].children[j].children[n]
                                                    );
                                                    let temp = this.getSomeTaskData(
                                                        this.stageData[i].children[j].children[n].children,
                                                        this.stageData[i].children[j].children[n].id
                                                    );
                                                    if (temp) {
                                                        isReasonable = true;
                                                    }
                                                    let stageTemp = this.getSomeStageData(
                                                        this.stageData[i].children[j].children[n].children,
                                                        this.stageData[i].children[j].children[n].id
                                                    );
                                                    if (stageTemp) {
                                                        isReasonable = true;
                                                    }
                                                    break;
                                                }
                                            }
                                            for (let k = 0; k < this.taskData.length; k++) {
                                                this.finalNodes.push(this.taskData[k]);
                                                for (
                                                    let m = 0;
                                                    m < this.taskData[k].children.length;
                                                    m++
                                                ) {
                                                    if (
                                                        this.taskData[k].children[m].type != "icon-task"
                                                    ) {
                                                        this.finalNodes.push(this.taskData[k].children[m]);
                                                        let temp = this.getSomeTaskData(
                                                            this.taskData[k].children[m].children,
                                                            this.taskData[k].children[m].id
                                                        );
                                                        if (temp) {
                                                            isReasonable = true;
                                                            break;
                                                        }
                                                        let stageTemp = this.getSomeStageData(
                                                            this.taskData[k].children[m].children,
                                                            this.taskData[k].children[m].id
                                                        );
                                                        if (stageTemp) {
                                                            isReasonable = true;
                                                            break;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            taskJson = this.getTaskJson(taskJson);
                            chaptersData.push({
                                dyName: this.finalNodes[0].properties.stageName,
                                chapterInfo: [
                                    {
                                        isread: false,
                                        chapterid: uuidv4(),
                                        title: "",
                                        courseName: "",
                                        taskJson: taskJson,
                                        itemCount: 1,
                                        fileList1: [],
                                        video: [],
                                        testData: [],
                                        pData: [],
                                        templateArray: [],
                                    },
                                ],
                            });
                        }
                        if (!isReasonable) {
                            this.sendCreateStageRequest(title, chaptersData, loadingInstance);
                        } else {
                            this.$nextTick(() => {
                                // 以服务的方式调用的 Loading 需要异步关闭
                                loadingInstance.close();
                            });
                            this.$message.error(this.languageData.createErrorTip2);
                        }
                    } else {
                        this.$message.error(this.languageData.createErrorTip3);
                    }
                } else {
                    this.$message.error(this.languageData.createErrorTip1);
                }
            }
        },
        /* 
          生成阶段双路径型项目中任务的json
        */
        getProjectTaskJson(taskJson) {
            for (let i = 0; i < this.finalNodes.length; i++) {
                if (this.finalNodes[i].type == "icon-task") {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                    });
                }
            }
            return taskJson;
        },
        getInformationTaskJson(taskJson, courseid) {
            let temp = 0;
            console.log(this.finalNodes);
            for (let i = 0; i < this.finalNodes.length; i++) {
                if (
                    this.finalNodes[i].type == "icon-task" &&
                    i < this.finalNodes.length - 1 &&
                    (this.finalNodes[i + 1].type == "icon-card" ||
                        this.finalNodes[i + 1].type == "icon-map")
                ) {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [
                            {
                                tool: 51,
                                toolDetail: "",
                                toolPhoto: [],
                                toolEdit: false,
                                toolId: uuidv4(),
                                sourceIndex: 0,
                                toolData: [],
                                isNoFile: false,
                                people: [],
                            },
                        ],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        tcMember: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                        isread: this.finalNodes[i].properties.taskTitle,
                        chapterid: "",
                        title: "",
                        courseName: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        taskJson: [],
                        itemCount: "",
                        fileList1: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        testData: false,
                        pData: 1,
                        templateArray: 0,
                        undefined: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-task" &&
                    i < this.finalNodes.length - 1 &&
                    this.finalNodes[i + 1].type != "icon-card" &&
                    this.finalNodes[i + 1].type != "icon-map"
                ) {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        tcMember: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                        isread: this.finalNodes[i].properties.taskTitle,
                        chapterid: "",
                        title: "",
                        courseName: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        taskJson: [],
                        itemCount: "",
                        fileList1: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        testData: false,
                        pData: 1,
                        templateArray: 0,
                        undefined: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-task" &&
                    i == this.finalNodes.length - 1
                ) {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        tcMember: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                        isread: this.finalNodes[i].properties.taskTitle,
                        chapterid: "",
                        title: "",
                        courseName: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        taskJson: [],
                        itemCount: "",
                        fileList1: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        testData: false,
                        pData: 1,
                        templateArray: 0,
                        undefined: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                    });
                }
                if (this.finalNodes[i].properties.radio == "imageList") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.imageList[0].name
                            ? this.finalNodes[i].properties.imageList[0].name
                            : this.finalNodes[i].properties.imageList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.imageList[0].url,
                        type: 13,
                        src: "",
                    });
                }
                if (this.finalNodes[i].properties.radio == "videoList") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.videoList[0].name
                            ? this.finalNodes[i].properties.videoList[0].name
                            : this.finalNodes[i].properties.videoList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.videoList[0].url,
                        type: 2,
                        src: {
                            playbackRates: [0.7, 1, 1.5, 2],
                            autoplay: false,
                            muted: false,
                            loop: false,
                            preload: "auto",
                            language: "zh-CN",
                            aspectRatio: "16:9",
                            fluid: true,
                            sources: [
                                {
                                    type: "video/mp4",
                                    src: this.finalNodes[i].properties.videoList[0].url,
                                },
                            ],
                            notSupportedMessage: "此视频暂无法播放，请稍后再试",
                            controlBar: {
                                timeDivider: true,
                                durationDisplay: true,
                                remainingTimeDisplay: false,
                                fullscreenToggle: true,
                            },
                        },
                    });
                }
                if (this.finalNodes[i].properties.radio == "iframeUrl") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: "链接",
                        title: "aaa",
                        url: this.finalNodes[i].properties.iframeUrl,
                        src:
                            this.finalNodes[i].properties.iframeUrl.indexOf("https://") != -1
                                ? this.finalNodes[i].properties.iframeUrl
                                : "https://" + this.finalNodes[i].properties.iframeUrl,
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "embedCode") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: "链接",
                        title: "嵌入代码",
                        src: this.finalNodes[i].properties.embedCode
                            .split('src="')[1]
                            .split('"')[0],
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "audioList") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.audioList[0].name
                            ? this.finalNodes[i].properties.audioList[0].name
                            : this.finalNodes[i].properties.audioList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.audioList[0].url,
                        type: 12,
                        src: "",
                    });
                }
                if (
                    this.finalNodes[i].properties.radio == "fileList" &&
                    this.finalNodes[i].properties.fileList[0].url.indexOf(".pdf") == -1 &&
                    this.finalNodes[i].properties.fileList[0].url.indexOf(".PDF") == -1
                ) {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.fileList[0].name
                            ? this.finalNodes[i].properties.fileList[0].name
                            : this.finalNodes[i].properties.fileList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.fileList[0].url,
                        type: 3,
                        src:
                            "https://view.officeapps.live.com/op/view.aspx?src=" +
                            this.finalNodes[i].properties.fileList[0].url,
                    });
                }
                if (
                    this.finalNodes[i].properties.radio == "fileList" &&
                    (this.finalNodes[i].properties.fileList[0].url.indexOf(".pdf") !=
                        -1 ||
                        this.finalNodes[i].properties.fileList[0].url.indexOf(".PDF") != -1)
                ) {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.fileList[0].name
                            ? this.finalNodes[i].properties.fileList[0].name
                            : this.finalNodes[i].properties.fileList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.fileList[0].url,
                        type: 9,
                        src: "",
                    });
                }
                if (this.finalNodes[i].properties.radio == "content") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.title,
                        url: this.finalNodes[i].properties.content,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-markdown" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p>' + this.finalNodes[i].properties.aiContent + '</p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18163,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text: "<p>" + this.finalNodes[i].properties.aiContent + "</p>",
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p>' + this.finalNodes[i].properties.aiContent + '</p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18163,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text: "<p>" + this.finalNodes[i].properties.aiContent + "</p>",
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p><img src=\\"' +
                                    this.finalNodes[i].properties.imgResult +
                                    '\\" style=\\"max-width:100%25;\\"/><br/></p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18164,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text:
                            '<p><img src="' +
                            this.finalNodes[i].properties.imgResult +
                            '" style="max-width:100%25;"/><br/></p>',
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p>' + this.finalNodes[i].properties.aiContent + '</p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18163,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text: "<p>" + this.finalNodes[i].properties.aiContent + "</p>",
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p><img src=\\"' +
                                    this.finalNodes[i].properties.imgResult +
                                    '\\" style=\\"max-width:100%25;\\"/><br/></p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18164,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text:
                            '<p><img src="' +
                            this.finalNodes[i].properties.imgResult +
                            '" style="max-width:100%25;"/><br/></p>',
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "audio"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p><audio controls src=\\"' +
                                    this.finalNodes[i].properties.audioList[0].url +
                                    '\\" style=\\"max-width:100%25;\\"></audio><br/></p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18165,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text:
                            '<p><audio controls src="' +
                            this.finalNodes[i].properties.audioList[0].url +
                            '" style="max-width:100%25;"></audio><br/></p>',
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (this.finalNodes[i].type == "icon-thinking") {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 3,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "3",
                                content: this.finalNodes[i].properties.thinkingContent
                                    ? this.finalNodes[i].properties.thinkingContent
                                    : "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
                                rate: null,
                                score: null,
                                img: null,
                                type: 1,
                                order: 18157,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-whiteboard" &&
                    this.finalNodes[i].properties.whiteboardContent
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 1,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "1",
                                content: this.finalNodes[i].properties.whiteboardContent,
                                rate: null,
                                score: null,
                                img: null,
                                type: 1,
                                order: 18157,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-map" &&
                    this.finalNodes[i].properties.map_url
                ) {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.map_url.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.map_url,
                        type: 13,
                        src: "",
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-camera" &&
                    this.finalNodes[i].properties.src
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 54,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "54",
                                content: this.finalNodes[i].properties.src,
                                rate: null,
                                score: null,
                                img: null,
                                type: 1,
                                order: 18157,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: "51c37eb4-a83a-15ec-cce2-cc69c0594293",
                        people: [],
                        isFinishSize: "0.00",
                        isAllSize: "0.00",
                    });
                    temp = temp + 1;
                }
            }
            return taskJson;
        },
        /* 
          生成直线型阶段项目的基础json
        */
        getProjectStageJson() {
            let taskJson = [];
            let chaptersData = [];
            for (let i = 0; i < this.finalNodes.length; i++) {
                if (this.finalNodes[i].type == "icon-stage") {
                    if (taskJson.length != 0) {
                        chaptersData[chaptersData.length - 1].chapterInfo[0].taskJson =
                            taskJson;
                        taskJson = [];
                    }
                    chaptersData.push({
                        dyName: this.finalNodes[i].properties.stageName,
                        chapterInfo: [
                            {
                                isread: false,
                                chapterid: uuidv4(),
                                title: "",
                                courseName: "",
                                taskJson: [],
                                itemCount: 1,
                                fileList1: [],
                                video: [],
                                testData: [],
                                pData: [],
                                templateArray: [],
                            },
                        ],
                    });
                }
                if (this.finalNodes[i].type == "icon-task") {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                    });
                }
                if (i == this.finalNodes.length - 1) {
                    chaptersData[chaptersData.length - 1].chapterInfo[0].taskJson =
                        taskJson;
                }
            }
            return chaptersData;
        },
        /*
          生成直线型阶段项目中资料的json
        */
        getInformationStageJson(courseid) {
            let temp = 0;
            let taskJson = [];
            let chaptersData = [];
            for (let i = 0; i < this.finalNodes.length; i++) {
                if (this.finalNodes[i].type == "icon-stage") {
                    if (taskJson.length != 0) {
                        chaptersData[chaptersData.length - 1].chapterInfo[0].taskJson =
                            taskJson;
                        taskJson = [];
                    }
                    chaptersData.push({
                        dyName: this.finalNodes[i].properties.stageName,
                        chapterInfo: [
                            {
                                isread: false,
                                chapterid: uuidv4(),
                                title: "",
                                courseName: "",
                                taskJson: [],
                                itemCount: 1,
                                fileList1: [],
                                video: [],
                                testData: [],
                                pData: [],
                                templateArray: [],
                            },
                        ],
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-task" &&
                    i < this.finalNodes.length - 1 &&
                    (this.finalNodes[i + 1].type == "icon-card" ||
                        this.finalNodes[i + 1].type == "icon-map")
                ) {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [
                            {
                                tool: 51,
                                toolDetail: "",
                                toolPhoto: [],
                                toolEdit: false,
                                toolId: uuidv4(),
                                sourceIndex: 0,
                                toolData: [],
                                isNoFile: false,
                                people: [],
                            },
                        ],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        tcMember: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                        isread: this.finalNodes[i].properties.taskTitle,
                        chapterid: "",
                        title: "",
                        courseName: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        taskJson: [],
                        itemCount: "",
                        fileList1: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        testData: false,
                        pData: 1,
                        templateArray: 0,
                        undefined: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-task" &&
                    i < this.finalNodes.length - 1 &&
                    this.finalNodes[i + 1].type != "icon-card" &&
                    this.finalNodes[i + 1].type != "icon-map"
                ) {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        tcMember: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                        isread: this.finalNodes[i].properties.taskTitle,
                        chapterid: "",
                        title: "",
                        courseName: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        taskJson: [],
                        itemCount: "",
                        fileList1: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        testData: false,
                        pData: 1,
                        templateArray: 0,
                        undefined: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-task" &&
                    i == this.finalNodes.length - 1
                ) {
                    taskJson.push({
                        task: this.finalNodes[i].properties.taskTitle,
                        people: "",
                        time: "",
                        taskDetail: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        chapterData: [],
                        toolText: "",
                        toolChoose: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        toolArray: [],
                        isShowTools: false,
                        askCount: 1,
                        isFold: 0,
                        askTitle: "",
                        askJson: [
                            {
                                askstitle: "",
                                askItem: 1,
                                checkList: [],
                            },
                        ],
                        checkJson: [
                            {
                                checkCount: [],
                                checkPerent: [],
                            },
                        ],
                        homeworkList: [],
                        tcMember: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                        isread: this.finalNodes[i].properties.taskTitle,
                        chapterid: "",
                        title: "",
                        courseName: this.finalNodes[i].properties.taskContent
                            ? this.finalNodes[i].properties.taskContent
                            : "",
                        taskJson: [],
                        itemCount: "",
                        fileList1: [
                            {
                                tool: [],
                                toolDetail: "",
                                toolType: 0,
                                askCount: 1,
                                askTitle: "",
                                askJson: [
                                    {
                                        askstitle: "",
                                        askItem: 1,
                                        checkList: [],
                                    },
                                ],
                            },
                        ],
                        testData: false,
                        pData: 1,
                        templateArray: 0,
                        undefined: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                : window.parent.US.userInfo.userid,
                        ],
                    });
                }
                if (this.finalNodes[i].properties.radio == "imageList") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.imageList[0].name
                            ? this.finalNodes[i].properties.imageList[0].name
                            : this.finalNodes[i].properties.imageList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.imageList[0].url,
                        type: 13,
                        src: "",
                    });
                }
                if (this.finalNodes[i].properties.radio == "videoList") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.videoList[0].name
                            ? this.finalNodes[i].properties.videoList[0].name
                            : this.finalNodes[i].properties.videoList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.videoList[0].url,
                        type: 2,
                        src: {
                            playbackRates: [0.7, 1, 1.5, 2],
                            autoplay: false,
                            muted: false,
                            loop: false,
                            preload: "auto",
                            language: "zh-CN",
                            aspectRatio: "16:9",
                            fluid: true,
                            sources: [
                                {
                                    type: "video/mp4",
                                    src: this.finalNodes[i].properties.videoList[0].url,
                                },
                            ],
                            notSupportedMessage: "此视频暂无法播放，请稍后再试",
                            controlBar: {
                                timeDivider: true,
                                durationDisplay: true,
                                remainingTimeDisplay: false,
                                fullscreenToggle: true,
                            },
                        },
                    });
                }
                if (this.finalNodes[i].properties.radio == "iframeUrl") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: "链接",
                        title: "aaa",
                        url: this.finalNodes[i].properties.iframeUrl,
                        src:
                            this.finalNodes[i].properties.iframeUrl.indexOf("https://") != -1
                                ? this.finalNodes[i].properties.iframeUrl
                                : "https://" + this.finalNodes[i].properties.iframeUrl,
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "embedCode") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: "链接",
                        title: "嵌入代码",
                        src: this.finalNodes[i].properties.embedCode
                            .split('src="')[1]
                            .split('"')[0],
                        type: 8,
                    });
                }
                if (this.finalNodes[i].properties.radio == "audioList") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.audioList[0].name
                            ? this.finalNodes[i].properties.audioList[0].name
                            : this.finalNodes[i].properties.audioList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.audioList[0].url,
                        type: 12,
                        src: "",
                    });
                }
                if (
                    this.finalNodes[i].properties.radio == "fileList" &&
                    this.finalNodes[i].properties.fileList[0].url.indexOf(".pdf") == -1 &&
                    this.finalNodes[i].properties.fileList[0].url.indexOf(".PDF") == -1
                ) {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.fileList[0].name
                            ? this.finalNodes[i].properties.fileList[0].name
                            : this.finalNodes[i].properties.fileList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.fileList[0].url,
                        type: 3,
                        src:
                            "https://view.officeapps.live.com/op/view.aspx?src=" +
                            this.finalNodes[i].properties.fileList[0].url,
                    });
                }
                if (
                    this.finalNodes[i].properties.radio == "fileList" &&
                    (this.finalNodes[i].properties.fileList[0].url.indexOf(".pdf") !=
                        -1 ||
                        this.finalNodes[i].properties.fileList[0].url.indexOf(".PDF") != -1)
                ) {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.fileList[0].name
                            ? this.finalNodes[i].properties.fileList[0].name
                            : this.finalNodes[i].properties.fileList[0].url.split(
                                ".com.cn/"
                            )[1],
                        url: this.finalNodes[i].properties.fileList[0].url,
                        type: 9,
                        src: "",
                    });
                }
                if (this.finalNodes[i].properties.radio == "content") {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.title,
                        url: this.finalNodes[i].properties.content,
                        type: 6,
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-markdown" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p>' + this.finalNodes[i].properties.aiContent + '</p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18163,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text: "<p>" + this.finalNodes[i].properties.aiContent + "</p>",
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p>' + this.finalNodes[i].properties.aiContent + '</p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18163,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text: "<p>" + this.finalNodes[i].properties.aiContent + "</p>",
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p><img src=\\"' +
                                    this.finalNodes[i].properties.imgResult +
                                    '\\" style=\\"max-width:100%25;\\"/><br/></p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18164,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text:
                            '<p><img src="' +
                            this.finalNodes[i].properties.imgResult +
                            '" style="max-width:100%25;"/><br/></p>',
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "description"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p>' + this.finalNodes[i].properties.aiContent + '</p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18163,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text: "<p>" + this.finalNodes[i].properties.aiContent + "</p>",
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "image"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p><img src=\\"' +
                                    this.finalNodes[i].properties.imgResult +
                                    '\\" style=\\"max-width:100%25;\\"/><br/></p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18164,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text:
                            '<p><img src="' +
                            this.finalNodes[i].properties.imgResult +
                            '" style="max-width:100%25;"/><br/></p>',
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-new-AI" &&
                    this.finalNodes[i].properties.radio == "audio"
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 52,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "52",
                                content:
                                    '"<p><audio controls src=\\"' +
                                    this.finalNodes[i].properties.audioList[0].url +
                                    '\\" style=\\"max-width:100%25;\\"></audio><br/></p>"',
                                rate: null,
                                score: null,
                                img: null,
                                type: 12,
                                order: 18165,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        text:
                            '<p><audio controls src="' +
                            this.finalNodes[i].properties.audioList[0].url +
                            '" style="max-width:100%25;"></audio><br/></p>',
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (this.finalNodes[i].type == "icon-thinking") {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 3,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "3",
                                content: this.finalNodes[i].properties.thinkingContent
                                    ? this.finalNodes[i].properties.thinkingContent
                                    : "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png",
                                rate: null,
                                score: null,
                                img: null,
                                type: 1,
                                order: 18157,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-whiteboard" &&
                    this.finalNodes[i].properties.whiteboardContent
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 1,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "1",
                                content: this.finalNodes[i].properties.whiteboardContent,
                                rate: null,
                                score: null,
                                img: null,
                                type: 1,
                                order: 18157,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: uuidv4(),
                        people: [
                            window.location.href.indexOf("localhost") != -1 ||
                                window.location.href.indexOf("192.168") != -1
                                ? "潘炜桐"
                                : window.parent.US.userInfo.username,
                        ],
                    });
                    temp = temp + 1;
                }
                if (
                    this.finalNodes[i].type == "icon-map" &&
                    this.finalNodes[i].properties.map_url
                ) {
                    taskJson[taskJson.length - 1].toolArray[0].toolData.push({
                        name: this.finalNodes[i].properties.map_url.split(".com.cn/")[1],
                        url: this.finalNodes[i].properties.map_url,
                        type: 13,
                        src: "",
                    });
                }
                if (
                    this.finalNodes[i].type == "icon-camera" &&
                    this.finalNodes[i].properties.src
                ) {
                    taskJson[taskJson.length - 1].toolArray.push({
                        tool: 54,
                        toolDetail: "",
                        toolPhoto: [
                            {
                                id: uuidv4(),
                                userid:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                                        : window.parent.US.userInfo.userid,
                                courseid: courseid,
                                stage: 0,
                                task: 0,
                                tool: temp,
                                atool: "54",
                                content: this.finalNodes[i].properties.src,
                                rate: null,
                                score: null,
                                img: null,
                                type: 1,
                                order: 18157,
                                isRead: 1,
                                uteacher: null,
                                ateacher: null,
                                tchange_time: null,
                                create_at: new Date(),
                                name: this.formatDate(new Date(), "yyyy.MM.dd"),
                                username:
                                    window.location.href.indexOf("localhost") != -1 ||
                                        window.location.href.indexOf("192.168") != -1
                                        ? "潘炜桐"
                                        : window.parent.US.userInfo.username,
                            },
                        ],
                        toolEdit: false,
                        toolId: "51c37eb4-a83a-15ec-cce2-cc69c0594293",
                        people: [],
                        isFinishSize: "0.00",
                        isAllSize: "0.00",
                    });
                    temp = temp + 1;
                }
                if (i == this.finalNodes.length - 1) {
                    chaptersData[chaptersData.length - 1].chapterInfo[0].taskJson =
                        taskJson;
                }
            }
            return chaptersData;
        },
        /*
          发送生成项目请求
        */
        async sendCreateProjectRequest(title, chaptersData, loadingInstance) {
            let that = this;
            let currentUserId =
                window.location.href.indexOf("localhost") != -1 ||
                    window.location.href.indexOf("192.168") != -1
                    ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                    : window.parent.US.userInfo.userid;
            let ateacher = "";
            for (let i = 0; i < that.group.length; i++) {
                if (that.group[i].id == that.active_group_id) {
                    for (let j = 0; j < that.group[i].group_member.length; j++) {
                        if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j != that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid + ",";
                        } else if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j == that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid;
                        }
                    }
                }
            }
            let params = [
                {
                    uid:
                        window.location.href.indexOf("localhost") != -1 ||
                            window.location.href.indexOf("192.168") != -1
                            ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                            : window.parent.US.userInfo.userid,
                    title: title,
                    brief: "",
                    cover: JSON.stringify([
                        {
                            name: "noBanner.jpg",
                            url: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/wu1676621845022.png",
                            uid: 1656409780264,
                            status: "success",
                        },
                    ]),
                    evaId: "",
                    astudent: "",
                    see: 0,
                    chapters: JSON.stringify(chaptersData),
                    template: "",
                    courseType: JSON.stringify([]),
                    ateacher: ateacher,
                    // "inviteCode": JSON.stringify([])
                },
            ];
            // console.log(qs.stringify(params))
            await this.$ajax
                .post(
                    "https://pbl.cocorobo.cn/api/pbl/addCourseWorkNew22",
                    qs.stringify(params)
                )
                .then((res) => {
                    console.log(res.data);
                    that.courseId = res.data.courseId;
                })
                .catch((err) => {
                    console.error(err);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    that.$message({
                        message: err,
                        type: "error",
                    });
                });
        },
        /*
          更新项目请求
        */
        sendUpdateProjectRequest(title, courseid, chaptersData, loadingInstance) {
            let that = this;
            let currentUserId =
                window.location.href.indexOf("localhost") != -1 ||
                    window.location.href.indexOf("192.168") != -1
                    ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                    : window.parent.US.userInfo.userid;
            let ateacher = "";
            for (let i = 0; i < that.group.length; i++) {
                if (that.group[i].id == that.active_group_id) {
                    for (let j = 0; j < that.group[i].group_member.length; j++) {
                        if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j != that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid + ",";
                        } else if (
                            currentUserId != that.group[i].group_member[j].userid &&
                            j == that.group[i].group_member.length - 1
                        ) {
                            ateacher += that.group[i].group_member[j].userid;
                        }
                    }
                }
            }
            let params = [
                {
                    cid: courseid,
                    uid:
                        window.location.href.indexOf("localhost") != -1 ||
                            window.location.href.indexOf("192.168") != -1
                            ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                            : window.parent.US.userInfo.userid,
                    title: title,
                    brief: "",
                    cover: JSON.stringify([
                        {
                            name: "noBanner.jpg",
                            url: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/wu1676621845022.png",
                            uid: 1656409780264,
                            status: "success",
                        },
                    ]),
                    evaId: "",
                    astudent: "",
                    see: 0,
                    chapters: JSON.stringify(chaptersData),
                    template: "",
                    courseType: JSON.stringify([]),
                    ateacher: ateacher,
                },
            ];
            // console.log(qs.stringify(params))
            this.$ajax
                .post(
                    "https://pbl.cocorobo.cn/api/pbl/updateStudentWorkNew22",
                    qs.stringify(params)
                )
                .then((res) => {
                    console.log(res.data);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    that.$message({
                        message: that.languageData.tip23,
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    that.$message({
                        message: err,
                        type: "error",
                    });
                });
        },
        /*
          创建项目
        */
        async $_createProject() {
            let data = this.lf.getGraphData();
            let courseType = "";

            let title = "";
            let themeNum = 0;
            for (let i = 0; i < data.nodes.length; i++) {
                if (data.nodes[i].type == "icon-theme") {
                    themeNum = themeNum + 1;
                }
            }

            for (let i = 0; i < data.nodes.length; i++) {
                if (data.nodes[i].type == "icon-theme") {
                    title = data.nodes[i].properties.topic;
                    break;
                }
            }
            // 获取所有节点的id列表
            var nodeIdList = [];
            for (let i = 0; i < data.nodes.length; i++) {
                nodeIdList.push(data.nodes[i].id);
            }
            // 获取所有连线的目标节点id列表
            var targetNodeIdList = [];
            for (let i = 0; i < data.edges.length; i++) {
                targetNodeIdList.push(data.edges[i].targetNodeId);
            }
            // 获取所有连线的源节点id列表
            var sourceNodeIdList = [];
            for (let i = 0; i < data.edges.length; i++) {
                sourceNodeIdList.push(data.edges[i].sourceNodeId);
            }
            // 获取图上的根节点id
            var firstNodeId = "";
            for (let i = 0; i < data.nodes.length; i++) {
                nodeIdList.push(data.nodes[i].id);
                if (
                    data.nodes[i].type == "icon-start" ||
                    data.nodes[i].type == "icon-theme"
                ) {
                    firstNodeId = data.nodes[i].id;
                }
            }
            if (firstNodeId != "") {
                // 获取图上的最后节点id列表
                var lastNodeIdList = [];
                for (let i = 0; i < nodeIdList.length; i++) {
                    if (sourceNodeIdList.includes(nodeIdList[i]) == false) {
                        lastNodeIdList.push(nodeIdList[i]);
                    }
                }
                // 获取自定义的所有连线列表
                let finalEedges = [];
                for (let i = 0; i < data.edges.length; i++) {
                    let edges = data.edges[i];
                    for (let j = 0; j < data.nodes.length; j++) {
                        if (edges.targetNodeId == data.nodes[j].id) {
                            edges.targetNode = data.nodes[j];
                            break;
                        }
                    }
                    finalEedges.push(edges);
                }
                for (let j = 0; j < data.nodes.length; j++) {
                    if (data.nodes[j].id == firstNodeId) {
                        finalEedges.push({
                            id: "0000",
                            sourceNodeId: "0",
                            targetNodeId: firstNodeId,
                            targetNode: data.nodes[j],
                        });
                        break;
                    }
                }
                const { nodes } = this.lf.getSelectElements();
                this.activeNodes = [];
                let list = [];
                if (nodes.length != 0) {
                    let tempList = deepFilter(
                        convertToTreeData(finalEedges, nodes[0].id),
                        nodeIdList,
                        lastNodeIdList
                    );
                    nodes[0].children = tempList;
                    list = nodes;
                    title = nodes[0].properties.topic;
                } else if (nodes.length == 0 && themeNum == 1) {
                    // 处理数据获取树状结构数据
                    list = deepFilter(
                        convertToTreeData(finalEedges, "0"),
                        nodeIdList,
                        lastNodeIdList
                    );
                }
                if (list.length != 0) {
                    this.finalNodes = [];
                    this.getWayData(list);
                    // console.log(this.finalNodes)
                    for (let i = 0; i < this.finalNodes.length; i++) {
                        if (this.finalNodes[i].type == "icon-stage") {
                            courseType = "stage";
                            break;
                        }
                    }
                    let taskJson = [];
                    // 直线型阶段项目
                    if (
                        courseType == "stage" &&
                        this.finalNodes[0].children.length == 1
                    ) {
                        if (
                            this.finalNodes[0].children[0].children.length == 1 ||
                            this.finalNodes[0].children[0].children.length == 0
                        ) {
                            // this.finalNodes.push(this.finalNodes[this.finalNodes.length - 1].children[0])
                            let loadingInstance = Loading.service({
                                fullscreen: true,
                                text: this.languageData.stageProjectTip,
                            });
                            let chaptersData = this.getProjectStageJson();
                            await this.sendCreateProjectRequest(
                                title,
                                chaptersData,
                                loadingInstance
                            );
                            let new_chaptersData = this.getInformationStageJson(
                                this.courseId
                            );
                            this.sendUpdateProjectRequest(
                                title,
                                this.courseId,
                                new_chaptersData,
                                loadingInstance
                            );
                        } else if (this.finalNodes[0].children[0].children.length == 2) {
                            this.$message.error(this.languageData.createErrorTip5);
                        }
                    }
                    // 阶段双路径型项目
                    else if (
                        courseType == "stage" &&
                        this.finalNodes[0].children.length == 2
                    ) {
                        this.stageData = [];
                        this.getStageData(list);
                        let loadingInstance = Loading.service({
                            fullscreen: true,
                            text: this.languageData.stageProjectTip,
                        });
                        let chaptersData = [];
                        let isReasonable = false;
                        for (let i = 0; i < this.stageData.length; i++) {
                            taskJson = [];
                            this.finalNodes = [];
                            this.finalNodes.push(this.stageData[i]);
                            if (this.stageData[i].children.length != 0) {
                                for (let j = 0; j < this.stageData[i].children.length; j++) {
                                    if (this.stageData[i].children[j].type != "icon-stage") {
                                        if (this.stageData[i].children[j].children.length == 0) {
                                            this.finalNodes.push(this.stageData[i].children[j]);
                                            this.getWayData(this.stageData[i].children[j].children);
                                        }
                                        if (this.stageData[i].children[j].children.length == 1) {
                                            this.finalNodes.push(this.stageData[i].children[j]);
                                            let temp = this.getSomeStageData(
                                                this.stageData[i].children[j].children,
                                                this.stageData[i].children[j].id
                                            );
                                            if (temp) {
                                                isReasonable = true;
                                            }
                                        } else if (
                                            this.stageData[i].children[j].children.length == 2
                                        ) {
                                            this.finalNodes.push(this.stageData[i].children[j]);
                                            this.taskData = [];
                                            this.getTaskData(this.stageData[i].children[j].children);
                                            for (
                                                let n = 0;
                                                n < this.stageData[i].children[j].children.length;
                                                n++
                                            ) {
                                                if (
                                                    this.stageData[i].children[j].children[n].type !=
                                                    "icon-task"
                                                ) {
                                                    this.finalNodes.push(
                                                        this.stageData[i].children[j].children[n]
                                                    );
                                                    let temp = this.getSomeTaskData(
                                                        this.stageData[i].children[j].children[n].children,
                                                        this.stageData[i].children[j].children[n].id
                                                    );
                                                    if (temp) {
                                                        isReasonable = true;
                                                    }
                                                    let stageTemp = this.getSomeStageData(
                                                        this.stageData[i].children[j].children[n].children,
                                                        this.stageData[i].children[j].children[n].id
                                                    );
                                                    if (stageTemp) {
                                                        isReasonable = true;
                                                    }
                                                    break;
                                                }
                                            }
                                            for (let k = 0; k < this.taskData.length; k++) {
                                                this.finalNodes.push(this.taskData[k]);
                                                for (
                                                    let m = 0;
                                                    m < this.taskData[k].children.length;
                                                    m++
                                                ) {
                                                    if (
                                                        this.taskData[k].children[m].type != "icon-task"
                                                    ) {
                                                        this.finalNodes.push(this.taskData[k].children[m]);
                                                        let temp = this.getSomeTaskData(
                                                            this.taskData[k].children[m].children,
                                                            this.taskData[k].children[m].id
                                                        );
                                                        if (temp) {
                                                            isReasonable = true;
                                                            break;
                                                        }
                                                        let stageTemp = this.getSomeStageData(
                                                            this.taskData[k].children[m].children,
                                                            this.taskData[k].children[m].id
                                                        );
                                                        if (stageTemp) {
                                                            isReasonable = true;
                                                            break;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            taskJson = this.getProjectTaskJson(taskJson);
                            chaptersData.push({
                                dyName: this.finalNodes[0].properties.stageName,
                                chapterInfo: [
                                    {
                                        isread: false,
                                        chapterid: uuidv4(),
                                        title: "",
                                        courseName: "",
                                        taskJson: taskJson,
                                        itemCount: 1,
                                        fileList1: [],
                                        video: [],
                                        testData: [],
                                        pData: [],
                                        templateArray: [],
                                    },
                                ],
                            });
                        }
                        if (!isReasonable) {
                            await this.sendCreateProjectRequest(
                                title,
                                chaptersData,
                                loadingInstance
                            );
                            chaptersData = [];
                            isReasonable = false;
                            for (let i = 0; i < this.stageData.length; i++) {
                                taskJson = [];
                                this.finalNodes = [];
                                this.finalNodes.push(this.stageData[i]);
                                if (this.stageData[i].children.length != 0) {
                                    for (let j = 0; j < this.stageData[i].children.length; j++) {
                                        if (this.stageData[i].children[j].type != "icon-stage") {
                                            if (this.stageData[i].children[j].children.length == 0) {
                                                this.finalNodes.push(this.stageData[i].children[j]);
                                                this.getWayData(this.stageData[i].children[j].children);
                                            }
                                            if (this.stageData[i].children[j].children.length == 1) {
                                                this.finalNodes.push(this.stageData[i].children[j]);
                                                let temp = this.getSomeStageData(
                                                    this.stageData[i].children[j].children,
                                                    this.stageData[i].children[j].id
                                                );
                                                if (temp) {
                                                    isReasonable = true;
                                                }
                                            } else if (
                                                this.stageData[i].children[j].children.length == 2
                                            ) {
                                                this.finalNodes.push(this.stageData[i].children[j]);
                                                this.taskData = [];
                                                this.getTaskData(
                                                    this.stageData[i].children[j].children
                                                );
                                                for (
                                                    let n = 0;
                                                    n < this.stageData[i].children[j].children.length;
                                                    n++
                                                ) {
                                                    if (
                                                        this.stageData[i].children[j].children[n].type !=
                                                        "icon-task"
                                                    ) {
                                                        this.finalNodes.push(
                                                            this.stageData[i].children[j].children[n]
                                                        );
                                                        let temp = this.getSomeTaskData(
                                                            this.stageData[i].children[j].children[n]
                                                                .children,
                                                            this.stageData[i].children[j].children[n].id
                                                        );
                                                        if (temp) {
                                                            isReasonable = true;
                                                        }
                                                        let stageTemp = this.getSomeStageData(
                                                            this.stageData[i].children[j].children[n]
                                                                .children,
                                                            this.stageData[i].children[j].children[n].id
                                                        );
                                                        if (stageTemp) {
                                                            isReasonable = true;
                                                        }
                                                        break;
                                                    }
                                                }
                                                for (let k = 0; k < this.taskData.length; k++) {
                                                    this.finalNodes.push(this.taskData[k]);
                                                    for (
                                                        let m = 0;
                                                        m < this.taskData[k].children.length;
                                                        m++
                                                    ) {
                                                        if (
                                                            this.taskData[k].children[m].type != "icon-task"
                                                        ) {
                                                            this.finalNodes.push(
                                                                this.taskData[k].children[m]
                                                            );
                                                            let temp = this.getSomeTaskData(
                                                                this.taskData[k].children[m].children,
                                                                this.taskData[k].children[m].id
                                                            );
                                                            if (temp) {
                                                                isReasonable = true;
                                                                break;
                                                            }
                                                            let stageTemp = this.getSomeStageData(
                                                                this.taskData[k].children[m].children,
                                                                this.taskData[k].children[m].id
                                                            );
                                                            if (stageTemp) {
                                                                isReasonable = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                taskJson = this.getInformationTaskJson(taskJson, this.courseId);
                                chaptersData.push({
                                    dyName: this.finalNodes[0].properties.stageName,
                                    chapterInfo: [
                                        {
                                            isread: false,
                                            chapterid: uuidv4(),
                                            title: "",
                                            courseName: "",
                                            taskJson: taskJson,
                                            itemCount: 1,
                                            fileList1: [],
                                            video: [],
                                            testData: [],
                                            pData: [],
                                            templateArray: [],
                                        },
                                    ],
                                });
                            }
                            this.sendUpdateProjectRequest(
                                title,
                                this.courseId,
                                chaptersData,
                                loadingInstance
                            );
                        } else {
                            this.$nextTick(() => {
                                // 以服务的方式调用的 Loading 需要异步关闭
                                loadingInstance.close();
                            });
                            this.$message.error(this.languageData.createErrorTip2);
                        }
                    } else {
                        this.$message.error(this.languageData.createErrorTip6);
                    }
                } else {
                    this.$message.error(this.languageData.createErrorTip4);
                }
            }
        },
    }
}
</script>

<style scoped>
.run {
    /* position: absolute;
  top: 66px;
  left: 58%; */
    z-index: 10;
    background: #007bff;
    margin-left: 20px;
    box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
    padding: 7px 15px;
}

.clear {
    /* position: absolute;
  top: 66px;
  left: 58%; */
    z-index: 10;
    /* background: #007BFF; */
    margin-left: 20px;
    box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
    padding: 7px 15px;
}

.isFullScreen {
    /* position: absolute;
  top: 66px;
  left: 58%; */
    z-index: 10;
    background: #80bfb0;
    margin-left: 20px;
    box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
    border-color: #80bfb0;
    padding: 7px 15px;
}

:deep(.el-button--small) {
    --el-button-size: 28px;
    padding: 7px 15px;
}

.fullScreen {
    /* position: absolute;
  top: 66px;
  left: 58%; */
    z-index: 10;
    /* background: #007BFF; */
    margin-left: 20px;
    box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
    padding: 7px 15px;
}

.projection {
    /* position: absolute;
  top: 66px;
  left: 58%; */
    z-index: 10;
    background: #ff9800;
    margin-left: 20px;
    box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
    border-color: #ff9800;
    padding: 7px 15px;
}

.loginout {
    z-index: 10;
    background: #205cc6;
    margin-left: 20px;
    box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
    border-color: #205cc6;
    padding: 7px 15px;
}

.delete {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    left: 20px;
    width: 40px;
    height: 40px;
}
</style>