import ImageNode from "./ImageNode";

// 图片-用户节点
class UserNode extends ImageNode.view {
  getImageHref() {
    return "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%A6%96%E6%80%AA1.png";
  }
}

export default {
  type: "icon-pm-1",
  view: UserNode,
  model: ImageNode.model,
};
