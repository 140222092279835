<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
      <path
        d="M933.647059 0h-210.82353v90.352941h180.705883a30.117647 30.117647 0 0 1 30.117647 30.117647v180.705883h90.352941V90.352941a90.352941 90.352941 0 0 0-90.352941-90.352941zM361.411765 0h301.17647v90.352941H361.411765zM933.647059 361.411765h90.352941v301.17647h-90.352941zM361.411765 933.647059h301.17647v90.352941H361.411765zM0 361.411765h90.352941v301.17647H0zM90.352941 903.529412v-180.705883H0v210.82353a90.352941 90.352941 0 0 0 90.352941 90.352941h210.82353v-90.352941H120.470588a30.117647 30.117647 0 0 1-30.117647-30.117647zM933.647059 903.529412a30.117647 30.117647 0 0 1-30.117647 30.117647h-180.705883v90.352941h210.82353a90.352941 90.352941 0 0 0 90.352941-90.352941v-210.82353h-90.352941zM0 90.352941v210.82353h90.352941V120.470588a30.117647 30.117647 0 0 1 30.117647-30.117647h180.705883V0H90.352941a90.352941 90.352941 0 0 0-90.352941 90.352941z"
        p-id="1890">
      </path>
    </svg> -->

  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMxOyYjMTg4
OyYjMTY5OyYjMjMwOyYjMTQ4OyYjMTkwOyYjMjMyOyYjMTM1OyYjMTc5OyYjMjI4OyYjMTg0OyYj
MTg3OyYjMjMzOyYjMTYyOyYjMTUyOyYjMjI5OyYjMTQxOyYjMTYxOyYjMjMxOyYjMTM3OyYjMTM1
OyI+CjxwYXRoIGlkPSJVbmlvbiIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5v
ZGQiIGQ9Ik0xNCA4QzE0IDExLjMxMzcgMTEuMzEzNyAxNCA4IDE0QzQuNjg2MjkgMTQgMiAxMS4z
MTM3IDIgOEMyIDQuNjg2MjkgNC42ODYyOSAyIDggMkMxMS4zMTM3IDIgMTQgNC42ODYyOSAxNCA4
Wk0xNSA4QzE1IDExLjg2NiAxMS44NjYgMTUgOCAxNUM0LjEzNDAxIDE1IDEgMTEuODY2IDEgOEMx
IDQuMTM0MDEgNC4xMzQwMSAxIDggMUMxMS44NjYgMSAxNSA0LjEzNDAxIDE1IDhaTTEwLjY1NTIg
Ni42Mzk4MUwxMS4zMDg5IDguMDAzNzJMMTAuNjU1OCA5LjM2NzYzTDEzLjYwMjEgOC4wMDM3Mkwx
MC42NTUyIDYuNjM5ODFaTTUuMzM5IDYuNjM5ODFMNC42ODUzIDguMDAzNzJMNS4zMzg0MiA5LjM2
NzYzTDIuMzkyMTIgOC4wMDM3Mkw1LjMzOSA2LjYzOTgxWk05LjM2MTAxIDEwLjY2MjFMNy45OTcx
IDExLjMxNThMNi42MzMzMyAxMC42NjIxTDcuOTk3MSAxMy42MDg3TDkuMzYxMDEgMTAuNjYyMVpN
OS4zNjEwMSA1LjM0NTM0TDcuOTk3MSA0LjY5MTY0TDYuNjMzMzMgNS4zNDUzNEw3Ljk5NzEgMi4z
OTg3NEw5LjM2MTAxIDUuMzQ1MzRaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiLz4K
PC9nPgo8L3N2Zz4K"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
