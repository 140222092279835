import _ from 'lodash'
import { v4 as uuidv4 } from "uuid";

// 元素属性右侧面板默认属性
export const defatuleStyle = {
  backgroundColor: "", // 填充色
  gradientColor: "", // 渐变色
  borderType: 0, // 边框类型
  borderColor: "", // 填充颜色
  borderWidth: 1, // 边框宽度
  borderStyle: "", // 边框类型
  fontSize: 12, // 文本大小
  fontColor: "", // 文本颜色
  fontWeight: "", // 文本加粗
};

export const getLfProperties = ({ type, context }) => {
  const base = {
    isShowDelete: false,
    createDate: new Date(),
    createUser:
      window.location.href.indexOf("localhost") != -1 ||
        window.location.href.indexOf("192.168") != -1
        ? "潘炜桐"
        : window.parent.US.userInfo.username,
  }
  const typeRelated = _.cond([
    [_.partial(_.isEqual, 'my-group'), _.constant({ 
      isCollapsed: false,
      radio: "imageList",
      title: "图片",
      titleColor: "#6266BC",
      imageList: [{name: "微信图片_20241009173604.png",url: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20241009173604.png"}]
    })],
    [_.partial(_.isEqual, 'icon-new-AI'), _.constant({ sourceCardId: uuidv4() })],
    [_.partial(_.isEqual, 'icon-ai-npc'), _.constant({
      ...context,
      url: context?.headUrl,
      name: context?.assistantName,
    })],
    [_.partial(_.startsWith, _, 'icon-pm'), _.constant({
      up: "w",
      down: "s",
      left: "a",
      right: "d",
      distance_x: "10",
      distance_y: "10",
    })],
    [_.partial(_.startsWith, _, 'icon-st-'), _.constant({
      up: "w",
      down: "s",
      left: "a",
      right: "d",
      distance_x: "10",
      distance_y: "10",
    })],
    [_.stubTrue, _.constant({})]
  ])(type)
  return { ...base, ...typeRelated }
}
