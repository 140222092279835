import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
// import { HtmlResize } from "@logicflow/extension";
import "../../../uml.css";
import { zhHansLanguage } from "../../../lang/zh-Hans.js";
import { zhHantLanguage } from "../../../lang/zh-Hant.js";
import { enLanguage } from "../../../lang/en.js";

class SelectModel extends HtmlNodeModel {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.editable = false; // 禁止节点文本编辑
    const width = 250;
    const height = 154;
    this.width = width;
    this.height = height;
    const unable_to_connect_with_oneself = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.tip43
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.tip43
          : enLanguage.tip43,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_theme
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_theme
          : enLanguage.unable_to_connect_with_theme,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor);
        return targetNode.type != "icon-theme";
      },
    };
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message:
        window.location.href.indexOf("cocorobo.cn") != -1
          ? zhHansLanguage.unable_to_connect_with_other
          : window.location.href.indexOf("cocorobo.hk") != -1
          ? zhHantLanguage.unable_to_connect_with_other
          : enLanguage.unable_to_connect_with_other,
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor);
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id);
        let isConnect = false;
        for (let i = 0; i < edges.length; i++) {
          if (
            edges[i].sourceNodeId == sourceNode.id &&
            edges[i].targetNodeId == targetNode.id
          ) {
            isConnect = true;
            break;
          }
        }
        return isConnect == false;
      },
    };
    this.sourceRules.push(unable_to_connect_with_other);
  }
  getData() {
    const data = super.getData();
    let properties = data.properties;
    properties = {
      ...properties,
      nodeSize: {
        width: this.width,
        height: this.height,
      }
    }
    return {
      ...super.getData(),
      properties: properties
    }
  }
  // setAttributes() {
  //   this.text.editable = false; // 禁止节点文本编辑
  //   // 设置节点宽高和锚点
  //   const width = 250;
  //   const height = 200;
  //   this.width = width;
  //   this.height = height;
  //   this.anchorsOffset = [
  //     [width / 2, 0],
  //     [0, height / 2],
  //     [-width / 2, 0],
  //     [0, -height / 2],
  //   ]
  // }
  // getResizeOutlineStyle(){
  //   const style = super.getResizeOutlineStyle();
  //   style.stroke = "#007BFF";
  //   style.strokeWidth = 2
  //   style.strokeDasharray = "3,3"
  //   style.fill = "transparent"
  //   return style;
  // }
}
class SelectNode extends HtmlNode {
  setHtml(rootEl) {
    const { id, properties } = this.props.model;

    // var r = Math.floor(Math.random() * 256);
    // var g = Math.floor(Math.random() * 256);
    // var b = Math.floor(Math.random() * 256);
    // var color = "rgb(" + r + "," + g + "," + b + ")";
    var color =
      properties.selectTitleColor && properties.selectTitleColor != ""
        ? properties.selectTitleColor
        : "#F284B2";
    let cardTitle =
      window.location.href.indexOf("cocorobo.cn") != -1
        ? zhHansLanguage.select
        : window.location.href.indexOf("cocorobo.hk") != -1
        ? zhHantLanguage.select
        : enLanguage.select;
    const el = document.createElement("div");
    el.className = "uml-wrapper";
    // el.style = "border: 1px solid " + color + ';';
    var html = `
    <div class="is_show_user" style="${
      properties.is_show_user ? "display:flex;" : "display:none;"
    }">
      <i class="el-icon" style="font-size: 18px;color:#58aba2;width: 18px;height: 27px;" >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M628.736 528.896A416 416 0 0 1 928 928H96a415.872 415.872 0 0 1 299.264-399.104L512 704zM720 304a208 208 0 1 1-416 0 208 208 0 0 1 416 0"></path></svg>
      </i>
      <div class="is_show_user_name">${properties.user_name}</div>
      <i class="el-icon" style="font-size: 16px;width: 16px;height: 27px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m199.04 672.64 193.984 112 224-387.968-193.92-112-224 388.032zm-23.872 60.16 32.896 148.288 144.896-45.696zM455.04 229.248l193.92 112 56.704-98.112-193.984-112-56.64 98.112zM104.32 708.8l384-665.024 304.768 175.936L409.152 884.8h.064l-248.448 78.336zm384 254.272v-64h448v64h-448z"></path></svg></i>
      <div class="apostrophe"></div>
    </div>
    <div style="height:${
      !properties.is_show_user
        ? "100%"
        : properties.nodeSize && properties.nodeSize.height
        ? properties.nodeSize.height - 27 + "px"
        : 127 + "px"
    };">
      <div class="uml-head" style="background-color:${color};">${
      properties.selectTitle ? properties.selectTitle : cardTitle
    }</div>
      <i id="${id}" class="el-icon closeImg" style="${
        properties.isShowDelete ? "display:block;font-size: 20px;" : "display:none;font-size: 20px;"
      }"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path></svg>
      </i>
      <div style="height:${
        !properties.is_show_user
          ? properties.nodeSize && properties.nodeSize.height
            ? properties.nodeSize.height - 32 + "px"
            : 122 + "px"
          : properties.nodeSize && properties.nodeSize.height
          ? properties.nodeSize.height - 60 + "px"
          : 94 + "px"
      };display: flex;justify-content: center;align-items: center;flex-direction: column;color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;padding:8px;">
        <div class="uml-select-body" style="text-align:justify;">
          ${properties.selectContent ? properties.selectContent : ""}
        </div>
        <div class="uml-select-option">`;

    var option = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    if (properties.selectOption) {
      for (var i = 0; i < properties.selectOption.length; i++) {
        html +=
          `<span style="margin-right:5px;font-size:12px;">` +
          option[i] +
          `.` +
          properties.selectOption[i] +
          `</span>`;
      }
    }
    html += `</div>
      </div>
    </div>
    `;
    el.innerHTML = html;
    // 需要先把之前渲染的子节点清除掉。
    rootEl.innerHTML = "";
    rootEl.appendChild(el);
  }
}

export default {
  type: "icon-select",
  view: SelectNode,
  model: SelectModel,
};
