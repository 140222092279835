<template>
  <div>
    <span
      style="
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      "
    >
      <div style="width: 100%">
        <el-button
          type="primary"
          style="float: right"
          size="small"
          round
          @click="handleSave"
          >{{ languageData.save }}</el-button
        >
      </div>
      <el-input
        v-model="address"
        style="margin: 20px 0px"
        :placeholder="languageData.tip26"
        class="input-with-select"
        @change="change_input"
      >
        <template #increase-icon>
          <el-button icon="el-icon-search" @click="search_address" />
        </template>
      </el-input>
      <div style="position: relative">
        <div
          v-if="address_list_flag"
          v-loading="loading"
          class="address_list"
          :element-loading-text="languageData.tip25"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div
            v-for="item of address_list"
            :key="item.id"
            class="address_item"
            @click="select_address(item)"
          >
            {{ item.title }}({{ item.address }})
          </div>
        </div>
        <div id="mapIframe" style="width: 100%; height: 70vh; border: none" />
        <input
          type="button"
          class="btn vector"
          :value="languageData.vectorgraph"
          @click="changeBaseMap({ type: 'vector' })"
        />
        <input
          type="button"
          class="btn satellite"
          :value="languageData.satellite_image"
          @click="changeBaseMap({ type: 'satellite' })"
        />
        <!-- <input type="button" class="btn traffic" @click="changeBaseMap([{type: 'vector'},{type: 'traffic'}])" value="实时路况图"> -->
      </div>
    </span>
  </div>
</template>

<script>
import { sendUpdate, add_Operation } from "../../tools/send.js";
import { ElLoading as Loading } from "element-plus";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
import LogicFlow from "@logicflow/core";
export default {
  props: {
    list: { type: Array, default: undefined },
    lf: LogicFlow,
  },
  data() {
    return {
      languageData: {},
      address: "",
      address_list: [
        // "深圳大学(粤海校区)(广东省深圳市南山区南海大道3688号)",
        // "深圳大学文科楼(广东省深圳市南山区南海大道3688号深圳大学)",
        // "深大南[地铁站](地铁9号线(梅林线),地铁15号线内环,地铁15号线外环)",
        // "深大[地铁站](地铁1号线(罗宝线),地铁13号线(石岩线))"
      ],
      address_list_flag: false,
      loading: false,
      lat: 0,
      lng: 0,
      map: null,
      markerLayer: null,
      map_type: null,
      hoverClose: false,
    };
  },
  watch: {},
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      document.domain = "cocorobo.cn";
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      document.domain = "cocorobo.hk";
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      document.domain = "cocorobo.com";
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    this.$store.state.addressArr.forEach((item) => {
      if (item.id == this.list[0].id) {
        this.lat = item.lat;
        this.lng = item.lng;
        this.map_type = item.map_type;
        this.init_map();
      }
    });
  },
  methods: {
    init_map() {
      $("#mapIframe").html("");
      let that = this;
      this.map = new TMap.Map("mapIframe", {
        center: new TMap.LatLng(this.lat, this.lng), //设置地图中心点坐标
        rotation: 20, // 设置地图旋转角度
        pitch: 30, // 设置俯仰角度（0~45）
        zoom: 11, //设置地图缩放级别
        renderOptions: {
          // renderOptions文档地址：https://lbs.qq.com/webApi/javascriptGL/glDoc/docIndexMap#10
          preserveDrawingBuffer: true, // 保留地图的渲染缓冲 默认false
        },
      });
      //创建并初始化MultiMarker
      this.markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            src: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/marker.png", //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: {
              x: 16,
              y: 32,
            },
          }),
        },
        //点标记数据数组
        geometries: [
          {
            id: "mapIframe", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(this.lat, this.lng), //点标记坐标位置
            properties: {
              //自定义属性
              title: "我的位置",
            },
          },
        ],
      });
      this.map.on("tilesloaded", function () {
        that.$store.commit("changeRemap", {
          map: this.map,
          markerLayer: this.markerLayer,
        });
      });
      //Map实例创建后，通过on方法绑定点击事件
      this.map.on("click", this.click_map);
      this.map.setBaseMap(this.map_type);
    },
    changeBaseMap(baseMap) {
      this.map.setBaseMap(baseMap);
      this.map_type = baseMap;
    },
    click_map(evt) {
      this.lat = evt.latLng.getLat().toFixed(6);
      this.lng = evt.latLng.getLng().toFixed(6);
      this.map.setCenter(new TMap.LatLng(this.lat, this.lng));
      this.markerLayer.updateGeometries([
        {
          styleId: "myStyle",
          id: "mapIframe",
          position: new TMap.LatLng(this.lat, this.lng),
        },
      ]);
    },
    handleSave() {
      let that = this;
      var node = document.getElementById("mapIframe");
      domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          // 导出图片
          let blob = that.dataURLtoBlob(dataUrl);
          // 拿到文件名
          let fileName = uuidv4() + ".png";
          // 2，在转为 file类型
          let file1 = that.blobToFile(blob, fileName);
          let loadingInstance = Loading.service({
            fullscreen: true,
            text: that.languageData.save_map,
          });
          that.uploadImage(file1, loadingInstance);
        })
        .catch(function (error) {
          console.error("domtoimage 出现问题! ", error);
        });
    },
    search_address() {
      let that = this;
      that.address_list_flag = true;
      that.loading = true;
      this.$ajax
        .post("https://gpt.cocorobo.cn/search_address", {
          keyword: this.address,
          lat: this.lat.toString(),
          lng: this.lng.toString(),
        })
        .then((res) => {
          that.loading = false;
          that.address_list = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    change_input(value) {
      if (!value) {
        this.address_list_flag = false;
      }
    },
    select_address(item) {
      this.lat = item.location.lat;
      this.lng = item.location.lng;
      this.map.setCenter(new TMap.LatLng(item.location.lat, item.location.lng));
      this.markerLayer.updateGeometries([
        {
          styleId: "myStyle",
          id: "mapIframe",
          position: new TMap.LatLng(item.location.lat, item.location.lng),
        },
      ]);
      this.address_list_flag = false;
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //2,再将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date(); // 文件最后的修改日期
      theBlob.name = fileName; // 文件名
      return new File([theBlob], fileName, {
        type: theBlob.type,
        lastModified: Date.now(),
      });
    },
    uploadImage(fileParams, loadingInstance) {
      // console.log(fileParams.file)
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      let list = _this.list;
      if (fileParams) {
        var params = {
          Key: fileParams.name,
          ContentType: fileParams.type,
          Body: fileParams,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket.upload(params, options).send(function (err, data) {
          if (err) {
            console.log(err);
          } else {
            _this.$props.lf.getNodeModelById(list[0].id).setProperties({
              lat: _this.lat,
              lng: _this.lng,
              map_type: _this.map_type,
              map_url: data.Location,
            });
            list[0].properties.map_type = _this.map_type;
            list[0].properties.lat = _this.lat;
            list[0].properties.lng = _this.lng;
            list[0].properties.map_url = data.Location;
            sendUpdate(list, [], "update", 0);
            let newData = {
              nodes: list,
              edges: [],
              backgroundUrl: localStorage.getItem("background"),
            };
            add_Operation(newData, "updateNode");
            _this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close();
            });
            _this.$emit("info", false);
          }
        });
      }
    },
  },
};
</script>

<style>
#map .btn {
  position: absolute;
  top: 30px;
  z-index: 1001;
  padding: 6px 8px;
}

.vector {
  left: 30px;
}

.satellite {
  left: 100px;
}

.traffic {
  left: 170px;
}

.address_list {
  position: absolute;
  z-index: 999999;
  top: -15px;
  left: 0px;
  width: 100%;
  background: #fff;
  padding: 10px;
  text-align: center;
  line-height: 30px;
  min-height: 150px;
}

.address_item {
  border-bottom: 1px solid #eaeaee;
  cursor: pointer;
}

.address_item:hover {
  color: #5cb6ff;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

#map .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#map .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

#map .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  /* background: var(--font-icon-white,#fff); */
  padding: 8px 24px;
  width: calc(100% - 48px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
}

#map .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
</style>
