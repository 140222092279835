<template>
  <div id="thinking">
    <el-dialog
      :model-value="dialogVisible"
      :show-close="false"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose"
      destroy-on-close
    >
      <template #header>
        <div style="width: 100%; position: relative">
          <span class="el-dialog__title">{{ languageData.mindMap }}</span>
          <button type="button" class="close" style="top: 4px; right: 0">
            <img
              v-if="hoverClose"
              src="../../assets/close_pre.png"
              width="16"
              height="16"
              @mouseout="blurClose"
              @click="handleClose"
            />
            <img
              v-else
              src="../../assets/close_nor.png"
              width="16"
              height="16"
              @mouseover="focusClose"
            />
          </button>
        </div>
      </template>
      <span>
        <div style="height: 100%">
          <div>
            <el-button size="large" @click="handle_clear">{{
              languageData.tip27
            }}</el-button>
            <el-button size="large" @click="handle_example1"
              >{{ languageData.tip28 }}1</el-button
            >
            <el-button size="large" @click="handle_example2"
              >{{ languageData.tip28 }}2</el-button
            >
            <el-button size="large" @click="handle_example3"
              >{{ languageData.tip28 }}3</el-button
            >
            <el-button size="large" @click="handle_download">{{
              languageData.download
            }}</el-button>
          </div>
          <Markdown
            ref="mardown"
            :width="70"
            :height="100"
            :cur-code="code"
            @getCode="getCode"
          />
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Markdown from "./../Markdown.vue";
import { sendUpdate, add_Operation } from "../../tools/send.js";
import { v4 as uuidv4 } from "uuid";
import { ElLoading as Loading } from "element-plus";
import LogicFlow from "@logicflow/core";
export default {
  components: {
    Markdown,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: undefined,
    },
    lf: LogicFlow,
  },
emits: ['info'],
  data() {
    return {
      languageData: {},
      code: null,
      hoverClose: false,
      codecopy: null,
      example_copy1: `# 主题
## 思路一
## 思路二
`,
      example_copy2: `# 主题
## 思路一
- 思路一（1）
- 思路一（2）
## 思路二
- 思路二（1）
- 思路二（2）
`,
      example_copy3: `# 主题
## 思路一
- 思路一（1）
- 思路一（2）
## 思路二
- 思路二（1）
- 思路二（2）
### 思路二（3）
- 思路二（3）(1)
- 思路二（3）(2)
`,
    };
  },
  watch: {
    list() {
      if (this.list.length != 0) {
        this.code = this.list[0].properties.code
          ? this.list[0].properties.code
          : this.example_copy2;
      }
    },
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "./aws-sdk-2.235.1.min.js";
    script.async = "async";
    script.onload = function () {
      var credentials = {
        accessKeyId: "AKIATLPEDU37QV5CHLMH",
        secretAccessKey: "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",
      }; //秘钥形式的登录上传
      window.AWS.config.update(credentials);
      window.AWS.config.region = "cn-northwest-1"; //设置区域
    };
    document.body.appendChild(script);
  },
  methods: {
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handle_clear() {
      this.code = this.codecopy;
    },
    handle_example1() {
      this.code = this.example_copy1;
    },
    handle_example2() {
      this.code = this.example_copy2;
    },
    handle_example3() {
      this.code = this.example_copy3;
    },
    handle_download() {
      this.$refs.mardown.handle_download();
    },
    handleClose() {
      this.downloadPng();
      this.$emit("info", false);
    },
    getCode(val) {
      this.code = val;
      if (
        val != this.example_copy1 &&
        val != this.example_copy2 &&
        val != this.example_copy3
      ) {
        this.codecopy = val;
        console.log(this.codecopy);
      }
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //2,再将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date(); // 文件最后的修改日期
      theBlob.name = fileName; // 文件名
      return new File([theBlob], fileName, {
        type: theBlob.type,
        lastModified: Date.now(),
      });
    },
    uploadImage(fileParams, loadingInstance) {
      // console.log(fileParams.file)
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      let list = _this.list;
      if (fileParams) {
        var params = {
          Key: fileParams.name,
          ContentType: fileParams.type,
          Body: fileParams,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket.upload(params, options).send(function (err, data) {
          if (err) {
            console.log(err);
          } else {
            list[0].properties.thinkingContent = data.Location;
            list[0].properties.code = _this.code;
            _this.$props.lf.getNodeModelById(list[0].id).setProperties({
              thinkingContent: list[0].properties.thinkingContent,
              code: list[0].properties.code,
            });
            sendUpdate(list, [], "update", 0);
            let newData = {
              nodes: list,
              edges: [],
              backgroundUrl: localStorage.getItem("background"),
            };
            add_Operation(newData, "updateNode");
            _this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close();
            });
          }
        });
      }
    },
    downloadPng() {
      const down = document.querySelector(`#markmap`);
      const xmlDom = new XMLSerializer().serializeToString(down); //将设置好颜色的div转为xml
      const svgDataBase64 = btoa(unescape(encodeURIComponent(xmlDom)));
      const src = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`;

      let loadingInstance = Loading.service({ fullscreen: true });
      let blob = this.dataURLtoBlob(src);
      // 拿到文件名
      let fileName = uuidv4() + ".png";
      // 2，在转为 file类型
      let file1 = this.blobToFile(blob, fileName);
      this.uploadImage(file1, loadingInstance);
    },
  },
};
</script>

<style>
#thinking .el-dialog {
  height: 85% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#thinking .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

#thinking .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  padding: 8px 24px;
  width: calc(100% - 48px);
  height: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
}

#thinking .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#thinking .code {
  width: 30%;
  height: 100%;
  border: 1px solid rgb(209 213 219);
  background: rgb(255, 255, 255);
}

#thinking .cm-editor {
  height: 100%;
  border: 1px solid rgb(209 213 219);
  width: 30%;
  background: rgb(255, 255, 255);
  box-sizing: unset;
}
</style>
