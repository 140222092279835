// 基础图形
import CircleNode from "./basic/CircleNode";
import RectNode from "./basic/RectNode";
import RectRadiusNode from "./basic/RectRadiusNode";
import EllipseNode from "./basic/EllipseNode";
import TextNode from "./basic/TextNode";
import DiamondNode from "./basic/DiamondNode";
// path绘制的个性化图形
import CylindeNode from "./path/CylindeNode";
import TriangleNode from "./path/TriangleNode";
import ParallelogramNode from "./path/ParallelogramNode";
import ActorNode from "./path/ActorNode";
import StarNode from "./path/Star";
import PentagonNode from "./path/PentagonNode";
import HexagonNode from "./path/HexagonNode";
import SeptagonNode from "./path/SeptagonNode";
import HeptagonNode from "./path/HeptagonNode";
import TrapezoidNode from "./path/TrapezoidNode";
import CrossNode from "./path/CrossNode";
import MinusNode from "./path/MinusNode";
import TimesNode from "./path/TimesNode";
import DivideNode from "./path/DivideNode";
// 多边形绘制的箭头
import LeftArrow from "./arrow/LeftArrow";
import RightArrow from "./arrow/RightArrow";
import HorizontalArrow from "./arrow/HorizontalArrowNode";
import UpArrow from "./arrow/UpArrowNode";
import DownArrow from "./arrow/DownArrowNode";
import VerticalArrow from "./arrow/VerticalArrowNode";
// image绘制图片节点
import ImageSetting from "./image/Setting";
import ImageUser from "./image/User";
import ImageCloud from "./image/Cloud";
import PocketMonster1 from "./image/PocketMonster1";
import PocketMonster2 from "./image/PocketMonster2";
import Student1 from "./image/Student-1";
import Student2 from "./image/Student-2";
import Student3 from "./image/Student-3";
import Student4 from "./image/Student-4";
import DrawNode from "./image/drawNode";

// image绘制左上角icon节点
import IconMessage from "./icon/Message";
// image绘制资源节点
import CardNode from "./resources/Card";
import SelectNode from "./resources/Select";
import ThinkingCardNode from "./thinking/ThinkingCard";
import WhiteBoardNode from "./whiteboard/Whiteboard";
import AiNpc from "./resources/AINPC";

// image绘制AI节点
import AICard from "./AI/AICard";
import AINewCard from "./AI/AINewCard";
//地图卡片
import Map from "./map/Map";
import Camera from "./camera/Camera";
// image绘制工具节点
import Start from "./tools/Start";
import Theme from "./tools/Theme";
import Stage from "./tools/Stage";
import Task from "./tools/Task";
import HomeWork from "./tools/HomeWork";

// image绘制Markdown节点
import Markdown from "./markdown/MarkDown";

// image绘制左上角互动类节点
import IconCountDown from "./interactive/CountDown";
// image绘制左上角思维类节点
import IconThinkingGrid from "./thinking/ThinkingGrid";
import IconElWhiteboard from "./thinking/ElWhiteboard";
import IconNote from "./thinking/Note";
import IconCollaborativeDocument from "./thinking/CollaborativeDocument";
import IconMindMap from "./thinking/MindMap";
// image绘制左上角评价类节点
import IconChoiceQuestion from "./evaluation/ChoiceQuestion";
import IconQATools from "./evaluation/QATools";
import IconJobSubmission from "./evaluation/JobSubmission";
import IconStudentMutual from "./evaluation/StudentMutual";
import IconMultipleChoice from "./evaluation/MultipleChoice";
// 注册边
import RedPloyline from "./edge/RedPolyline";
import GreenPloyline from "./edge/GreenPolyline";
import DashedPloyline from "./edge/DashedPolyline";
import Ployline from "./edge/Polyline";
import RedLine from "./edge/RedLine";
import GreenLine from "./edge/GreenLine";
import DashedLine from "./edge/DashedLine";
import Line from "./edge/Line";
import RedBezier from "./edge/RedBezier";
import GreenBezier from "./edge/GreenBezier";
import DashedBezier from "./edge/DashedBezier";
import Bezier from "./edge/Bezier";
import RedCurved from "./edge/RedCurved";
import GreenCurved from "./edge/GreenCurved";
import DashedCurved from "./edge/DashedCurved";
import Curved from "./edge/Curved";
import CustomStraightLine from "./edge/CustomStraightLine";
import CustomDottedLine from "./edge/CustomDottedLine";

//注册自定义分组
import CustomGroup from "./group/MyGroupNode";
import CustomGroupNew from "./group/MyGroupNodeNew";
import Project from "./analyse/Project";
import Student from "./analyse/Student";
import Undertake from "./analyse/Undertake";
import Participate from "./analyse/Participate";

export const registerCustomElement = (lf) => {
  // 注册基础图形
  lf.register(CircleNode);
  lf.register(RectNode);
  lf.register(RectRadiusNode);
  lf.register(EllipseNode);
  lf.register(DiamondNode);
  lf.register(TextNode);
  // 注册path绘制的个性化图形
  lf.register(CylindeNode);
  lf.register(TriangleNode);
  lf.register(ParallelogramNode);
  lf.register(ActorNode);
  lf.register(StarNode);
  lf.register(PentagonNode);
  lf.register(HexagonNode);
  lf.register(SeptagonNode);
  lf.register(HeptagonNode);
  lf.register(TrapezoidNode);
  lf.register(CrossNode);
  lf.register(MinusNode);
  lf.register(TimesNode);
  lf.register(DivideNode);
  // 注册多边形绘制的箭头
  lf.register(LeftArrow);
  lf.register(RightArrow);
  lf.register(HorizontalArrow);
  lf.register(UpArrow);
  lf.register(DownArrow);
  lf.register(VerticalArrow);
  // 注册image绘制图片节点
  lf.register(ImageSetting);
  lf.register(ImageUser);
  lf.register(ImageCloud);
  lf.register(PocketMonster1);
  lf.register(PocketMonster2);
  lf.register(AiNpc);
  lf.register(Student1);
  lf.register(Student2);
  lf.register(Student3);
  lf.register(Student4);
  lf.register(DrawNode);

  // 注册image绘制左上角icon节点
  lf.register(IconMessage);
  // 注册绘制资源卡片节点
  lf.register(CardNode);
  lf.register(SelectNode);
  lf.register(ThinkingCardNode);
  lf.register(WhiteBoardNode);
  // 注册绘制工具卡片节点
  lf.register(Start);
  lf.register(Theme);
  lf.register(Stage);
  lf.register(Task);
  lf.register(HomeWork);
  // 注册绘制Markdown卡片节点
  lf.register(Markdown)
  // 注册image绘制左上角互动类节点
  lf.register(IconCountDown);
  // 注册image绘制左上角思维类节点
  lf.register(IconThinkingGrid);
  lf.register(IconElWhiteboard);
  lf.register(IconNote);
  lf.register(IconCollaborativeDocument);
  lf.register(IconMindMap);
  // 注册image绘制左上角评价类节点
  lf.register(IconChoiceQuestion);
  lf.register(IconQATools);
  lf.register(IconJobSubmission);
  lf.register(IconStudentMutual);
  lf.register(IconMultipleChoice);
  // 注册边
  lf.register(Ployline);
  lf.register(RedPloyline);
  lf.register(GreenPloyline);
  lf.register(DashedPloyline);
  lf.register(Line);
  lf.register(RedLine);
  lf.register(GreenLine);
  lf.register(DashedLine);
  lf.register(Bezier);
  lf.register(RedBezier);
  lf.register(GreenBezier);
  lf.register(DashedBezier);
  lf.register(Curved);
  lf.register(RedCurved);
  lf.register(GreenCurved);
  lf.register(DashedCurved);
  lf.register(CustomStraightLine);
  lf.register(CustomDottedLine);

  //注册自定义分组
  lf.register(CustomGroup);
  lf.register(CustomGroupNew);

  //注册AI卡片
  lf.register(AICard);
  lf.register(AINewCard);
  //地图卡片
  lf.register(Map);
  // 注册分析节点
  lf.register(Project);
  lf.register(Student);
  lf.register(Undertake);
  lf.register(Participate);
  lf.register(Camera);
};
