<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
      <path
        d="M933.647059 0h-210.82353v90.352941h180.705883a30.117647 30.117647 0 0 1 30.117647 30.117647v180.705883h90.352941V90.352941a90.352941 90.352941 0 0 0-90.352941-90.352941zM361.411765 0h301.17647v90.352941H361.411765zM933.647059 361.411765h90.352941v301.17647h-90.352941zM361.411765 933.647059h301.17647v90.352941H361.411765zM0 361.411765h90.352941v301.17647H0zM90.352941 903.529412v-180.705883H0v210.82353a90.352941 90.352941 0 0 0 90.352941 90.352941h210.82353v-90.352941H120.470588a30.117647 30.117647 0 0 1-30.117647-30.117647zM933.647059 903.529412a30.117647 30.117647 0 0 1-30.117647 30.117647h-180.705883v90.352941h210.82353a90.352941 90.352941 0 0 0 90.352941-90.352941v-210.82353h-90.352941zM0 90.352941v210.82353h90.352941V120.470588a30.117647 30.117647 0 0 1 30.117647-30.117647h180.705883V0H90.352941a90.352941 90.352941 0 0 0-90.352941 90.352941z"
        p-id="1890">
      </path>
    </svg> -->

  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMxOyYjMTg4
OyYjMTY5OyYjMjMwOyYjMTQ4OyYjMTkwOyYjMjI4OyYjMTg3OyYjMTY1OyYjMjMzOyYjMTI4OyYj
MTMwOyYjMjI5OyYjMTg2OyYjMTQ4OyYjMjI5OyYjMTY0OyYjMTY3OyYjMjI5OyYjMTc2OyYjMTQz
OyI+CjxwYXRoIGlkPSJWZWN0b3IgNDYgKFN0cm9rZSkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xp
cC1ydWxlPSJldmVub2RkIiBkPSJNMiAyLjVDMiAyLjIyMzg2IDIuMjIzODYgMiAyLjUgMkgxMy41
QzEzLjc3NjEgMiAxNCAyLjIyMzg2IDE0IDIuNVY2QzE0IDYuMjc2MTQgMTMuNzc2MSA2LjUgMTMu
NSA2LjVIMi41QzIuMjIzODYgNi41IDIgNi4yNzYxNCAyIDZWMi41Wk0zIDNWNS41SDEzVjNIM1oi
IGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuOSIvPgo8cGF0aCBpZD0iVmVjdG9yIDQ3IChT
dHJva2UpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgOEMy
IDcuNzIzODYgMi4yMjM4NiA3LjUgMi41IDcuNUgxMy41QzEzLjc3NjEgNy41IDE0IDcuNzIzODYg
MTQgOFYxMy41QzE0IDEzLjc3NjEgMTMuNzc2MSAxNCAxMy41IDE0SDIuNUMyLjIyMzg2IDE0IDIg
MTMuNzc2MSAyIDEzLjVWOFpNMyA4LjVWMTNIMTNWOC41SDNaIiBmaWxsPSJibGFjayIgZmlsbC1v
cGFjaXR5PSIwLjkiLz4KPHBhdGggaWQ9IlZlY3RvciA0OCAoU3Ryb2tlKSIgZmlsbC1ydWxlPSJl
dmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDkuNUM1LjI3NjE0IDkuNSA1LjUgOS43
MjM4NiA1LjUgMTBWMTEuNUM1LjUgMTEuNzc2MSA1LjI3NjE0IDEyIDUgMTJDNC43MjM4NiAxMiA0
LjUgMTEuNzc2MSA0LjUgMTEuNVYxMEM0LjUgOS43MjM4NiA0LjcyMzg2IDkuNSA1IDkuNVoiIGZp
bGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuOSIvPgo8cGF0aCBpZD0iVmVjdG9yIDQ5IChTdHJv
a2UpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcgOS41Qzcu
Mjc2MTQgOS41IDcuNSA5LjcyMzg2IDcuNSAxMFYxMS41QzcuNSAxMS43NzYxIDcuMjc2MTQgMTIg
NyAxMkM2LjcyMzg2IDEyIDYuNSAxMS43NzYxIDYuNSAxMS41VjEwQzYuNSA5LjcyMzg2IDYuNzIz
ODYgOS41IDcgOS41WiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC45Ii8+CjxwYXRoIGlk
PSJWZWN0b3IgNTAgKFN0cm9rZSkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVu
b2RkIiBkPSJNOSA5LjVDOS4yNzYxNCA5LjUgOS41IDkuNzIzODYgOS41IDEwVjExLjVDOS41IDEx
Ljc3NjEgOS4yNzYxNCAxMiA5IDEyQzguNzIzODYgMTIgOC41IDExLjc3NjEgOC41IDExLjVWMTBD
OC41IDkuNzIzODYgOC43MjM4NiA5LjUgOSA5LjVaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5
PSIwLjkiLz4KPHBhdGggaWQ9IlZlY3RvciA1MSAoU3Ryb2tlKSIgZmlsbC1ydWxlPSJldmVub2Rk
IiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMSA5LjVDMTEuMjc2MSA5LjUgMTEuNSA5LjcyMzg2
IDExLjUgMTBWMTEuNUMxMS41IDExLjc3NjEgMTEuMjc2MSAxMiAxMSAxMkMxMC43MjM5IDEyIDEw
LjUgMTEuNzc2MSAxMC41IDExLjVWMTBDMTAuNSA5LjcyMzg2IDEwLjcyMzkgOS41IDExIDkuNVoi
IGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuOSIvPgo8L2c+Cjwvc3ZnPgo="
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
