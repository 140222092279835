import { BezierEdge, BezierEdgeModel } from "@logicflow/core";
import {
  getShapeStyleFuction,
  getTextStyleFunction,
} from "../getShapeStyleUtil";

// 贝塞尔曲线
class Model extends BezierEdgeModel {
  constructor(data, graphModel) {
    super(data, graphModel);
    this.text.editable = false; // 禁止节点文本编辑
    this.strokeWidth = 1;
  }
  getTextStyle() {
    const style = super.getTextStyle();
    return getTextStyleFunction(style, this.properties);
  }

  getEdgeStyle() {
    const attributes = super.getEdgeStyle();
    const properties = this.properties;
    const style = getShapeStyleFuction(attributes, properties);
    style.strokeWidth = 1;
    return { ...style, fill: "none", stroke: "#FF0000" };
  }
}
export default {
  type: "pro-red-bezier",
  view: BezierEdge,
  model: Model,
};
