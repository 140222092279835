import { CurvedEdge, CurvedEdgeModel } from "@logicflow/extension";

// 圆角折线
class myCurvedEdgeModel extends CurvedEdgeModel {
  initEdgeData(data) {
    super.initEdgeData(data);
    this.radius = 5;
    this.text.editable = false; // 禁止节点文本编辑
  }
  getEdgeStyle() {
    const style = super.getEdgeStyle();
    style.strokeWidth = 1;
    style.stroke = "#000000";
    return style;
  }
  setAttributes() {
    this.isAnimation = true;
  }
  getEdgeAnimationStyle() {
    const style = super.getEdgeAnimationStyle();
    style.strokeDasharray = "3 3";
    // style.animationDuration = "10s";
    style.stroke = "#000000";
    return style;
  }
}

export default {
  type: "pro-dashed-curved",
  view: CurvedEdge,
  model: myCurvedEdgeModel,
};
