<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
    <path d="M943.8 892.5l-201.4-201c24.2-29 43.9-61.3 58.7-96.3 20-47.3 30.1-97.6 30.1-149.4s-10.1-102-30.1-149.4c-19.3-45.7-47-86.7-82.1-122-35.2-35.2-76.2-62.9-121.9-82.2-47.3-20-97.5-30.2-149.3-30.2-51.8 0-102 10.2-149.3 30.2-45.7 19.3-86.7 47-121.9 82.2s-62.8 76.3-82.1 122c-20 47.3-30.1 97.6-30.1 149.4s10.1 102 30.1 149.4c19.3 45.7 47 86.7 82.1 122 35.2 35.2 76.2 62.9 121.9 82.2 47.3 20 97.5 30.2 149.3 30.2 51.7 0 102-10.2 149.3-30.2 34.6-14.7 66.6-34.1 95.3-58l201.5 201c6.9 6.9 15.9 10.3 24.9 10.3 9.1 0 18.1-3.5 25-10.4 13.8-13.7 13.8-36.1 0-49.8zM669.7 666.6c-0.4 0.4-0.8 0.7-1.2 1.1-0.3 0.3-0.6 0.6-0.8 0.9-59 58.3-137 90.4-219.9 90.4-83.5 0-162.1-32.6-221.2-91.7-59.1-59.1-91.6-137.8-91.6-221.4s32.5-162.3 91.6-221.4c59.1-59.1 137.6-91.7 221.2-91.7s162.1 32.6 221.2 91.7c59.1 59.1 91.6 137.8 91.6 221.4 0 83.3-32.3 161.6-90.9 220.7z" p-id="813">
    </path>
    <path d="M573.7 419H473v-98c0-19.5-13-35.3-32.5-35.3S408 301.5 408 321v98H305.3c-19.5 0-35.3 13-35.3 32.5s15.8 32.5 35.3 32.5H408v105.4c0 19.5 13 35.3 32.5 35.3s32.5-15.8 32.5-35.3V484h100.7c19.5 0 35.3-13 35.3-32.5S593.2 419 573.7 419z" p-id="814">
    </path>
  </svg> -->

  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 19 20"
    enable-background="new 0 0 19 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="19"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMwOyYjMTQ4
OyYjMTkwOyYjMjI5OyYjMTY0OyYjMTY3OyI+CjxwYXRoIGlkPSJFbGxpcHNlIDQgKFN0cm9rZSki
IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy41Njc5NSAzLjAx
MjM1QzUuMDUxOTYgMy4wMTIzNSAzLjAxMjM1IDUuMDUxOTYgMy4wMTIzNSA3LjU2Nzk1QzMuMDEy
MzUgMTAuMDgzOSA1LjA1MTk2IDEyLjEyMzUgNy41Njc5NSAxMi4xMjM1QzguODI2MDggMTIuMTIz
NSA5Ljk2NDI5IDExLjYxNDIgMTAuNzg5MiAxMC43ODkyQzExLjYxNDIgOS45NjQyOSAxMi4xMjM1
IDguODI2MDggMTIuMTIzNSA3LjU2Nzk1QzEyLjEyMzUgNS4wNTE5NiAxMC4wODM5IDMuMDEyMzUg
Ny41Njc5NSAzLjAxMjM1Wk0yIDcuNTY3OTVDMiA0LjQ5Mjg2IDQuNDkyODYgMiA3LjU2Nzk1IDJD
MTAuNjQzIDIgMTMuMTM1OSA0LjQ5Mjg2IDEzLjEzNTkgNy41Njc5NUMxMy4xMzU5IDguOTIzMDQg
MTIuNjUxMyAxMC4xNjU3IDExLjg0NjggMTEuMTMwOUwxMy42NDIxIDEyLjkyNjJDMTMuODM5OCAx
My4xMjM5IDEzLjgzOTggMTMuNDQ0NCAxMy42NDIxIDEzLjY0MjFDMTMuNDQ0NCAxMy44Mzk4IDEz
LjEyMzkgMTMuODM5OCAxMi45MjYyIDEzLjY0MjFMMTEuMTMwOSAxMS44NDY4QzEwLjE2NTcgMTIu
NjUxMyA4LjkyMzA0IDEzLjEzNTkgNy41Njc5NSAxMy4xMzU5QzQuNDkyODYgMTMuMTM1OSAyIDEw
LjY0MyAyIDcuNTY3OTVaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiLz4KPHBhdGgg
aWQ9IlVuaW9uIiBkPSJNNy45MjEwNCA1LjkyMTA1QzcuOTIxMDQgNS42ODg1MSA3LjczMjUzIDUu
NSA3LjQ5OTk5IDUuNUM3LjI2NzQ1IDUuNSA3LjA3ODkzIDUuNjg4NTEgNy4wNzg5MyA1LjkyMTA1
VjcuMDc4OTVINS45MjEwNUM1LjY4ODUxIDcuMDc4OTUgNS41IDcuMjY3NDYgNS41IDcuNUM1LjUg
Ny43MzI1NCA1LjY4ODUxIDcuOTIxMDUgNS45MjEwNSA3LjkyMTA1SDcuMDc4OTNWOS4wNzg5NUM3
LjA3ODkzIDkuMzExNDkgNy4yNjc0NSA5LjUgNy40OTk5OSA5LjVDNy43MzI1MyA5LjUgNy45MjEw
NCA5LjMxMTQ5IDcuOTIxMDQgOS4wNzg5NVY3LjkyMTA1SDkuMDc4OTVDOS4zMTE0OSA3LjkyMTA1
IDkuNSA3LjczMjU0IDkuNSA3LjVDOS41IDcuMjY3NDYgOS4zMTE0OSA3LjA3ODk1IDkuMDc4OTUg
Ny4wNzg5NUg3LjkyMTA0VjUuOTIxMDVaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjki
Lz4KPC9nPgo8L3N2Zz4K"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
