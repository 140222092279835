<template>
  <div id="runDemo">
    <div v-if="!isShowMulu" style="
        height: calc(100%);
        display: flex;
        flex-direction: row;
        background: #ffffff;
      " class="animate__animated animate__zoomIn">
      <button v-if="isShowMulu" type="button" class="close" style="top: 4px">
        <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16" @mouseout="blurClose"
          @click="handleClose" />
        <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
      </button>
      <div id="leftSide" class="left-side" :style="fullscreen ? '' : ''">
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid rgb(202, 209, 220);
          ">
          <div v-if="themeName != ''" style="
              width: 140px;
              margin-left: 10px;
              margin-bottom: 5px;
              color: black;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-word;
              color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
              font-family: SimSun;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            ">
            {{ themeName }}
          </div>
        </div>
        <div :style="stageDatas.length != 0
            ? 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;height: calc(100% - 35px);'
            : 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;margin-top: 15px;height: calc(100% - 50px);'
          " class="menuList">
          <template v-for="(item, i) in newnavigationListNew" :key="'menu_' + i">
            <div v-if="item.type == 'icon-stage'" style="width: 100%; cursor: pointer">
              <div style="
                  width: calc(100% - 16px);
                  display: flex;
                  flex-direction: row;
                  padding: 12px 8px;
                  align-items: center;
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;
                " :style="selectMulu == item.id
                    ? 'background: var(--brand-light, #e0eafb);color: var(--brand-click, #0061ff);'
                    : 'color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));'
                  ">
                <div v-if="
                  selectMulu == item.id &&
                  item.isFold &&
                  item.child.length != 0
                " class="closeTri" style="cursor: pointer" @click="hideStageNew(i)">
                  <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="item.isFold && item.child.length != 0" class="closeTri" style="cursor: pointer"
                  @click="hideStageNew(i)">
                  <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="
                  selectMulu == item.id &&
                  !item.isFold &&
                  item.child.length != 0
                " class="closeTri" style="cursor: pointer" @click="showStageNew(i)">
                  <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="!item.isFold && item.child.length != 0" class="closeTri" style="cursor: pointer"
                  @click="showStageNew(i)">
                  <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="item.child.length == 0 || stageDatas.length != 0" class="closeTri">
                  <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" style="visibility: hidden" />
                </div>
                <div :style="selectMulu == item.id
                    ? 'background: var(--brand-light, #e0eafb);width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--brand-click, #0061ff);font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;'
                    : 'width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;'
                  " @click="navToStage(i)">
                  <div class="stageName-1" style="width: auto">
                    {{ languageData.stageName }}{{ i + 1 }}:
                  </div>
                  <div style="
                      margin-left: 10px;
                      width: auto;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      word-break: break-word;
                    ">
                    {{ item.properties.stageName }}
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(itemNew, j) in item.child" :key="'menu_' + i + '_' + j">
              <a v-if="stageIndex - 1 == i && item.isFold" style="text-decoration: none"
                @click.stop="navTo(itemNew.id)">
                <div v-if="itemNew.type == 'icon-task'" :class="selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'"
                  :data-id="itemNew.id">
                  <div v-if="
                    selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    itemNew.child.length == 0 || stageDatas.length != 0
                  " class="closeTri">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                      style="visibility: hidden" />
                  </div>
                  <span :data-id="itemNew.id" :class="selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1'
                    " style="cursor: pointer">{{ languageData.taskTip }}
                    {{
                      itemNew.properties.taskTitle
                        ? itemNew.properties.taskTitle
                        : ""
                    }}</span>
                </div>
              </a>
              <a v-else-if="stageIndex - 1 != i && item.isFold" style="text-decoration: none"
                @click.stop="navToTask(i, j)">
                <div v-if="itemNew.type == 'icon-task'" :class="selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'"
                  :data-id="itemNew.id">
                  <div v-if="
                    selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    itemNew.child.length == 0 || stageDatas.length != 0
                  " class="closeTri">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                      style="visibility: hidden" />
                  </div>
                  <span :data-id="itemNew.id" :class="selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1'
                    " style="cursor: pointer">{{ languageData.taskTip }}
                    {{
                      itemNew.properties.taskTitle
                        ? itemNew.properties.taskTitle
                        : ""
                    }}</span>
                </div>
              </a>
              <template v-for="(Item, index) in itemNew.child" :key="'menu_' + i + '_' + j + '_' + index">
                <a v-if="
                  stageIndex - 1 == i &&
                  itemNew.isFold &&
                  item.isFold &&
                  stageDatas.length == 0
                " style="text-decoration: none" @click="navTo(Item.id)">
                  <div v-if="Item.type == 'icon-card'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.title ? Item.properties.title : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-select'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.selectTitle
                          ? Item.properties.selectTitle
                          : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-thinking'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.thinkingTitle
                          ? Item.properties.thinkingTitle
                          : languageData.thinkingCard
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-AI' || Item.type == 'icon-markdown'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-new-AI'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-whiteboard'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.whiteboardTitle
                          ? Item.properties.whiteboardTitle
                          : languageData.whiteboard_card
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-map'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.map_card }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-camera'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.camera_card }}</span>
                  </div>
                </a>

                <a v-else-if="
                  stageIndex - 1 != i &&
                  itemNew.isFold &&
                  item.isFold &&
                  stageDatas.length == 0
                " style="text-decoration: none" @click="navToOther(i, j, index)">
                  <div v-if="Item.type == 'icon-card'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.title ? Item.properties.title : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-select'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.selectTitle
                          ? Item.properties.selectTitle
                          : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-thinking'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.thinkingTitle
                          ? Item.properties.thinkingTitle
                          : languageData.thinkingCard
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-AI' || Item.type == 'icon-markdown'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-new-AI'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-whiteboard'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.whiteboardTitle
                          ? Item.properties.whiteboardTitle
                          : languageData.whiteboard_card
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-map'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.map_card }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-camera'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.camera_card }}</span>
                  </div>
                </a>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div v-else style="height: calc(100%); display: flex; flex-direction: column">
      <div v-if="isShowMulu" style="
          padding: 12px 16px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 20px 20px 0px 0px;
          border-bottom: 1px solid var(--bg3, #e7e7e7);
          background: #fff;
        ">
        <span style="
            color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
            text-align: center;
            font-family: SimSun;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          ">
          {{ languageData.directory }}
        </span>
      </div>
      <button type="button" class="close">
        <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16" @mouseout="blurClose"
          @click="handleClose" />
        <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
      </button>
      <div id="leftSide" class="left-side" :style="fullscreen ? '' : ''">
        <div v-if="themeName != ''" style="display: flex; flex-direction: row; align-items: center">
          <div style="
              width: calc(100% - 20px);
              margin-left: 10px;
              color: black;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-word;
              color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
              font-family: SimSun;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            " :title="themeName">
            {{ themeName }}
          </div>
        </div>
        <div :style="themeName != ''
            ? 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;margin-top: 15px;height: calc(100% - 47px);'
            : 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;height: calc(100% - 8px);'
          " class="menuList">
          <template v-for="(item, i) in newnavigationListNew" :key="'menu_' + i">
            <div v-if="item.type == 'icon-stage'" style="width: 100%; cursor: pointer">
              <div style="
                  width: calc(100% - 16px);
                  display: flex;
                  flex-direction: row;
                  padding: 12px 8px;
                  align-items: center;
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;
                " :style="selectMulu == item.id
                    ? 'background: var(--brand-light, #e0eafb);color: var(--brand-click, #0061ff);'
                    : 'color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));'
                  ">
                <div v-if="
                  selectMulu == item.id &&
                  item.isFold &&
                  item.child.length != 0
                " class="closeTri" style="cursor: pointer; visibility: hidden" @click="hideStageNew(i)">
                  <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="item.isFold && item.child.length != 0" class="closeTri"
                  style="cursor: pointer; visibility: hidden" @click="hideStageNew(i)">
                  <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="
                  selectMulu == item.id &&
                  !item.isFold &&
                  item.child.length != 0
                " class="closeTri" style="cursor: pointer; visibility: hidden" @click="showStageNew(i)">
                  <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="!item.isFold && item.child.length != 0" class="closeTri"
                  style="cursor: pointer; visibility: hidden" @click="showStageNew(i)">
                  <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16"
                    :style="item.isFold ? '' : 'transform: rotate(-90deg);'" />
                </div>
                <div v-else-if="item.child.length == 0 || stageDatas.length != 0" class="closeTri">
                  <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" style="visibility: hidden" />
                </div>
                <div :style="selectMulu == item.id
                    ? 'background: var(--brand-light, #e0eafb);width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--Brand-Normal, #3681FC);font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 600;line-height: 20px;'
                    : 'width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 600;line-height: 20px;'
                  " @click="navToStage(i)">
                  <div class="stageName-1" style="width: auto">
                    {{ languageData.stageName }}{{ i + 1 }}:
                  </div>
                  <div style="
                      margin-left: 10px;
                      width: auto;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      word-break: break-word;
                    ">
                    {{ item.properties.stageName }}
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(itemNew, j) in item.child" :key="'menu_' + i + '_' + j">
              <a v-if="stageIndex - 1 == i && item.isFold" style="text-decoration: none"
                @click.stop="navTo(itemNew.id)">
                <div v-if="itemNew.type == 'icon-task'" :class="selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'"
                  :data-id="itemNew.id">
                  <div v-if="
                    selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    itemNew.child.length == 0 || stageDatas.length != 0
                  " class="closeTri">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                      style="visibility: hidden" />
                  </div>
                  <span :data-id="itemNew.id" :class="selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1'
                    " style="cursor: pointer">{{ languageData.taskTip }}
                    {{
                      itemNew.properties.taskTitle
                        ? itemNew.properties.taskTitle
                        : ""
                    }}</span>
                </div>
              </a>
              <a v-else-if="stageIndex - 1 != i && item.isFold" style="text-decoration: none"
                @click.stop="navToTask(i, j)">
                <div v-if="itemNew.type == 'icon-task'" :class="selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'"
                  :data-id="itemNew.id">
                  <div v-if="
                    selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="hideTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    stageDatas.length == 0
                  " class="closeTri" style="cursor: pointer; visibility: hidden" @click.stop="showTaskNew(i, j)">
                    <img src="../../assets/chevron-up_nor.png" alt="" width="16" height="16" :style="itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                      " />
                  </div>
                  <div v-else-if="
                    itemNew.child.length == 0 || stageDatas.length != 0
                  " class="closeTri">
                    <img src="../../assets/chevron-up_pre.png" alt="" width="16" height="16"
                      style="visibility: hidden" />
                  </div>
                  <span :data-id="itemNew.id" :class="selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1'
                    " style="cursor: pointer">{{ languageData.taskTip }}
                    {{
                      itemNew.properties.taskTitle
                        ? itemNew.properties.taskTitle
                        : ""
                    }}</span>
                </div>
              </a>
              <template v-for="(Item, index) in itemNew.child" :key="'menu_' + i + '_' + j + '_' + index">
                <a v-if="
                  stageIndex - 1 == i &&
                  itemNew.isFold &&
                  item.isFold &&
                  stageDatas.length == 0
                " style="text-decoration: none" @click="navTo(Item.id)">
                  <div v-if="Item.type == 'icon-card'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.title ? Item.properties.title : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-select'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.selectTitle
                          ? Item.properties.selectTitle
                          : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-thinking'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.thinkingTitle
                          ? Item.properties.thinkingTitle
                          : languageData.thinkingCard
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-AI' || Item.type == 'icon-markdown'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-new-AI'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-whiteboard'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.whiteboardTitle
                          ? Item.properties.whiteboardTitle
                          : languageData.whiteboard_card
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-map'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.map_card }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-camera'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.camera_card }}</span>
                  </div>
                </a>

                <a v-else-if="
                  stageIndex - 1 != i &&
                  itemNew.isFold &&
                  item.isFold &&
                  stageDatas.length == 0
                " style="text-decoration: none" @click="navToOther(i, j, index)">
                  <div v-if="Item.type == 'icon-card'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.title ? Item.properties.title : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-select'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.selectTitle
                          ? Item.properties.selectTitle
                          : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-thinking'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.thinkingTitle
                          ? Item.properties.thinkingTitle
                          : languageData.thinkingCard
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-AI' || Item.type == 'icon-markdown'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-new-AI'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.topic ? Item.properties.topic : ""
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-whiteboard'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{
                        Item.properties.whiteboardTitle
                          ? Item.properties.whiteboardTitle
                          : languageData.whiteboard_card
                      }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-map'" :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'"
                    :data-id="Item.id" style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.map_card }}</span>
                  </div>
                  <div v-else-if="Item.type == 'icon-camera'"
                    :class="selectMulu == Item.id ? 'biaoti-select' : 'biaoti'" :data-id="Item.id"
                    style="padding: 8px 16px 8px 70px; width: calc(100% - 86px)">
                    <span :data-id="Item.id" :class="selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other'
                      ">{{ languageData.camera_card }}</span>
                  </div>
                </a>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  props: {
    navigationList: {
      type: Array,
      default: undefined,
    },
    themeName: {
      type: String,
      default: '',
    },
    selectMulu: {
      type: String,
      default: '',
    },
    sourceList: {
      type: Array,
      default: undefined,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    isHomeWork: {
      type: Boolean,
      default: false,
    },
    isShowMulu: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setIsShowMulu', 'createDemoList', 'setSelectMulu'],
  data() {
    return {
      letterList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      Radio: "",
      selectRadio: "",
      isAnswer: false,
      selectDialogVisible: false,
      selectList: [],
      answerContent: {},
      currentIndex: 0,
      dataList: [],
      nodes: [],
      selectIds: [],
      stageDatas: [],
      taskData: [],
      newnavigationListNew: [],
      hoverMax: false,
      hoverRecovery: false,
      hoverDownload: false,
      hoverClose: false,
      fullscreen: false,
      // disX: 0,
      disY: 0,
      down: false,
      offsetTop: 0,
      languageData: {},
      value: "",
      stageIndex: 0,
      isRun: true,
      isImmersive: true,
      newDataList: [],
      DemoLists: [],
      hide: false,
    };
  },
  watch: {

  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    this.newnavigationListNew = this.navigationList;
  },
  methods: {
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handleClose() {
      this.$emit("setIsShowMulu", false);
    },
    createProjection() {
      this.$emit("createDemoList", this.DemoLists);
    },
    navToStage(i) {
      let navigationList = this.newnavigationListNew;
      this.$emit("setSelectMulu", navigationList[i].id);
    },
    showStageNew(i) {
      let navigationList = this.newnavigationListNew;
      this.newnavigationListNew = [];
      navigationList[i].isFold = true;
      this.newnavigationListNew = navigationList;
    },
    hideStageNew(i) {
      let navigationList = this.newnavigationListNew;
      this.newnavigationListNew = [];
      navigationList[i].isFold = false;
      this.newnavigationListNew = navigationList;
    },
    showTaskNew(i, j) {
      let navigationList = this.newnavigationListNew;
      this.newnavigationListNew = [];
      navigationList[i].child[j].isFold = true;
      this.newnavigationListNew = navigationList;
    },
    hideTaskNew(i, j) {
      let navigationList = this.newnavigationListNew;
      this.newnavigationListNew = [];
      navigationList[i].child[j].isFold = false;
      this.newnavigationListNew = navigationList;
    },
    navToTask(i, j) {
      let navigationList = this.newnavigationListNew;
      this.$emit("setSelectMulu", navigationList[i].child[j].id);
    },
    navToOther(i, j, index) {
      let navigationList = this.newnavigationListNew;
      this.$emit("setSelectMulu", navigationList[i].child[j].child[index].id);
    },
  },
};
</script>

<style>
#runDemo .left-side {
  background: #ffffff;
  padding: 24px 16px;
  border-right: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-wh-2, rgba(255, 255, 255, 0.55));
  border-bottom-left-radius: 12px;
  width: calc(100% - 32px);
}

#runDemo .right-side {
  width: calc(100% - 300px);
  border-bottom-right-radius: 12px;
  background: var(--font-icon-white, #fff);
}

.right_top {
  height: auto;
  line-height: 40px;
  background: #ffffff;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  border-radius: 12px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  padding: 20px;
}

#runDemo .right_top {
  height: auto;
  line-height: 1;
  box-shadow: none;
  border-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 8px 24px;
}

#runDemo .right_top p {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

#runDemo .right_top p {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

#runDemo .right_top p span {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

#runDemo .right_top .span_ai {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.right_top_title {
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  font-family: SimSun;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.right_bottom {
  height: 175px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 47, 130, 0.08);
  border-radius: 10px;
  margin-left: 25px;
  margin-bottom: 25px;
  padding: 20px;
}

.right_answer {
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 47, 130, 0.08);
  border-radius: 10px;
  margin-left: 25px;
  padding: 20px;
}

.mulu {
  width: 70px;
  height: 17px;
  font-size: 18px;
  font-family: SimSun;
  font-weight: bold;
  color: #205cc6;
  line-height: 16px;
  margin-bottom: 15px;
}

.menuList::-webkit-scrollbar,
.right-side::-webkit-scrollbar {
  width: 4px;
}

.menuList::-webkit-scrollbar-thumb,
.right-side::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(#606266, 60%);
}

.menuList::-webkit-scrollbar-track,
.right-side::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#606266, 30%);
}

#runDemo {
  position: absolute;
  left: 16px;
  bottom: 104px;
  max-height: 60%;
  height: 40%;
  border-radius: 8px;
  background: #fff;
  width: 383px;
  /* 上层投影 */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
}

#runDemo a {
  width: calc(100%);
}

#runDemo .biaoti {
  width: calc(100% - 32px);
  background: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  padding: 8px 8px 8px 24px;
}

#runDemo .biaoti-select {
  width: calc(100% - 32px);
  background: var(--brand-light, #e0eafb);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  padding: 8px 8px 8px 24px;
}

#runDemo .text-1 {
  overflow: hidden;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: SimSun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

#runDemo .text-other {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-size: 12px;
  line-height: 20px;
}

#runDemo .text-select {
  color: var(--brand-click, #0061ff);
}

.el-dialog {
  margin-top: 5vh !important;
}

.el-dialog__body {
  background: #f0f7fd;
}

.download {
  position: absolute;
  top: 20px;
  right: 62px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#select .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  width: 40% !important;
  box-shadow: none;
}

#runDemo .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#select .el-dialog__header {
  width: 100%;
  background: #ffffff;
}

#runDemo .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
}

#select .el-dialog__body {
  width: 100%;
  height: 69%;
}

#runDemo .el-dialog__body {
  width: 100%;
  height: 69%;
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  padding: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

#runDemo .el-dialog__footer {
  box-sizing: initial;
  padding: 16px 8px;
  width: 100%;
  height: 31px;
  background: #ffffff;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#runDemo .dialog-footer {
  margin-right: 16px;
}

#select .el-dialog__headerbtn,
#runDemo .el-dialog__headerbtn {
  top: none;
}

#runDemo .createCourse {
  background: #ffffff;
}

#runDemo .createCourse:hover,
#runDemo .createCourse:focus {
  background: #007bff;
}

#select i:hover,
#runDemo i:hover {
  color: #5cb6ff;
}

#runDemo .export_to {
  position: absolute;
  bottom: 0;
  right: 60px;
}

#runDemo .create_course {
  position: absolute;
  bottom: 0;
  right: 160px;
}

#runDemo .max {
  position: absolute;
  top: 0;
  right: 25px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#runDemo .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#runDemo .el-dialog.is-fullscreen {
  overflow: hidden;
}

.noToolBlue {
  min-width: 10px;
  width: 10px;
  height: 10px;
  background: #0061ff;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 11px;
}

.noToolBlueHollow {
  min-width: 8px;
  width: 8px;
  height: 8px;
  border: 1px solid #0061ff;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 11px;
}

.stageBoxCss,
.stageBoxIsCss {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #e2f5fc;
  cursor: pointer;
  width: 100%;
}

.stageBoxIsCss {
  background: #0061ff;
  color: #fff;
  border-radius: 8px;
}

.closeTri {
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.stageItemTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  width: calc(100% - 50px);
}

.stageItemTitle>div:first-child {
  font-size: 16px;
  font-weight: 700;
  min-width: 55px;
}

.stageItemTitle>div:last-child {
  font-size: 15px;
  font-weight: 600;
  max-width: 242px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: calc(100% - 55px);
}

.mlImg {
  width: 16px !important;
  min-width: 16px;
  cursor: pointer;
  height: 16px;
}

.mlImg_1 {
  width: 16px !important;
  min-width: 16px;
  cursor: pointer;
  height: 16px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

#runDemo .el-tooltip {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

#runDemo .stageTitle {
  padding: 16px;
  width: 96%;
  display: flex;
}

.stageTitle .stageItemTitle>div:first-child {
  min-width: 55px;
  margin-right: 15px;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  font-family: SimSun;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.stageTitle .stageItemTitle>div:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  font-family: SimSun;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.stageTitle .stageItemTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

@media screen and (max-width: 960px) {
  #runDemo {
    position: absolute;
    left: 57%;
    bottom: 65px;
    max-height: 100%;
    height: 250px;
    border-radius: 8px;
    background: #fff;
    width: 231px;
    /* 上层投影 */
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  }
}
</style>
